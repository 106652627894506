import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate, useLocation } from "react-router-dom";
import { UserAuth } from '../../../context/AuthContext';
import { Backdrop } from '@mui/material';

export default function ProfileAlertDialog(props) {

  const [open, setOpen] = React.useState(props.open);

  const handleClose = () => {
    setOpen(false);
  };

  const { logOut, user } = UserAuth();
  const navigate = useNavigate();

  const handleOnSignOut = async () => {
    try {
      await logOut();
      setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
        sx={{ p: 2 }}
      >
        <DialogTitle id="alert-dialog-title">
          {"Signing up for the first time?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            To continue, please complete your profile setup - it only takes a few moments and unlocks the full potential of our platform.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button variant="outlined" onClick={() => handleOnSignOut()}>Sign out</Button>
          <Button  variant="contained" onClick={() =>  {
            navigate('/profilesetup');
            handleClose();
          }}
            >
            Go to profile settings
          </Button>
        </DialogActions>
        <br />
        <Backdrop open={open} sx={{ zIndex: -10, color: '#fff', opacity: 0.8 }} />
      </Dialog>
  );
}