import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

function AutocompleteMultipleSelectController(props) {
  const { title, name, register, errors, optionsdata, watch, multiple, onChange } = props
    
  return (
    <FormControl fullWidth error={ errors?.[name]?.message } >
      <Autocomplete
      id="autocomplete_con"      
      error    
      options={optionsdata}  
      getOptionLabel={(option) =>{
        return option.label ? option.label : option
      }}
      defaultValue={watch(name)} 
      onChange={(e) => onChange(e)}
      err    
      //multiple     
      autoHighlight        
      renderInput={(params) => (
        <TextField       
          {...params}
          label={title}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
            helperText: errors?.[name]?.message
          }}          
          {...register(name)}          
        />        
      )} 
         
    />
     { errors?.[name]?.message  &&
        <FormHelperText id="component-helper-text">
          { errors?.[name]?.message  }
        </FormHelperText>
      }
    </FormControl>
    
  );
}

export default AutocompleteMultipleSelectController;

