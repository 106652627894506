import React, { useState, useContext, useEffect } from "react";
import { border, Container } from "@mui/system";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Button,
  Paper,
  Alert
} from "@mui/material";
import { UserAuth } from "../../../../context/AuthContext";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Avatar from "../../../organisms/avatar";
import TextField from "../../../atom/InputController";
import RadioButtonsGroup from "../../../atom/RadioController";
import CountrySelectController from "../../../atom/CountryController";
import DatePickerController from "../../../atom/DatePickerController";
import PhotoUpload from "../../../atom/PhotoDropZone";
import { db, collection, addDoc, query, getDocs, doc, getDoc, setDoc, updateDoc } from "../../../../firebase";
import { useNavigate } from "react-router-dom";
import Dropzone from "../../../atom/dropzone";
import AggridTable from "../table/ag-grid-table";
import { SpinnerContext } from "../../../../context/SpinnerContext";

const schema = yup.object({
  firstName: yup.string().required('First name is required'),
  surName: yup.string().required('Surname is required'),
  gender: yup.string().required('Gender is required'),
  profilePicture: yup.array()
    .min(1, 'At least one file is required')
    .test('fileType', 'Only PDF, JPG, or PNG files are allowed', (files) => {
      if (!files) return true; // no files selected
      return files.every(file => {
        const fileType = file.type;
        return fileType === 'application/pdf' || fileType === 'image/jpeg' || fileType === 'image/png';
      });
    })
    .test('fileSize', 'File size is too large', (files) => {
      if (!files) return true; // no files selected
      return files.every(file => file.size <= 1024 * 1024 * 10); // 10MB limit
    }),
  contactNumber: yup.string().required('Phone number is required'),
  email: yup.string().email('Invalid email format').required('Email is required'),
  residingState: yup.string().required('Current state of residence is required'),
  residingCity: yup.string().required('Current city of residence is required'),
  residingCountry: yup.string().required('Current country of residence is required'),
  dob: yup.string().required('Date of birth is required'),
  choiceOfCountry: yup.string().required('Choice of planned country is required'),
  workExp: yup.string().required('Work experience is required'),
  pan: yup.string().required('PAN number is required'),
  aadhar: yup.string().required('Aadhar number is required'),
  passport: yup.string().required('Passport number is required'),
  existingLoan: yup.string().required('Existing Loan details is required'),
  referrer: yup.string().required('Referrer details is required'),
}).required();

function withFormHook(Component) {
  return function FormComponent(props) {
    const { user } = UserAuth();
    const { showSpinner, setShowSpinner } = useContext(SpinnerContext);
    const [allapplications, setAllApplications] = useState([]);

    useEffect(() => {
      fetchApplications();
    }, [])

    function convertDateFormat(inputDate) {
      // Split the input date string into day, month, and year parts
      const parts = inputDate?.split('/');
    
      if (parts?.length === 3) {
        // Reconstruct the date in "yyyy/mm/dd" format
        const year = parts[2];
        const month = parts[1].padStart(2, '0'); // Ensure two-digit month
        const day = parts[0].padStart(2, '0'); // Ensure two-digit day
    
        const outputDate = `${year}/${month}/${day}`;
    
        return outputDate;
      } else {
        // If the input format is incorrect, return the original string
        return inputDate;
      }
    }

    const fetchApplications = async () => {
      try {        
        setShowSpinner(true);
        const dateFormat = {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        };
        const querySnapshot = await getDocs(collection(db, 'applications'));
        const applications = [];
        querySnapshot.forEach((doc) => {
          applications.push({ id: doc.id, ...doc.data() });
        });          
        let allapplications = [];
        for (let i = 0; i < applications.length; i++) { 
          debugger;         
          if (applications[i].userId !== "" && applications[i].completedSteps >= 4) {
            try {
              //1 admission
              setShowSpinner(true);

              const userRef = doc(db, "profiles", applications[i].userId);
              const q = query(collection(db, "profiles", applications[i].userId, "basicInfo"));
              const querySnapshot = await getDocs(q);              
              const lvProfile = querySnapshot.docs.map((doc) => doc.data());
              //Merge both objects
              applications[i] = {...applications[i], ...lvProfile[0],
                createdDate : convertDateFormat(applications?.[i].createdOn?.toDate().toLocaleDateString('en-IN', dateFormat)),
                submittedDate : convertDateFormat(applications?.[i].submittedOn?.toDate().toLocaleDateString('en-IN', dateFormat))};  
              allapplications.push(applications[i]);     
              console.log("===============1");              
              console.log(allapplications);              

              setShowSpinner(false);
            } catch (error) {
              setShowSpinner(false);
              console.log("Unable to retrieve current admission fields", error)
            }
          }          
        }
        //setAllApplications(applications);
        setAllApplications(allapplications);

      } catch (error) {
        setShowSpinner(false);
        console.log("Unable to retrieve current admission fields", error)
      }


  
    }   

    const downloadPDF = (data) => {
      debugger;
    }

    return (
      <>
        {!showSpinner &&
          <Component
            {...props}
            applications={allapplications}
            downloadPDF={downloadPDF}
          />
        }
      </>
    );
  };
}


const AdminDashboardWrapper = (props) => {
 const { applications, downloadPDF } = props;
  const navigate = useNavigate();
  const { user } = UserAuth();
  return (
    <React.Fragment>
      <div style={{width:"100% !important"}}>
      <AggridTable applications={applications} downloadPDF={downloadPDF} />
      </div>
    </React.Fragment>
  );
}

const AdminDashboard = withFormHook(AdminDashboardWrapper);

export default AdminDashboard