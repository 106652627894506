import React, { useContext, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import { PulseLoader } from 'react-spinners';

export default function LoadingPulse(props) {

  const { showSpinner } = props;

  console.log("showSpinner compoent", showSpinner)

  React.useEffect(() => {
    if (showSpinner) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [showSpinner]);

  return(
  <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={showSpinner}
  >
     <PulseLoader color="#2196f3" size={40} margin={2} loading={showSpinner}/>
  </Backdrop>
  );

}