import Skeleton from '@mui/material/Skeleton';
import Card from '@mui/material/Card'

export const FormSkeleton = () => {
  return (
    <Card sx={{ padding: "36px" }}>
      <Skeleton animation="wave" height={40}/>
      <Skeleton animation="wave" height={60}/>
      <Skeleton animation="wave" height={60}/>
      <Skeleton animation="wave" height={60}/>
      <Skeleton animation="wave" height={60}/>
      <Skeleton animation="wave" height={60} width={100}/>
    </Card>
  );
}
