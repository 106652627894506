import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

function AutocompleteSelectController(props) {
  const { title, name, register, errors, optionsdata, watch  } = props;  
  const key = name?.lastIndexOf('.') > 0 ? name.substring(name.lastIndexOf('.') + 1) : name;
  
  return (
    <FormControl fullWidth error={ errors?.[key]?.message }>
      <Autocomplete
      id="autocomplete_con"      
      error={ errors?.[key]?.message}
      options={optionsdata}  
      getOptionLabel={(option) =>{
        return option.label ? option.label : option
      }}
      defaultValue={watch(name)}
      err         
      autoHighlight        
      renderInput={(params) => (
        <TextField       
          {...params}
          label={title}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
            helperText: errors?.[key]?.message
          }}
          {...register(name)}          
        />        
      )} 
         
    />
     { errors?.[key]?.message  &&
        <FormHelperText id="component-helper-text">
          { errors?.[key]?.message  }
        </FormHelperText>
      }
    </FormControl>
    
  );
}

export default AutocompleteSelectController;

