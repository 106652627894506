import { Avatar, Box, Button, Grid, Typography, Card, Divider, Alert } from '@mui/material';
import { UserAuth } from '../../../../context/AuthContext'
import { styled } from '@mui/system';
import ProfileCover from '../../../../Assets/images/profile-cover.jpg'


const ProfilePage = () => {

  const { logOut, user } = UserAuth();

  const userData = {
    name: 'John Doe',
    email: 'john.doe@example.com',
    phone: '555-555-5555',
    location: 'New York, NY',
    bio:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    avatarUrl:
      'https://www.w3schools.com/howto/img_avatar2.png',
    coverImageUrl:
      'https://unsplash.com/photos/1_CMoFsPfso',
  };

  const Background = styled(Box)(({ theme }) => ({
    background: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${ProfileCover}) no-repeat center center fixed`,
    backgroundSize: 'cover',
    height: '400px',
    position: 'relative',
    zIndex: 1,
  }));

  const AvatarWrapper = styled(Box)(({ theme }) => ({
    position: 'relative',
    bottom: '-100px',
    display: 'flex',
    justifyContent: 'center',
  }));

  const AvatarImage = styled(Avatar)(({ theme }) => ({
    width: '200px',
    height: '200px',
    border: `4px solid ${theme.palette.background.default}`,
  }));

  const SocialButton = styled(Button)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.primary.dark,
    },
  }));

  return (
    <Box sx={{ margin: "25px 0", bgcolor: 'background.default' }}>
      <Grid container spacing={8}>
        <Grid item xs={12} lg={4}>
          <Background>
            <AvatarWrapper>
              <AvatarImage src={user?.lvProfile?.profilePicture} />
            </AvatarWrapper>
          </Background>
          <Box sx={{ textAlign: 'center', my: 1}}>
            <Typography variant="h5">{`${user.lvProfile.firstName} ${user.lvProfile.surName}`}</Typography>
            <Typography variant="subtitle1" color="text.secondary">
              {user.email}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              {user.lvProfile.contactNumber}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              {user.lvProfile.residingCity}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' , my: 1 }}>
            <Alert severity="info">Submit an e-mail request to edit personal information</Alert>
          </Box>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Card sx={{ padding: "25px", margin: "0 0 25px 0" }}>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Personal Details
            </Typography>
            <Divider />
            <Box sx={{ my: 1 }}>
              <Typography variant="subtitle1" color="text.secondary">
                Date of birth
              </Typography>
              <Typography variant="body1">{user.lvProfile.dob}</Typography>
            </Box>
            <Box sx={{ my: 1 }}>
              <Typography variant="subtitle1" color="text.secondary">
                Gender
              </Typography>
              <Typography variant="body1">{user.lvProfile.gender}</Typography>
            </Box>
            <Box sx={{ my: 1 }}>
              <Typography variant="subtitle1" color="text.secondary">
                Residing State
              </Typography>
              <Typography variant="body1">{user.lvProfile.residingState}</Typography>
            </Box>
            <Box sx={{ my: 1 }}>
              <Typography variant="subtitle1" color="text.secondary">
                Residing country
              </Typography>
              <Typography variant="body1">{user.lvProfile.residingCountry}</Typography>
            </Box>
          </Card>
          <Card sx={{ padding: "25px", margin: "0 0 25px 0" }}>
          <Typography variant="h6" sx={{ mb: 1 }}>
              Other details
            </Typography>
            <Divider />
            <Box sx={{ my: 1 }}>
              <Typography variant="subtitle1" color="text.secondary">
                Choice of country
              </Typography>
              <Typography variant="body1">{user.lvProfile.choiceOfCountry}</Typography>
            </Box>
            <Box sx={{ my: 1 }}>
              <Typography variant="subtitle1" color="text.secondary">
                Work Experience (if any)
              </Typography>
              <Typography variant="body1">{user.lvProfile.workExp}</Typography>
            </Box>
            <Box sx={{ my: 1 }}>
              <Typography variant="subtitle1" color="text.secondary">
                Total monthly income (if any)
              </Typography>
              <Typography variant="body1">{user.lvProfile.monthlyIncome}</Typography>
            </Box>
            <Box sx={{ my: 1 }}>
              <Typography variant="subtitle1" color="text.secondary">
                Passport Number
              </Typography>
              <Typography variant="body1">{user.lvProfile.passport}</Typography>
            </Box>
            <Box sx={{ my: 1 }}>
              <Typography variant="subtitle1" color="text.secondary">
                PAN Number
              </Typography>
              <Typography variant="body1">{user.lvProfile.pan}</Typography>
            </Box>
            <Box sx={{ my: 1 }}>
              <Typography variant="subtitle1" color="text.secondary">
                Aadhar Number
              </Typography>
              <Typography variant="body1">{user.lvProfile.aadhar}</Typography>
            </Box>
            <Box sx={{ my: 1 }}>
              <Typography variant="subtitle1" color="text.secondary">
                Do you have any existing loan
              </Typography>
              <Typography variant="body1">{user.lvProfile.existingLoan}</Typography>
            </Box>
            <Box sx={{ my: 1 }}>
              <Typography variant="subtitle1" color="text.secondary">
                Referred by
              </Typography>
              <Typography variant="body1">{user.lvProfile.referrer}</Typography>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProfilePage;
