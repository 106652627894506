import React, { useState, useEffect, useContext } from "react";
import { border, Container } from "@mui/system";
import { useForm, useFieldArray, Controller, useWatch } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {
  Button,
  Paper,
  Alert
} from "@mui/material";
import { UserAuth } from "../../../../context/AuthContext";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from "../../../atom/InputController";
import CountrySelectController from "../../../atom/CountryController";
import DatePickerController from "../../../atom/DatePickerController";
import AutocompleteSelectController from "../../../atom/AutocompleteController";
import AutocompleteSelectControllerWithOnchange from "../../../atom/AutucompleteControllerWithOnChange";
//options values
import { addStatusData, programLevel, programCategory, relations, employmentstatus, yesno, steps } from "./optionsdata";
import { db, collection, addDoc, getDocs, doc, getDoc, setDoc, updateDoc, query, where } from "../../../../firebase";
import { SpinnerContext } from "../../../../context/SpinnerContext";
import { FormSkeleton } from "../../../atom/Skeleton";


const schema = yup.object({
  fathersName: yup.string(),
  fatherEmailAddress: yup.string().email('Invalid email format'),
  fatherContactNumber: yup.string(),
  mothersName: yup.string(),
  motherEmailAddress: yup.string().email('Invalid email format'),
  motherContactNumber: yup.string(),
  currentHomeAddress: yup.string().required('Current home address is required'),
  currentCollectiveMonthlyIncome: yup.string().required('Currect collective monthly income').matches(/^\d+$/, "Only numeric value allowed."),
  coapplicant: yup.array().of(
    yup.object().shape({
      coApplicantName: yup.string().required('Co applicant name is required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
      relationshipToMainApplicant: yup.string().required('Relationship to Main applicant is required'),
      currentEmploymentStatus: yup.string().required('Current employment status is required'),
      currentCityandState: yup.string().required('Current city & state of residence is required'),
      pan: yup.string().required('Pan is required'),
      monthlyIncome: yup.string().required('Monthly Income is required').matches(/^\d+$/, "Only numeric value allowed."),
      existingLoan: yup.string().required('Existing Loans EMI is required'),
      totalAmountofEmi: yup.string().required('Total amount of EMI is required').matches(/^\d+$/, "Only numeric value allowed."),
      canYouprovideProofofIncome: yup.string().required('Proof of income  is required'),
      isOwnproPertyAilable: yup.string().required('Own Property is required'),
      propertyAddress: yup.string().required('Property address is required'),
      employerBusinessName :  yup.string().required('Employer or Business name is required, Please fill NA if not applicable'),
      employerBusinessAddr : yup.string().required('Employer or Business address is required, Please fill NA if not applicable'),
    })
  )
}).required();

function withFormHook(Component) {
  return function FormComponent(props) {
    const [totalAmount, setTotalAmount] = useState();
    const [formData, setFormData] = useState([]);

    const { user } = UserAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [backstepdata, setBackstepdata] = useState();
    //const { setShowSpinner } = useContext(SpinnerContext);
    const { showSpinner, setShowSpinner } = useContext(SpinnerContext);
    const [nextStep, setNextStep] = useState([]);
    const [defaultValues, setDefaultValues] = useState({});

    const initialCoapplicant = {
      coapplicant: [
        {
          coApplicantName: "",
          relationshipToMainApplicant: "",
          currentEmploymentStatus: "",
          currentCityandState: "",
          pan: "",
          monthlyIncome: "",
          existingLoan: "",
          totalAmountofEmi: "",
          canYouprovideProofofIncome: "",
          isOwnproPertyAilable: "",
          propertyAddress: "",
        }
      ]
    };


    useEffect(() => {
      if (fields.length === 0) {
        append({
          coApplicantName: "",
          relationshipToMainApplicant: "",
          currentEmploymentStatus: "",
          currentCityandState: "",
          pan: "",
          monthlyIncome: "",
          existingLoan: "",
          totalAmountofEmi: "",
          canYouprovideProofofIncome: "",
          isOwnproPertyAilable: "",
          propertyAddress: "",
        });
      }
    })

    const { register, handleSubmit, setValue, control, watch, formState: { errors }, reset } = useForm({
      mode: 'onBlur',
      resolver: yupResolver(schema),
      defaultValues: defaultValues
      //defaultValues: formStep? formStep : initialCoapplicant,
      //defaultValues: location.state? location.state.backstepdata : initialCoapplicant,   
      //defaultValues: initialCoapplicant,          
    });

    const fetchCoapplicantFields = async () => {
      if (user?.currentOpenApplicationId) {
        try {
          setShowSpinner(true)
          const coapplicantRef = doc(db, "applications", user?.currentOpenApplicationId, "coapplicant", `${user.currentOpenApplicationId}_3`);
          // Retrieve admission data to set in default values if exist
          const currentData = (await getDoc(coapplicantRef)).data();
          reset(currentData); // set form default values
          setDefaultValues(currentData)
          setShowSpinner(false)
          console.log("coapplicant fields retrieved from step 3 mount", currentData);
        } catch (error) {
          setShowSpinner(false)
          console.log("Unable to retrieve current coapplicant fields from firebase", error)
        }
      }
      if (props.step === "step3") {
        try {
          setShowSpinner(true)
          const coapplicantRef = doc(db, "applications", props.applicationId, "coapplicant", `${props.applicationId}_3`);
          // Retrieve admission data to set in default values if exist
          const currentData = (await getDoc(coapplicantRef)).data();
          console.log("**************+++++++++");
          console.log(currentData);
          reset(currentData); // set form default values
          setDefaultValues(currentData)
          setShowSpinner(false)
          console.log("Admission fields retrieved from step 1 mount", currentData);
        } catch (error) {
          setShowSpinner(false)
          console.log("Unable to retrieve current admission fields", error)
        }
      }
    }

    const { fields, append, remove } = useFieldArray({
      name: "coapplicant",
      control,
      rules: {
        required: "Please append at least 1 item"
      }
    });


    //Get Next Step data:
    useEffect(() => {
      fetchCoapplicantFields();
      if (props.step === undefined) {
        const fetchData = async () => {
          setShowSpinner(true)
          const querySnapshot = await getDocs(collection(db, 'applications', user?.currentOpenApplicationId, 'academic'));
          const docs = [];
          querySnapshot.forEach((doc) => {
            docs.push({ id: doc.id, ...doc.data() });
          });
          setNextStep(docs[0]);
          setShowSpinner(false)
        };
        fetchData();
      }
    }, []);

    //Get prev Step data:
    useEffect(() => {
      if (props.step === undefined) {
        const fetchData = async () => {
          const querySnapshot = await getDocs(collection(db, 'applications', user?.currentOpenApplicationId, 'debt'));
          const docs = [];
          querySnapshot.forEach((doc) => {
            docs.push({ id: doc.id, ...doc.data() });
          });
          setBackstepdata(docs[0]);
        };
        fetchData();
      }
    }, []);



    const onSubmit = async (data) => {
      console.log("=======Data=======");
      let currentempstatus = data.coapplicant[0].currentEmploymentStatus;
      console.log(currentempstatus);
      localStorage.setItem('currentempstatus', currentempstatus);
      let payloadObj = data;
      payloadObj.uid = user.uid;
      console.log(payloadObj);
      try {
        setShowSpinner(true);
        let applicationid = props.step === "step3" ? props.applicationId : user?.currentOpenApplicationId;
        const applicationRef = doc(db, "applications", applicationid);
        const coapplicantRef = doc(db, "applications", applicationid, "coapplicant", `${applicationid}_3`);
        // Check if the document exists
        const coapplicantDoc = await getDoc(coapplicantRef);
        if (coapplicantDoc.exists()) {
          // Document exists, update its data
          await updateDoc(coapplicantRef, payloadObj);
        } else {
          // Document doesn't exist, add it with a randomly generated ID
          await setDoc(coapplicantRef, payloadObj);

          // Update document with steps completed
          // Get the current data
          const currentData = (await getDoc(applicationRef)).data();
          // Add a new field to the data
          const newData = { ...currentData, completedSteps: 3 };
          // Update the document with the new data
          await updateDoc(applicationRef, newData);
        }
        const docRef = collection(db, 'applications', user?.currentOpenApplicationId, 'lvDocuments');
        const parentsRef = query(docRef, where('group', '==', 'parents'))
        getDocs(parentsRef)
          .then((querySnapshots) => {
            querySnapshots.forEach(async (snapshot) => {
              const parentData = snapshot.data()
              const documentRef = doc(db, "applications", user?.currentOpenApplicationId, "lvDocuments", snapshot.id)
              if ((parentData.role === 'mother' && data.mothersName === "") || (parentData.role === 'father' && data.fathersName === "")) {
                const updatedDoc = { ...parentData, showDoc: false };
                await updateDoc(documentRef, updatedDoc);
              } else {
                const updatedDoc = { ...parentData, showDoc: true };
                await updateDoc(documentRef, updatedDoc);
              }
            })
          })

        const coappDetails = data.coapplicant
        coappDetails.forEach(async (coapp, index) => {
          const relationship = coapp.relationshipToMainApplicant;
          const coappEmpStatus = coapp.currentEmploymentStatus
          const coappindex = index + 1
          const currentOpenApplicationId = user?.currentOpenApplicationId;
          const docRef = collection(db, 'applications', currentOpenApplicationId, 'lvDocuments');
          const queryRef = query(docRef, where('group', '==', 'coapplicant' + coappindex))
          // Fetch the documents that match the query
          getDocs(queryRef)
          .then((querySnapshots) => {
            // Iterate through the documents that match the query
            querySnapshots.forEach(async (snapshot) => {
              const documentData = snapshot.data();
              const documentRef = doc(db, "applications", user.currentOpenApplicationId, "lvDocuments", snapshot.id)

              // Check if docName contains any of the specified substrings
              if(documentData.subGroup === 'all') {
                  if ( relationship !== 'Mother' && relationship !== 'Father') {
                    const updatedDoc = { ...documentData, showDoc: true };
                    await updateDoc(documentRef, updatedDoc);
                  } else {
                    const updatedDoc = { ...documentData, showDoc: false };
                    await updateDoc(documentRef, updatedDoc);
                }
              } else {
                if (coappEmpStatus === documentData.subGroup) {
                  const updatedDoc = { ...documentData, showDoc: true };
                  await updateDoc(documentRef, updatedDoc);
                } else {
                  const updatedDoc = { ...documentData, showDoc: false };
                  await updateDoc(documentRef, updatedDoc);
                }
              }
            });
          })
          .catch((error) => {
            console.error('Error fetching documents:', error);
          });
        })
        setShowSpinner(false)
        //navigate to next step once data is saved
        if (props.step === undefined) {
          navigate("/coreform/step4", { state: { nextStep } });
        } else {
          props.handleClose();
        }
      } catch (error) {
        console.error('Error adding data to Firestore:', error);
      }
    }
    const onBackClick = () => {
      navigate("/coreform/step2", { state: { backstepdata } });
    }
    console.log("user=====>");
    console.log(user);
    return (
      <>
        {!showSpinner &&
          <Component
            {...props}
            register={register}
            setValue={setValue}
            handleSubmit={handleSubmit(onSubmit)}
            errors={errors}
            fields={fields}
            append={append}
            remove={remove}
            onBackClick={onBackClick}
            formData={formData}
            control={control}
            watch={watch}
          />
        }
      </>
    );
  };
}


function StepThreeParentDetailscomponent(props) {
  const { register, handleSubmit, setValue, errors, fields, append, remove, onBackClick, formData, control, watch, step, setFields } = props;
  const { user } = UserAuth();
  const navigate = useNavigate();
  const { showSpinner } = useContext(SpinnerContext);

  //currentCollectiveMonthlyIncome calculation: Start
  useEffect(() => {
    currentCollectiveMonthlyIncomeCalculate();
  }, [fields]);

  const currentCollectiveMonthlyIncomeCalculate = async () => {
    let lvProfile;
    if(step==="step3"){
      const userRef = await doc(db, "profiles", props.uid);
      const q = query(collection(db, "profiles", props.uid, "basicInfo"));
      const querySnapshot = await getDocs(q);
      lvProfile = querySnapshot.docs.map((doc) => doc.data());
    }
    let studentMonIncome;
    if(step==="step3"){
      studentMonIncome = lvProfile?.monthlyIncome !== undefined ? lvProfile?.monthlyIncome : "0";
    }else{
      studentMonIncome = user?.lvProfile?.monthlyIncome !== undefined ? user?.lvProfile?.monthlyIncome : "0";
    }

    if (props.step === undefined) {
      let studentMonIncome = user?.lvProfile?.monthlyIncome !== undefined ? user?.lvProfile?.monthlyIncome : "0";
      if (fields.length === 0) {
        let val1 = (fields[0]?.monthlyIncome !== "" ? parseInt(fields[0]?.monthlyIncome) : 0);
        setValue('currentCollectiveMonthlyIncome', parseInt(val1) + parseInt(studentMonIncome));
      } else if (fields.length === 1) {
        let val1 = (fields[0]?.monthlyIncome !== "" ? parseInt(fields[0]?.monthlyIncome) : 0);
        setValue('currentCollectiveMonthlyIncome', parseInt(val1) + parseInt(studentMonIncome));
      } else if (fields.length === 2) {
        let val1 = (fields[0]?.monthlyIncome !== "" ? parseInt(fields[0]?.monthlyIncome) : 0);
        let val2 = (fields[1]?.monthlyIncome !== "" ? parseInt(fields[1]?.monthlyIncome) : 0);
        let total = val1 + val2 + parseInt(studentMonIncome);
        setValue('currentCollectiveMonthlyIncome', total);
      }
    }
  }


  const monthlyIncomeChange = (e) => {
    let lvProfile;
    if(step==="step3"){
      const userRef = doc(db, "profiles", props.uid);
      const q = query(collection(db, "profiles", props.uid, "basicInfo"));
      const querySnapshot = getDocs(q);
      lvProfile = querySnapshot.docs.map((doc) => doc.data());
    }

    if(step==="step3"){
      let studentMonIncome = lvProfile?.monthlyIncome !== undefined ? lvProfile?.monthlyIncome : "0";
    if (e.target.name === "coapplicant[0].monthlyIncome" && fields.length === 1) {
      let total = e.target.value !== "" ? parseInt(e.target.value) : 0;
      total = total + parseInt(studentMonIncome)
      setValue('currentCollectiveMonthlyIncome', total);
    }
    else if (e.target.name === "coapplicant[1].monthlyIncome" && fields.length === 2) {
      let total = (fields[0].monthlyIncome !== "" ? parseInt(fields[0].monthlyIncome) : 0) + (parseInt(e.target.value));
      total = total + parseInt(studentMonIncome)
      setValue('currentCollectiveMonthlyIncome', total);
    }
    else if (e.target.name === "coapplicant[0].monthlyIncome" && fields.length === 2) {
      let total = (fields[0].monthlyIncome !== "" ? parseInt(fields[0].monthlyIncome) : 0) + (parseInt(e.target.value));
      total = total + parseInt(studentMonIncome)
      setValue('currentCollectiveMonthlyIncome', total);
    }
    }else{
      let studentMonIncome = user?.lvProfile?.monthlyIncome !== undefined ? user?.lvProfile?.monthlyIncome : "0";
    if (e.target.name === "coapplicant[0].monthlyIncome" && fields.length === 1) {
      let total = e.target.value !== "" ? parseInt(e.target.value) : 0;
      total = total + parseInt(studentMonIncome)
      setValue('currentCollectiveMonthlyIncome', total);
    }
    else if (e.target.name === "coapplicant[1].monthlyIncome" && fields.length === 2) {
      let total = (fields[0].monthlyIncome !== "" ? parseInt(fields[0].monthlyIncome) : 0) + (parseInt(e.target.value));
      total = total + parseInt(studentMonIncome)
      setValue('currentCollectiveMonthlyIncome', total);
    }
    else if (e.target.name === "coapplicant[0].monthlyIncome" && fields.length === 2) {
      let total = (fields[0].monthlyIncome !== "" ? parseInt(fields[0].monthlyIncome) : 0) + (parseInt(e.target.value));
      total = total + parseInt(studentMonIncome)
      setValue('currentCollectiveMonthlyIncome', total);
    }
    }
    
  }
  //currentCollectiveMonthlyIncome calculation: End  



  const [coapplicant, setCoapplicant] = useState(fields.length);
  return (
    <form onSubmit={handleSubmit}>
      <Container sx={{ padding: "36px" }}>
        {showSpinner && <FormSkeleton />}
        {!showSpinner &&
          <Grid container spacing={2}>
            <Grid xs={12} md={12} sx={{ maxHeight: "600px" }}>
              <Paper
                elevation={3}
                sx={{
                  padding: "24px"
                }}
              >
                <Typography gutterBottom>
                {
                        step ===undefined ? <div className="wizard_title">Parent Details</div> : ""
                    }
                    {
                        step ===undefined ? <Box sx={{ width: '100%', marginTop: "30px" }}>
                        <Stepper activeStep={2} alternativeLabel>
                          {steps.map((label) => (
                            <Step key={label}>
                              <StepLabel>{label}</StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </Box> : ""
                    }   
                  
                  
                  <Grid container spacing={2} sx={{ margin: "12px 0 12px 0" }}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        name="fathersName"
                        title="Father's Name"
                        register={register}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        name="fatherEmailAddress"
                        title="Email Address"
                        register={register}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        name="fatherContactNumber"
                        title="Contact Number"
                        register={register}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        name="mothersName"
                        title="Mother's Name"
                        register={register}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        name="motherEmailAddress"
                        title="Email Address"
                        register={register}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        name="motherContactNumber"
                        title="Contact Number"
                        register={register}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        name="currentHomeAddress"
                        title="Current home address"
                        register={register}
                        errors={errors}
                      />
                    </Grid>

                  </Grid>
                  <div className="wizard_title">Co-Application Details</div>
                  {/*---------------------*/}
                  {fields.map((field, index) => {
                    
                    return (
                      <Grid container spacing={2} sx={{ margin: "12px 0 12px 0" }} key={field.id}>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            name={`coapplicant[${index}].coApplicantName`}
                            title="Co-Applicant Name"
                            register={register}
                            errors={errors?.coapplicant?.[index]}
                          />

                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <AutocompleteSelectController
                            name={`coapplicant[${index}].relationshipToMainApplicant`}
                            title="Relationship to Main applicant"
                            register={register}
                            optionsdata={relations}
                            errors={errors?.coapplicant?.[index]}
                            watch={watch}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <AutocompleteSelectController
                            name={`coapplicant[${index}].currentEmploymentStatus`}
                            title="Current employment status"
                            register={register}
                            optionsdata={employmentstatus}
                            errors={errors?.coapplicant?.[index]}
                            watch={watch}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            name={`coapplicant[${index}].currentCityandState`}
                            title="Current city & state of residence"
                            register={register}
                            errors={errors?.coapplicant?.[index]}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            name={`coapplicant[${index}].pan`}
                            title="PAN"
                            register={register}
                            errors={errors?.coapplicant?.[index]}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            name={`coapplicant[${index}].monthlyIncome`}
                            title="Monthly Income"
                            register={register}
                            errors={errors?.coapplicant?.[index]}
                            onChange={monthlyIncomeChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <AutocompleteSelectController
                            name={`coapplicant[${index}].existingLoan`}
                            title="Existing Loans EMI (if any)"
                            register={register}
                            optionsdata={yesno}
                            errors={errors?.coapplicant?.[index]}
                            watch={watch}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            name={`coapplicant[${index}].totalAmountofEmi`}
                            title="Total Amount of EMI"
                            register={register}
                            errors={errors?.coapplicant?.[index]}
                            value="0"
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <AutocompleteSelectController
                            name={`coapplicant[${index}].canYouprovideProofofIncome`}
                            title="Can you provide proof of income "
                            register={register}
                            optionsdata={yesno}
                            errors={errors?.coapplicant?.[index]}
                            watch={watch}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4} className="isOwnProperty">
                          <AutocompleteSelectController
                            name={`coapplicant[${index}].isOwnproPertyAilable`}
                            title="Is own property available?"
                            register={register}
                            optionsdata={yesno}
                            errors={errors?.coapplicant?.[index]}
                            watch={watch}
                          />
                          <span style={{ fontSize: "10px" }}>(For Field Investigation' purpose only)</span>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            name={`coapplicant[${index}].propertyAddress`}
                            title="Property Address"
                            register={register}
                            errors={errors?.coapplicant?.[index]}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}></Grid>
                        <React.Fragment>
                          <Grid item xs={12} sm={4}>
                          <TextField
                            name={`coapplicant[${index}].employerBusinessName`}
                            title="Employer or Business Name"
                            register={register}
                            errors={errors?.coapplicant?.[index]}
                          />
                          <span style={{ fontSize: "10px" }}>Fill 'NA' if not applicable</span>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            name={`coapplicant[${index}].employerBusinessAddr`}
                            title="Employer or Business Address"
                            register={register}
                            errors={errors?.coapplicant?.[index]}
                          />
                          <span style={{ fontSize: "10px" }}>Fill 'NA' if not applicable</span>
                        </Grid>
                        </React.Fragment>
                        
                        {/* <Grid item xs={12} sm={4}>
                            <TextField
                              name={`coapplicant[${index}].currentCollectiveMonthlyIncome`}
                              title="Current Collective Monthly Income (Main Applicant and Co-Applicant combined)"
                              register={register}
                              errors={errors?.coapplicant?.[index]}
                            />
                          </Grid> */}
                        {fields.length > 1 ? <Button
                          variant="contained"
                          type="button"
                          color="error"
                          disabled={step==="step3" ? true : false}
                          style={{ float: "right" }}
                          onClick={() => {
                            remove(index);
                            setCoapplicant(coapplicant - 1);
                            //localStorage.setItem('coapplicantNum', coapplicant - 1);                                                  
                          }
                          }
                          sx={{ margin: "12px 0 12px 0" }}
                        >
                          Delete
                        </Button> : ""}

                      </Grid>

                    );
                  })}
                  <Button
                    variant="contained"
                    type="button"
                    color="success"
                    disabled={fields.length === 2}
                    onClick={() => {
                      if (fields.length < 2) {
                        setCoapplicant(fields.length + 1);
                        //localStorage.setItem('coapplicantNum', coapplicant + 1);
                        append({
                          coApplicantName: "",
                          relationshipToMainApplicant: "",
                          currentEmploymentStatus: "",
                          currentCityandState: "",
                          pan: "",
                          monthlyIncome: "",
                          existingLoan: "",
                          totalAmountofEmi: "",
                          canYouprovideProofofIncome: "",
                          isOwnproPertyAilable: "",
                          propertyAddress: "",
                        });
                      }

                    }}
                    sx={{ margin: "12px 0 12px 0" }}
                  >
                    Add Co-applicant
                  </Button>

                  <Grid item xs={12} sm={6} className="control_disabled">
                    <TextField
                      name={"currentCollectiveMonthlyIncome"}
                      title="Current Collective Monthly Income (Applicant and Co-Applicant combined)"
                      register={register}
                      errors={errors}
                    />
                    {/* <span style={{ fontSize: "10px" }}>(Main Applicant and Co-Applicant combined)</span> */}
                  </Grid>
                  {/*---------------------*/}
                  <Divider />
                  {
                    step === undefined ? <><Button
                      variant="contained"
                      type="button"
                      color="primary"
                      onClick={onBackClick}
                      sx={{ margin: "12px 0 12px 0" }}
                    >
                      Back
                    </Button>
                      {" "}
                      <Button
                        variant="contained"
                        type="submit"
                        sx={{ margin: "12px 0 12px 0" }}
                      >
                        Next
                      </Button>
                    </> : <Button
                      variant="contained"
                      type="submit"
                      sx={{ margin: "12px 0 12px 0" }}
                    >
                      Save
                    </Button>
                  }
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        }
      </Container>
    </form>

  )
}



const StepThreeParentDetails = withFormHook(StepThreeParentDetailscomponent);

export default StepThreeParentDetails