import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@mui/styles';
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Typography from '@mui/material/Typography';
import { useFormContext } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  dropzone: {
    border: `2px dashed #082e72`,
    borderRadius: '5px',
    cursor: 'pointer',
    height: '100%',
    padding: '10px',
    textAlign: 'center',
  },
  preview: {
    height: 400,
    objectFit: 'cover',
    width: '100%',
  },
}));

function PhotoUpload(props) {
  const { helperText, title, isError = false, name, register, setValue } = props;
  const classes = useStyles();
  const onDrop = useMemo(
    () => (acceptedFiles) => {
      setValue(name, acceptedFiles[0]);
    },
    [setValue]
  );
  const {acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/*' });

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item {...getRootProps()} className={classes.dropzone}>
        <input {...getInputProps()} />
        {acceptedFiles.length > 0 ? (
          <img src={URL.createObjectURL(acceptedFiles[0])} className={classes.preview} alt="Preview" />
        ) : (
          <Typography variant="body1">Drag and drop an image here or click to select a file</Typography>
        )}
      </Grid>
     
    </Grid>
  );
}

export default PhotoUpload;