import { Box, Typography } from '@mui/material';
import constructionImg from './construction.jpg';

function UnderConstruction() {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <img
        src={constructionImg}
        alt="Site under construction"
        width="800"
        height="400"
        style={{ marginBottom: '2rem' }}
      />
      <Typography variant="h4" align="center" gutterBottom>
        Feature Under Construction
      </Typography>
      <Typography variant="body1" align="center">
        We're sorry, but this feature is currently under construction. Please check back later.
      </Typography>
    </Box>
  );
};
export default UnderConstruction;