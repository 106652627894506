import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

function CustomCountrySelectController(props) {
    const { title, name, register, errors, optionsdata, watch } = props
    return (
        <FormControl fullWidth error={errors?.[name]?.message} >
            <Autocomplete
                id="country-select-demo"
                error
                options={optionsdata}
                autoHighlight
                getOptionLabel={(option) =>{
                    return option.label ? option.label : option
                  }}
                defaultValue={watch(name)}
                err
                renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            alt=""
                        />
                        {option.label} ({option.code})
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={title}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                            helperText: errors?.[name]?.message
                        }}
                        {...register(name)}
                    />
                )}
            />
            {errors?.[name]?.message &&
                <FormHelperText id="component-helper-text">
                    {errors?.[name]?.message}
                </FormHelperText>
            }
        </FormControl>

    );
}

export default CustomCountrySelectController;


