import React, {useState, useEffect, useContext, useMemo} from "react";
import { border, Container } from "@mui/system";
import { useNavigate, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
    Button,
    Paper,
    Alert
} from "@mui/material";
import { UserAuth } from "../../../../context/AuthContext";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from "../../../atom/InputController";
import CountrySelectController from "../../../atom/CountryController";
import CustomCountrySelectController from "../../../atom/CustomCountryController";
import DatePickerController from "../../../atom/DatePickerController";
import AutocompleteSelectController from "../../../atom/AutocompleteController";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
//options values
import { addStatusData, programLevel, programCategory, relations, steps, selectedCountry } from "./optionsdata";
import {countries} from "./countrylist";
import { db, collection, addDoc, getDocs, doc, updateDoc, getDoc, setDoc } from "../../../../firebase";

import { SpinnerContext } from "../../../../context/SpinnerContext";
import Skeleton from '@mui/material/Skeleton';
import Card from '@mui/material/Card'
import { FormSkeleton } from "../../../atom/Skeleton";

const dateRegex = /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/;

const schema = yup.object({
    admissionStatus: yup.string().required('Admission status is required'),
    countryName: yup.string().required('Country name is required'),
    nameCollegeUniversity: yup.string().required('College name is required'),
    programLevel: yup.string().required('Program level is required'),
    programName: yup.string().required('Program name is required'),
    programCategory: yup.string().required('Program category is required'),
    //programStartDate: yup.string().required('Program start date is required'),
    programStartDate: yup.string().matches(dateRegex, 'Invalid date format').required('Expected time should be a future date').test('conditionalRequired', null, function (value) {
      //getting current date in dd/mm/yyyy format:
      let currentDate = new Date();
      currentDate = ((currentDate.getMonth() > 8) ? (currentDate.getMonth() + 1) : ('0' + (currentDate.getMonth() + 1))) + '/' + ((currentDate.getDate() > 9) ? currentDate.getDate() : ('0' + currentDate.getDate())) + '/' + currentDate.getFullYear();   
      let temValue = value;
      let datearray = temValue.split("/");
      let newValue = datearray[1] + '/' + datearray[0] + '/' + datearray[2];
      
      if(Date.parse(newValue) < Date.parse(currentDate)){
        return this.createError({
          message: 'Tentative program start date should be a future date.',
        });
      }
      return true;
    }),
}).required();

function withFormHook(Component) {
    return function FormComponent(props) {
        const { user } = UserAuth();
        const navigate = useNavigate();
        const location = useLocation();
        const [defaultValues, setDefaultValues] = useState({});
        const { showSpinner, setShowSpinner } = useContext(SpinnerContext);
        //const [currentStep, setCurrentStep] = useState([]);
        const [nextStep, setNextStep] = useState([]);
        const { register, handleSubmit, setValue, control, watch, formState: { errors }, reset } = useForm({
          mode: 'onBlur',
          resolver: yupResolver(schema),
          defaultValues: defaultValues// fetchAdmissionFields()
        });

        const fetchAdmissionFields = async () => {          
          if(user?.currentOpenApplicationId) {
            try {
              setShowSpinner(true)
              const admissionRef = doc(db, "applications", user?.currentOpenApplicationId, "admission", `${user.currentOpenApplicationId}_1`);
              // Retrieve admission data to set in default values if exist
              const currentData = (await getDoc(admissionRef)).data();
              reset(currentData); // set form default values
              setDefaultValues(currentData)
              setShowSpinner(false)
              console.log("Admission fields retrieved from step 1 mount", currentData);
            } catch (error) {
              setShowSpinner(false)
              console.log("Unable to retrieve current admission fields", error)
            }
          }
          if(props.step ==="step1") {
            try {
              setShowSpinner(true)
              const admissionRef = doc(db, "applications", props.applicationId, "admission", `${props.applicationId}_1`);
              // Retrieve admission data to set in default values if exist
              const currentData = (await getDoc(admissionRef)).data();
              reset(currentData); // set form default values
              setDefaultValues(currentData)
              setShowSpinner(false)
              console.log("Admission fields retrieved from step 1 mount", currentData);
            } catch (error) {
              setShowSpinner(false)
              console.log("Unable to retrieve current admission fields", error)
            }
          }
        }

        useEffect(() => {
          fetchAdmissionFields()
        }, []);


        const onSubmit = async (data) => {          
            let payloadObj = data;
            payloadObj.uid = user.uid;            
            try {                 
              setShowSpinner(true);
              let applicationid = props.step ==="step1" ? props.applicationId : user?.currentOpenApplicationId;
              const applicationRef = doc(db, "applications",  applicationid);
              const admissionRef = doc(db, "applications", applicationid, "admission", `${applicationid}_1`);
              // Check if the document exists
              const admissionDoc = await getDoc(admissionRef);
              if (admissionDoc.exists()) {
                // Document exists, update its data
                await updateDoc(admissionRef, payloadObj);
              } else {
                // Document doesn't exist, add it with a randomly generated ID
                await setDoc(admissionRef, payloadObj);

                // Update document with steps completed
                // Get the current data
                const currentData = (await getDoc(applicationRef)).data();
                // Add a new field to the data
                const newData = { ...currentData, completedSteps: 1, status:'INPROGRESS'};
                // Update the document with the new data
                await updateDoc(applicationRef, newData);
                
              }
              setShowSpinner(false)
              //navigate to next step once data is saved              
              if(props.step ===undefined){
                navigate("./step2", { state: { nextStep }});
              }else{
                props.handleClose();
              }              
            } catch (error) {
                setShowSpinner(false)
                console.error('Error adding data to Firestore:', error);
            }
        }

        return (
            <Component
                {...props}
                register={register}
                setValue={setValue}
                handleSubmit={handleSubmit(onSubmit)}
                errors={errors}
                control={control}
                watch={watch}                
            />
        );
    };
}


function StepOneAdmissionDetailcomponent(props) {
    const { register, handleSubmit, errors, watch, step, stepData } = props;
    const [countriesList, setCountriesList] = useState([]);
    const { showSpinner } = useContext(SpinnerContext);

    useEffect(() => {
        filteredCountry();
    }, []);

    const filteredCountry = () => {
        let selectedcountry = selectedCountry;
        let allcountry = countries;
        let filtCountry = [];
        for (let i = 0; i < allcountry.length; i++) {
            for (let j = 0; j < selectedcountry.length; j++) {
                if (selectedcountry[j].toLowerCase() === allcountry[i].label.toLowerCase()) {
                    filtCountry.push(allcountry[i]);
                }
            }
        }
        const others = {
          "code": "Others",
          "label": "Others",
          "phone": "0",
          "suggested": true
        };
        filtCountry.push(others); 
        setCountriesList(filtCountry);
    }

    return (
      <form onSubmit={handleSubmit}>
        <Container sx={{ padding: "36px" }}>
          {showSpinner && <FormSkeleton />}
          {!showSpinner &&
            <Grid container spacing={2}>
              <Grid xs={12} md={12} sx={{ maxHeight: "600px" }}>
                <Paper
                  elevation={3}
                  sx={{
                    padding: "24px"
                  }}
                >
                  <Typography gutterBottom>
                    {
                        step ===undefined ? <div className="wizard_title">Admission Details</div> : ""
                    }
                    {
                        step ===undefined ? <Box sx={{ width: '100%', marginTop: "30px" }}>
                        <Stepper activeStep={0} alternativeLabel>
                          {steps.map((label) => (
                            <Step key={label}>
                              <StepLabel>{label}</StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </Box> : ""
                    }                    
                    
                    <Grid container spacing={2} sx={{ margin: "12px 0 12px 0" }}>
                      <Grid item xs={12} sm={4}>
                        <AutocompleteSelectController
                          name="admissionStatus"
                          title="Admission Status"
                          register={register}
                          optionsdata={addStatusData}
                          errors={errors}
                          watch={watch}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <CustomCountrySelectController
                          name="countryName"
                          title="Country"
                          register={register}
                          optionsdata={countriesList}
                          errors={errors}
                          watch={watch}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          name="nameCollegeUniversity"
                          title="Name of College/University"
                          register={register}
                          errors={errors}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <AutocompleteSelectController
                          name="programLevel"
                          title="Program Level"
                          register={register}
                          optionsdata={programLevel}
                          errors={errors}
                          watch={watch}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          name="programName"
                          title="Program Name"
                          register={register}
                          errors={errors}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <AutocompleteSelectController
                          name="programCategory"
                          title="Program Category"
                          register={register}
                          optionsdata={programCategory}
                          errors={errors}
                          watch={watch}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <DatePickerController
                          name="programStartDate"
                          title="Tentative Program start date"
                          register={register}
                          errors={errors}
                          watch={watch}
                        />
                      </Grid>
                    </Grid>
                    <Divider />
                    {
                      step ===undefined ? <Button
                      variant="contained"
                      type="submit"
                      sx={{ margin: "12px 0 12px 0" }}
                    >
                      Next
                    </Button> : <Button
                      variant="contained"
                      type="submit"                      
                      sx={{ margin: "12px 0 12px 0" }}
                    >
                      Save
                    </Button>
                    }
                    

                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          }
        </Container>
      </form>
  )
}

//export default StepOneAdmissionDetail;

const StepOneAdmissionDetail = withFormHook(StepOneAdmissionDetailcomponent);

export default StepOneAdmissionDetail