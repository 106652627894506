import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getFirestore, collection, addDoc, query, where, getDoc, getDocs, doc, setDoc, updateDoc, serverTimestamp  } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { firebaseConfigProduction } from "./config/firebase-config-production";
import { firebaseConfigTest } from "./config/firebase-config-test";



/* let firebaseConfig
if(process.env.NODE_ENV === 'production') {
  firebaseConfig = {
    apiKey: "AIzaSyDENtPBXn0-lxb3HPhiiT8kcIw-VJ5dPgo",
    authDomain: "loan-vidya-prod-167b8.firebaseapp.com",
    projectId: "loan-vidya-prod-167b8",
    storageBucket: "loan-vidya-prod-167b8.appspot.com",
    messagingSenderId: "354386466788",
    appId: "1:354386466788:web:8c4ad74afceecc370e59f1",
    measurementId: "G-C6LCNNNHY9"
  };
} else if(process.env.NODE_ENV === 'development') {
  firebaseConfig = {
    apiKey: "AIzaSyDEhKDcjA4o3H-ZX82blWpay5fSYKMfRW8",
    authDomain: "loan-vidya.firebaseapp.com",
    projectId: "loan-vidya",
    storageBucket: "loan-vidya.appspot.com",
    messagingSenderId: "927944358529",
    appId: "1:927944358529:web:88eabd1bbe54348718ab80",
    measurementId: "G-XJZCEG1C39"
  }
}
*/

const config = window?.location?.href?.includes("app.loanvidya.com") ? firebaseConfigProduction : firebaseConfigTest;

console.log("launch url and config",window?.location?.href, config);
// Initialize Firebase
const app = initializeApp(config);

const analytics = getAnalytics(app);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app);
const storage = getStorage(app);

export {
  app, 
  auth, 
  provider, 
  analytics, 
  db, 
  collection, 
  addDoc, 
  query, 
  where, 
  getDoc,
  getDocs, 
  doc, 
  storage, 
  ref, 
  uploadBytes,
  getDownloadURL,
  setDoc,
  updateDoc,
  serverTimestamp
}
