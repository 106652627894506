export const addStatusData = [
    { label: "Received Offer letter", value: "Received Offer letter" },
    { label: "Awaiting Decision", value: "Awaiting Decision" },
    { label: "Yet to Apply", value: "Yet to Apply" }
];
export const programLevel =[    
    {label:"Bachelors", value:"Bachelors"},
    {label:"PG Diploma", value:"PG Diploma"},
    {label:"Masters", value:"Masters"}
];
export const programCategory =[    
    {label:"Science", value:"Science"},
    {label:"Technology", value:"Technology"},
    {label:"Engineering", value:"Engineering"},    
    {label:"Mathematics", value:"Mathematics"},
    {label:"Business", value:"Business"},
    {label:"Others", value:"Others"}
];
export const relations =[    
    {label:"Father", value:"Father"},
    {label:"Mother", value:"Mother"},
    {label:"Uncle", value:"Uncle"},
    {label:"Aunt", value:"Aunt"},
    {label:"Brother", value:"Brother"},
    {label:"Sister", value:"Sister"},
    {label:"Cousin", value:"Cousin"}
];
export const employmentstatus =[    
    {label:"Self Employed", value:"Self Employed"},
    {label:"Employed", value:"Employed"},    
    {label:"Pensioner", value:"Pensioner"}
];
export const yesno =[    
    {label:"Yes", value:"yes"},
    {label:"No", value:"no"}  
];
export const higherDegree =[    
    {label:"PG", value:"PG"},
    {label:"PG Integrated", value:"PG Integrated"},  
    {label:"UG", value:"UG"},  
    {label:"UG Lateral", value:"UG Lateral"},  
    {label:"Diploma", value:"Diploma"},  
    {label:"Diploma Lateral", value:"Diploma Lateral"}
];
export const cource =[    
    {label:"ME", value:"ME"},
    {label:"M.Tech", value:"M.Tech"},  
    {label:"M.Sc", value:"M.Sc"},  
    {label:"M.B.A", value:"M.B.A"},  
    {label:"M.Arch", value:"M.Arch"},  
    {label:"M.Com", value:"M.Com"},
    {label:"BE", value:"BE"},
    {label:"B.Tech", value:"B.Tech"},
    {label:"B.Arch", value:"B.Arch"},
    {label:"B.B.A", value:"B.B.A"},
    {label:"B.Com", value:"B.Com"},
    {label:"B.Sc", value:"B.Sc"},
    {label:"Others", value:"Others"}
];
export const backlog =[
    {label:"0", value:"0"},
    {label:"1", value:"1"},
    {label:"2", value:"2"},
    {label:"3", value:"3"},
    {label:"3+", value:"3+"},
];
export const tests=[    
    {label:"IELTS", value:"IELTS"},
    {label:"PTE", value:"PTE"},
    {label:"TOEFL", value:"TOEFL"},
    {label:"GRE", value:"GRE"},
    {label:"GMAT", value:"GMAT"},
    {label:"Duolingo", value:"Duolingo"}      
];

export const steps = [
    'Admission Details',
    'Debt Details',
    'Co-Application Details',
    'Academic Details'
];
export const selectedCountry=["United States", "United Kingdom", "CANADA", "GERMANY", "FRANCE", "ITALY", "AUSTRALIA", "NEW ZEALAND", "IRELAND"];
//higherDegree, cource, backlog, tests