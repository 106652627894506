import React, { useContext, useState, useEffect } from 'react';
import { Box, Button, Container, Typography, Card } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import ApplicationProgress from './applicationProgress';
import { UserAuth } from '../../../../context/AuthContext';
import { makeStyles } from '@mui/styles';
import { db, collection, addDoc, getDocs, doc, updateDoc, getDoc, setDoc, serverTimestamp } from "../../../../firebase";
import { v4 as uuidv4 } from 'uuid';
import { SpinnerContext } from "../../../../context/SpinnerContext";
import DocMetaData from '../document/docsConstant';


const useStyles = makeStyles(() => ({
  button: {
    fontWeight: 'bold',
    color: '#082e72',
    borderRadius: '10px'
  },
}));


function Dashboard() {
  const navigate = useNavigate();
  const { user, updateUser } = UserAuth();
  const { setShowSpinner } = useContext(SpinnerContext);
  const [nextStep, setNextStep] = useState([]);
  const classes = useStyles();
  const [applicationFields, setApplicationFields] = useState();


  const applicationSteps = [
    {
      key: "NOTSTARTED",
      value: "Not started"
    },
    {
      key: "INPROGRESS",
      value: "In progress"
    },
    {
      key: "SUBMITTED",
      value: "Submitted"
    },
    {
      key: "UNDERVALIDATION",
      value: "Under validation"
    },
    {
      key: "PROCESSED",
      value: "Processed"
    }
  ]

  const coreFormRoutes = [
    "/coreform",
    "/coreform/step2",
    "/coreform/step3",
    "/coreform/step4",
    "/coreform/step5"
  ]

  //Fetch application field data to identify the stage of aplication and completed steps
  useEffect(() => {
    if(user?.currentOpenApplicationId) {
      const fetchApplicationFields = async () => {
        try {
          setShowSpinner(true)
          const applicationRef = doc(db, "applications", user?.currentOpenApplicationId);
          // Get the current data
          const currentData = (await getDoc(applicationRef)).data();
          console.log("Application fields retrieved from dashboard mount", currentData);
          setApplicationFields(currentData)
          setShowSpinner(false)
        } catch(error) {
          setShowSpinner(false)
          console.log("Unable to retrieve current application fields from firebase", error)
        }
      }
      fetchApplicationFields();
     }
  }, []);

  const generateApplicationID = async () => {

    if (!user.currentOpenApplicationId) {
      // Pad the number with leading zeros to ensure it has 8 digits
      const currentOpenApplicationId = `LV${uuidv4().substr(0, 8).toUpperCase()}`;
      console.log("Loan Application Number", currentOpenApplicationId, DocMetaData)
      try {
        setShowSpinner(true)

        const userRef = doc(db, "profiles", user.uid);
        // Get the current data
        const currentData = (await getDoc(userRef)).data();
        // Add a new field to the data
        const newData = { ...currentData, currentOpenApplicationId: currentOpenApplicationId };
        // Update the document with the new data
        await updateDoc(userRef, newData);
        // Updating the user context with currentOpenApplicationId
        await updateUser({ currentOpenApplicationId: currentOpenApplicationId })
        // create the document reference for aplication and set up the basic information
        const applicationRef = doc(db, "applications", currentOpenApplicationId);
        await setDoc(applicationRef, { userId: user?.uid, completedSteps: 0, status: 'NOTSTARTED', createdOn: serverTimestamp() });
        //create the lvDocument reference for document to capture and set up the initial state
        
        /*
        const docRef = doc(db, "applications", currentOpenApplicationId, "lvDocuments", `${currentOpenApplicationId}_6`);
        await setDoc(docRef, {...DocMetaData})
        */

        DocMetaData?.every(async docData => {
          if(docData.group === "workexperience" && user?.lvProfile?.workExp < 1) {
            docData.showDoc = false
          }
          const docRef = doc(db, "applications", currentOpenApplicationId, "lvDocuments", docData.name)
          await setDoc(docRef, {...docData})
        })

        setShowSpinner(false)
        navigate('/coreform', { state: { nextStep }});

      } catch (error) {
        setShowSpinner(false)
        console.error('Error adding data to Firestore:', error);
      }
    } else {
      try {
        setShowSpinner(true)
        const applicationRef = doc(db, "applications", user.currentOpenApplicationId);
        const currentData = (await getDoc(applicationRef)).data();
        console.log("Current application in progress", currentData)
        setShowSpinner(false)
        navigate(coreFormRoutes[applicationFields.completedSteps], { state: { nextStep }});
      } catch (error) {
        setShowSpinner(false)
        console.error('Error adding data to Firestore:', error);
      }
    }
  }

  console.log("User in dashboard", user)
  const activeStep = applicationSteps.findIndex(step => step.key === applicationFields?.status)
  return (
    <Box sx={{ margin: "25px 0" }}>
      <Typography variant="h6">
        Stay organized and in control with our application dashboard - track your progress, manage your submissions, and track your deadlines with ease and confidence.
      </Typography>
      <Grid container spacing={2} sx={{ padding: "25px 0" }}>
        <Grid item xs={12}>
          <Card sx={{
            background: 'linear-gradient(45deg, #141e30 30%, #243b55 90%)',
            borderRadius: "10px",
            padding: "25px"
          }}
          >
            <ApplicationProgress steps={applicationSteps} activeStep={activeStep < 0 ? 0 : activeStep} />
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ margin: "25px 0" }}>
        <Grid item xs={6}>
          <Card sx={{
            background: 'linear-gradient(45deg, #7b4397 30%, #dc2430 90%)',
            borderRadius: "10px",
            padding: "25px",
          }}>
            <Typography variant="h6" sx={{ color: "#FFFFFF", fontWeight: 'bold' }}>
              Applying for an education finance
            </Typography>
            <br />
            <Typography sx={{ color: "#FFFFFF" }}>Unlock your educational potential with our easy education loan application process.</Typography>
            <br />
            <Button variant="contained" color="secondary" disabled={applicationFields?.status === 'SUBMITTED'} onClick={() => generateApplicationID()} className={classes.button}>
              {
                applicationFields?.status === 'INPROGRESS' ? 'Resume my loan application' : 'Apply my loan'
              }
            </Button>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card sx={{
            background: 'linear-gradient(45deg, #4568dc 30%, #b06ab3 90%)',
            borderRadius: "10px",
            padding: "25px",
          }}>
            <Typography variant="h6" sx={{ color: "#FFFFFF", fontWeight: 'bold' }}>
              Invite a friend
            </Typography>
            <br />
            <Typography sx={{ color: "#FFFFFF" }}>Refer a friend - both of you could benefit from our platform's valuable services.</Typography>
            <br />
            <Button variant="contained" color="secondary" onClick={() => navigate('/referafriend')} className={classes.button}>Refer</Button>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card sx={{
            background: 'linear-gradient(45deg, #02aab0 30%, #00cdac 90%)',
            borderRadius: "10px",
            padding: "25px",
          }}>
            <Typography variant="h6" sx={{ color: "#FFFFFF", fontWeight: 'bold' }}>
              Ask for advice
            </Typography>
            <br />
            <Typography sx={{ color: "#FFFFFF" }}>We're here to help - our dedicated support team is available to assist you with any questions or issues you may have.</Typography>
            <br />
            <Button variant="contained" color="secondary" onClick={() => navigate('/')} className={classes.button}>Support</Button>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
export default Dashboard