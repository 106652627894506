import React, { useState, useCallback, useContext } from "react";
import Paper from '@mui/material/Paper'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from "@mui/material/DialogActions";
import Button from '@mui/material/Button'
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@mui/styles';
import PDFPreview from '../../../../Assets/images/pdf-thumbnail.png';
import { SpinnerContext } from '../../../../context/SpinnerContext'
import Typography from '@mui/material/Typography';
import UploadIllustration from '../../../../Assets/illustrations/add-files.svg'
import Box from '@mui/system/Box'
import { CloudUpload } from '@mui/icons-material';
import { ref, uploadBytes, getDownloadURL, storage, doc, db, getDoc, updateDoc } from "../../../../firebase";
import { UserAuth } from '../../../../context/AuthContext'
import { useSnackbar } from "../../../../context/SnackbarContext";

const useStyles = makeStyles((theme) => ({
  dropzone: {
    border: `1px dashed #082e72`,
    borderRadius: '5px',
    cursor: 'pointer',
    height: '100%',
    padding: '10px',
    textAlign: 'center',
  },
  preview: {
    height: 50,
    width: 50,
  },
}));
export default function DocUploadDialog(props) {
  const { user } = UserAuth();
  const { showSnackbar } = useSnackbar()
  const { setShowSpinner } = useContext(SpinnerContext);
  const [ files, setFiles ] = useState([])
  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    setFiles(acceptedFiles);
  }, [])
  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'image/*' })
  const dialogStyles = useStyles()
  const uploadFiles = async () => {
    const { uploadFor, close, fetchDocData } = props
    close()
    try {        
      setShowSpinner(true);
      const fileExt = files[0].name.split('.').pop();
      const fileName = uploadFor.docName
      const storageRef = ref(storage, user.uid + '/' + fileName+'.'+fileExt);
      await uploadBytes(storageRef, files[0]);
      const downloadURL = await getDownloadURL(storageRef);
      const docRef = doc(db, "applications", user.currentOpenApplicationId, "lvDocuments", uploadFor.name)
      const currentData = (await getDoc(docRef)).data();
      const newData = { ...currentData,
        docName: fileName+'.'+fileExt, 
        uploadedDate: new Date(),
        url: downloadURL,
        status: 'success'
      };
      await updateDoc(docRef, newData);
      showSnackbar(`${uploadFor.value}` + ' uploaded successfully', 'success')
      fetchDocData()
    } catch (error) {
      console.log('unable to store the file into firebase')
      showSnackbar('Unable to upload the document, please try again.', 'error')
    } finally {
      setShowSpinner(false);
      setFiles([])
    }

  }
  return (
    <Dialog open={props.open} onClose={props.close}  maxWidth="sm" fullWidth>
      <DialogTitle>Upload <b><i>{props.uploadFor?.value}</i></b> </DialogTitle>
      <DialogContent>
          <Paper {...getRootProps()} className={dialogStyles.dropzone}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <Typography variant="heading5">Drag and drop a file or click to select a file</Typography>
            <Box
              component="img"
              sx={{
                width: '200px',
                height: '200px',
                objectFit: 'contain'
              }}
              alt="Upload a file"
              src={ UploadIllustration }
            />
            <input {...getInputProps()} />
            { files.length > 0 && (
              <React.Fragment>
                <img src={files[0]?.type === "application/pdf" ? PDFPreview : URL.createObjectURL(files[0])} className={dialogStyles.preview} alt="Preview" />
                <Typography variant="body1">{files[0].name}</Typography>
              </React.Fragment>
            )}
          </Paper>
          <DialogActions>
            <Button variant="contained" disabled={files.length === 0} onClick={uploadFiles} endIcon={<CloudUpload />}>Upload file</Button>
          </DialogActions>
      </DialogContent>
    </Dialog>
  )
}