import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material/styles'
import Theme from "../src/theme"
import { AuthProvider } from './context/AuthContext';
import { Provider } from "react-redux";
import { store } from './store';
import "./style.css";
import { SpinnerProvider } from './context/SpinnerContext';
import SnackbarProvider from './context/SnackbarContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <AuthProvider>
      <SnackbarProvider>
        <SpinnerProvider>
          <ThemeProvider theme={Theme}>
            <App />
          </ThemeProvider>
        </SpinnerProvider>
      </SnackbarProvider>
    </AuthProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
