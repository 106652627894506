import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import enIN from 'date-fns/locale/en-IN';

export function DatePickerController(props) {
  const { helperText, title, isError = false, name, register, errors, watch } = props;
  const regex = /^\d{2}\/\d{2}\/\d{4}$/;
  const [value, setValue] = React.useState(null);
  let formattedDate;
  if(watch(name) && regex.test(watch(name))) {
   const [day, month, year] = watch(name)?.split('/');
   formattedDate = new Date(Number(year), Number(month) - 1, Number(day));
  }
  
  return (
    <FormControl fullWidth error={errors?.[name]?.message} >
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enIN}>
        <DatePicker
          label={title}
          value={formattedDate || null}
          onChange={(newValue) => {
            console.log("newValue",newValue)
            setValue(newValue);
          }}
          renderInput={(params) => <TextField {...params} sx={{ width: "100%" }} {...register(name)} />}
          disableOpenPicker
        />
      </LocalizationProvider>
      { errors?.[name]?.message  &&
        <FormHelperText id="component-helper-text">
          { errors?.[name]?.message  }
        </FormHelperText>
      }
    </FormControl>
    
  );
}

export default DatePickerController;