import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, useLocation, useNavigate, Link } from 'react-router-dom';
import { border, Container } from "@mui/system";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
    Button,
    Paper,
    Alert
} from "@mui/material";
import { UserAuth } from "../../../../context/AuthContext";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Error from "../../page-not-found";
import { db, collection, query, addDoc, getDocs, getDoc, doc, setDoc } from "../../../../firebase";
import ApplicationEditDialog from "./applicationEditModal";



function ApplicationSummary() {
    const { logOut, user } = UserAuth();
    const location = useLocation();
    const navigate = useNavigate();

    const [admissiondata, setAdmissiondata] = useState();
    const [debtdata, setDebtdata] = useState();
    const [coapplicantdata, setCoapplicantdata] = useState();
    const [academicdata, setAcademicdata] = useState();
    const [openmodal, setOpenmodal] = useState(false);
    const [step, setStep] = useState();
    const [stepData, setStepData] = useState();
    const [rowData, setRowData] = useState();
    const [admissionStepdata, setAdmissionStepdata] = useState();
    const [debtStepdata, setDebtStepdata] = useState();
    const [coapplicantStepdata, setCoapplicantStepdata] = useState();
    const [academicStepdata, setAcademicStepdata] = useState();
    const [uid, setUid] = useState();


    const handleOnSignOut = async () => {
        try {
            await logOut();
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        setRowData(location?.state?.rowData);
        getStepTwoDetaile();
        getStepOneDetaile();
        getStepThreeDetaile();
        getStepFourDetaile();
    }, [])

    const formData = {
        //id: location?.state?.rowData.id  
        id: sessionStorage.getItem("appid")
    }

    const getStepOneDetaile = async () => {
        //1 admission    
        const admissionRef = doc(db, "applications", formData.id, "admission", `${formData.id}_1`);
        const admission = (await getDoc(admissionRef)).data();
        const { programName, nameCollegeUniversity, countryName, programCategory, programLevel, admissionStatus, programStartDate } = admission;
        let newadmissionObj = {
            "Program Name": programName,
            "Name of College/University": nameCollegeUniversity,
            "Country": countryName,
            "Program Category": programCategory,
            "Program Level": programLevel,
            "Admission Status": admissionStatus,
            "Tentative Program start date": programStartDate
        };
        if (admission !== undefined) {
            setAdmissiondata(newadmissionObj);
            setAdmissionStepdata(admission);
        };
    }
    const getStepTwoDetaile = async () => {
        //2 Debt
        const debtRef = doc(db, "applications", formData.id, "debt", `${formData.id}_2`);
        const debt = (await getDoc(debtRef)).data();

        let newdebtObj = {
            "Total Course Fee": debt ? debt.fullCourceFeeINR : "",
            "Total living cost": debt ? debt.anyLivingCostINR : "",
            "Total Loan Required": debt ? debt.totalLoanRequiredINR : ""
        };
        setDebtdata(newdebtObj);
        setDebtStepdata(debt);

    }
    const getStepThreeDetaile = async () => {
        //3 coapplicant
        const coapplicantRef = doc(db, "applications", formData.id, "coapplicant", `${formData.id}_3`);
        let coapplicant = (await getDoc(coapplicantRef)).data();
        console.log("coapplicant");
        console.log(coapplicant);
        let newCoapplicantObj = {};
        if (coapplicant !== undefined) {
            newCoapplicantObj = {
                [`Father Name`]: coapplicant[`fathersName`],
                [`Father Email`]: coapplicant[`fatherEmailAddress`],
                [`Father Phone`]: coapplicant[`fatherContactNumber`],
                [`Mother Name`]: coapplicant[`mothersName`],
                [`Mother Email`]: coapplicant[`motherEmailAddress`],
                [`Mother Phone`]: coapplicant[`motherContactNumber`],
                [`Current Home Address`]: coapplicant[`currentHomeAddress`]
            }
        }
        let coappdata = {};
        if (coapplicant.coapplicant !== undefined && coapplicant.coapplicant.length > 0) {
            coapplicant.coapplicant.map((x, index) => {
                let coapplicantObj = {
                    [`Co Applicant ${index === 0 ? '1' : '2'} Name`]: x.coApplicantName,
                    [`Co Applicant ${index === 0 ? '1' : '2'} Pan`]: x.pan,
                    [`Co Applicant ${index === 0 ? '1' : '2'} Relationship to main applicant`]: x.relationshipToMainApplicant,
                };
                coappdata = { ...coappdata, ...coapplicantObj };
            });
        }
        let finalCoappdata = { ...newCoapplicantObj, ...coappdata };
        if (coapplicant !== undefined) {
            setCoapplicantdata(finalCoappdata);
            setCoapplicantStepdata(coapplicant);
        };

    }
    const getStepFourDetaile = async () => {
        //4 Education    
        const academicRef = doc(db, "applications", formData.id, "academic", `${formData.id}_4`);
        // Retrieve admission data to set in default values if exist
        const currentData = (await getDoc(academicRef)).data();
        console.log("currentData");
        console.log(currentData);
        let newacademicObj = {};
        if (currentData !== undefined) {
            newacademicObj = {
                [`Higher Degree`]: currentData[`higherDegree`],
                [`Course`]: currentData[`cource`],
                [`University College Name`]: currentData[`universityCollegeName`],
                [`College Location`]: currentData[`collegeLocation`],
                [`Course Completed Status`]: currentData[`courceCompletedStatus`],
                [`CGPA`]: currentData[`cgpa`],
                [`Expected Time of Compeletion`]: currentData[`expectedTimeofCompeletion`],
                [`Backlogs`]: currentData[`backlogs`]
            }
        }
        if (currentData.testAndScore !== undefined && currentData.testAndScore.length > 0) {
            currentData.testAndScore.map((x, index) => {
                let testObj = {
                    [`${x.testTaken}`]: x.scorebasedonEachTest
                };
                newacademicObj = { ...newacademicObj, ...testObj };
            });
        }
        if (currentData !== undefined) {
            setAcademicdata(newacademicObj);
            setAcademicStepdata(currentData);
        };

    }
    const onClickChange = (step, data) => {
        setOpenmodal(true);
        setStep(step);
        setStepData(data);
    }
    const onClickChangeCoapplicant = (step, data, uid) => {
        setOpenmodal(true);
        setStep(step);
        setStepData(data);
        setUid(uid);
    }
    const handleClose = () => {
        debugger;
        setOpenmodal(false);
        if (step === "step1") {
            getStepOneDetaile();
        } else if (step === "step2") {
            getStepTwoDetaile();
        }
        else if (step === "step3") {
            getStepThreeDetaile();
        }
        else if (step === "step4") {
            getStepFourDetaile();
        }
    };

    const onBackClick = () => {
        navigate("/admin");
    }

    return (
        <>
            <ApplicationEditDialog
                open={openmodal}
                step={step}
                stepData={stepData}
                applicationId={rowData?.id}
                getStepOneDetaile={getStepOneDetaile}
                getStepTwoDetaile={getStepTwoDetaile}
                getStepThreeDetaile={getStepThreeDetaile}
                getStepFourDetaile={getStepFourDetaile}
                handleClose={handleClose}
                uid={uid}
            />
            <Container sx={{ padding: "36px" }}>
                <Grid container spacing={2}>
                    <Grid xs={12} md={12} sx={{ maxHeight: "600px" }}>
                        <Paper
                            elevation={3}
                            sx={{
                                padding: "24px"
                            }}
                        >
                            <Typography gutterBottom>
                                <div className="wizard_title" style={{ marginBottom: "30px" }}>Please review data before submit application</div>
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid xs={12} md={3}>
                                    Admission Details
                                </Grid>
                                <Grid xs={12} md={7}>
                                    <table style={{ width: "100%" }}>
                                        {admissiondata ?
                                            Object.entries(admissiondata).map(([key, value]) => {
                                                return (
                                                    <tr style={{ marginBottom: "10px", height: "40px" }} key={key}>
                                                        <td style={{ width: "70%" }}>{key}:</td>
                                                        <td style={{ width: "30%" }}>{value.toString()}</td>
                                                    </tr>
                                                );
                                            }) : ""
                                        }
                                    </table>
                                </Grid>

                                <Grid xs={12} md={2} style={{ textAlign: "right" }}>
                                    <Link onClick={() => onClickChange("step1", admissionStepdata)}>Change</Link>
                                </Grid>
                            </Grid>
                            <hr style={{ borderTop: "1px solid #cccccc", marginTop: "30px", marginBottom: "30px" }} />
                            <Grid container spacing={2}>
                                <Grid xs={12} md={3}>
                                    Debt Details
                                </Grid>
                                <Grid xs={12} md={7}>
                                    <table style={{ width: "100%" }}>
                                        {debtdata ?
                                            Object.entries(debtdata).map(([key, value]) => {
                                                return (
                                                    <tr style={{ marginBottom: "10px", height: "40px" }} key={key}>
                                                        <td style={{ width: "70%" }}>{key}:</td>
                                                        <td style={{ width: "30%" }}>{value.toString()}</td>
                                                    </tr>
                                                );
                                            }) : ""
                                        }
                                    </table>
                                </Grid>
                                <Grid xs={12} md={2} style={{ textAlign: "right" }}>
                                    <Link onClick={() => onClickChange("step2", debtdata)}>Change</Link>
                                </Grid>
                            </Grid>
                            <hr style={{ borderTop: "1px solid #cccccc", marginTop: "30px", marginBottom: "30px" }} />
                            <Grid container spacing={2}>
                                <Grid xs={12} md={3}>
                                    Co-Applicant Details
                                </Grid>
                                <Grid xs={12} md={7}>
                                    <table style={{ width: "100%" }}>
                                        {coapplicantdata ?
                                            Object.entries(coapplicantdata).map(([key, value]) => {
                                                return (
                                                    <tr style={{ marginBottom: "10px", height: "40px" }} key={key}>
                                                        <td style={{ width: "70%" }}>{key}:</td>
                                                        <td style={{ width: "30%" }}>{value.toString()}</td>
                                                    </tr>
                                                );
                                            }) : ""
                                        }
                                    </table>
                                </Grid>
                                <Grid xs={12} md={2} style={{ textAlign: "right" }}>
                                    <Link onClick={() => onClickChangeCoapplicant("step3", coapplicantdata, academicStepdata.uid)}>Change</Link>
                                </Grid>
                            </Grid>
                            <hr style={{ borderTop: "1px solid #cccccc", marginTop: "30px", marginBottom: "30px" }} />
                            <Grid container spacing={2}>
                                <Grid xs={12} md={3}>
                                    Education Details
                                </Grid>
                                <Grid xs={12} md={7}>
                                    <table style={{ width: "100%" }}>
                                        {academicdata ?
                                            Object.entries(academicdata).map(([key, value]) => {
                                                return (
                                                    <tr style={{ marginBottom: "10px", height: "40px" }} key={key}>
                                                        <td style={{ width: "70%" }}>{key}:</td>
                                                        <td style={{ width: "30%" }}>{value.toString()}</td>
                                                    </tr>
                                                );
                                            }) : ""
                                        }
                                    </table>
                                </Grid>
                                <Grid xs={12} md={2} style={{ textAlign: "right" }}>
                                    <Link onClick={() => onClickChange("step4", academicdata)}>Change</Link>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Button
                                    variant="contained"
                                    type="button"
                                    color="primary"
                                    onClick={onBackClick}
                                    sx={{ margin: "12px 0 12px 0" }}
                                >
                                    Back
                                </Button>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>


        </>
    )
}

export default ApplicationSummary;