const DocMetaData = [
  {
    name: "studentAadhar",
    value: "Aadhar card",
    type: "mandatory",
    group: "identity",
    subGroup: "all",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "student",
    docName: "student_aadhar_card",
    showDoc: true
  },
  {
    name: "studentPan",
    value: "PAN card",
    type: "mandatory",
    group: "identity",
    subGroup: "all",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "student",
    docName: "student_pan_card",
    showDoc: true
  },
  {
    name: "studentPhotograph",
    value: "Applicant Photograph",
    type:"mandatory",
    group: "identity",
    subGroup: "all",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "student",
    docName: "student_photograph",
    showDoc: true
  },
  {
    name: "studentPassport",
    value: "Passport Scanned Copy(Front and Back)",
    type: "mandatory",
    group: "identity",
    subGroup: "all",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "student",
    docName: "student_passport",
    showDoc: true
  },
  {
    name: "tenthMarksheet",
    value: "Class 10 marksheet",
    type: "mandatory",
    group: "academic",
    subGroup: "all",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "student",
    docName: "student_tenth_marksheet",
    showDoc: true
  },
  {
    name: "twelfthMarksheet",
    value: "Class 12 Marksheet",
    type: "mandatory",
    group: "academic",
    subGroup: "all",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "student",
    docName: "student_twelfth_marksheet",
    showDoc: true
  },
  {
    name: "semesterwiseGradeSheet",
    value: "Semester Wise gradesheets (All Individual in one PDF)",
    type: "mandatory",
    group: "academic",
    subGroup: "all",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "student",
    docName: "student_sem_individual_marksheet",
    showDoc: true
  },
  {
    name: "consolidatedMarksheet",
    value: "Consolidated Marksheet",
    type: "mandatory",
    group: "academic",
    subGroup: "all",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "student",
    docName: "student_consolidated_marksheet",
    showDoc: true
  },
  {
    name: "provisional",
    value: "Provisional Degree certificate",
    type: "optional",
    group: "academic",
    subGroup: "all",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "student",
    docName: "student_provisional_certificate",
    showDoc: true
  },
  {
    name: "degree",
    value: "Degree certificate",
    type: "optional",
    group: "academic",
    subGroup: "all",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "student",
    docName: "student_degree_certificate",
    showDoc: true
  },
  {
    name: "offerLetter",
    value: "University offer letter ",
    type: "mandatory",
    group: "academic",
    subGroup: "all",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "student",
    docName: "student_univ_offerletter",
    showDoc: true
  },
  {
    name: "greGmatScorecard",
    value: "GRE/GMAT Scorecard",
    type: "mandatory",
    group: "academic",
    subGroup: "all",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "student",
    docName: "student_gregmat_scorecard",
    showDoc: true
  },
  {
    name: "englishCompetency",
    value: "English Competency score card (TOEFL, IELTS, PTE, Duolingo)",
    type: "mandatory",
    group: "academic",
    subGroup: "all",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "student",
    docName: "student_english_competency_scorecard",
    showDoc: true
  },
  {
    name: "formi20",
    value: "form i20 (if USA student and if form is available)",
    type: "optional",
    group: "academic",
    subGroup: "all",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "student",
    docName: "student_formi20",
    showDoc: true
  },
  {
    name: "bankStatement",
    value: "Latest 8 months bank statement (if student has no work experience)",
    type: "mandatory",
    group: "academic",
    subGroup: "all",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "student",
    docName: "student_bank_statement",
    showDoc: true
  },
  {
    name: "resume",
    value: "Latest CV/Resume",
    type: "mandatory",
    group: "academic",
    subGroup: "all",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "student",
    docName: "student_resume",
    showDoc: true
  },
  {
    name: "studentEmpOfferLetter",
    value: "Employment offer letter",
    type: "mandatory",
    group: "workexperience",
    subGroup: "all",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "student",
    docName: "student_employment_offerletter",
    showDoc: true
  },
  {
    name: "studentLatestPayslips",
    value: "Latest 3 months payslips",
    type: "mandatory",
    group: "workexperience",
    subGroup: "all",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "student",
    docName: "student_payslips",
    showDoc: true
  },
  {
    name: "studentForm16",
    value: "Latest 2 years Form 16 (if available)",
    type: "optional",
    group: "workexperience",
    subGroup: "all",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "student",
    docName: "student_form16",
    showDoc: true
  },
  {
    name: "studentRelievingLetter",
    value: "Relieving letter (if the student has resigned)",
    type: "optional",
    group: "workexperience",
    subGroup: "all",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "student",
    docName: "student_relieving_letter",
    showDoc: true
  },
  {
    name: "studentExpLetter",
    value: "Experience letter",
    type: "optional",
    group: "workexperience",
    subGroup: "all",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "student",
    docName: "student_experience_letter",
    showDoc: true
  },
  {
    name: "studentSalaryBankStatement",
    value: "latest 8 months salary account bank statement",
    type: "mandatory",
    group: "workexperience",
    subGroup: "all",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "student",
    docName: "student_salaryaccount_bankstatement",
    showDoc: true
  },
  {
    name: "fatherAadhar",
    value: "Father's Aadhar card",
    type: "mandatory",
    group: "parents",
    subGroup: "all",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "father",
    docName: "father_aadhar_card",
    showDoc: true
  },
  {
    name: "fatherPan",
    value: "Father's PAN card",
    type: "mandatory",
    group: "parents",
    subGroup: "all",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "father",
    docName: "father_pan_card",
    showDoc: true
  },
  {
    name: "fatherPhotograph",
    value: "Father's Photograph",
    type: "mandatory",
    group: "parents",
    subGroup: "all",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "father",
    docName: "father_photograph",
    showDoc: true
  },
  {
    name: "motherAadhar",
    value: "Mother's Aadhar card",
    type: "mandatory",
    group: "parents",
    subGroup: "all",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "mother",
    docName: "mother_aadhar_card",
    showDoc: true
  },
  {
    name: "motherPan",
    value: "Mother's PAN card",
    type: "mandatory",
    group: "parents",
    subGroup: "all",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "mother",
    docName: "mother_pan_card",
    showDoc: true
  },
  {
    name: "motherPhotograph",
    value: "Mother's Photograph",
    type: "mandatory",
    group: "parents",
    subGroup: "all",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "mother",
    docName: "mother_photograph",
    showDoc: true
  },
  {
    name: "houseBill",
    value: "Latest Electricity bill/Property tax receipt/Gas bill of current address",
    type: "mandatory",
    group: "parents",
    subGroup: "all",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "",
    docName: "residence_bill",
    showDoc: true
  },
  {
    name: "houseLeaseAgreement",
    value: "Lease/Rental agreement",
    type: "optional",
    group: "parents",
    subGroup: "all",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "",
    docName: "house_lease_agreement",
    showDoc: true
  },
  {
    name: "coapp1Aadhar",
    value: "Co-Applicant's Aadhar card",
    type: "mandatory",
    group: "coapplicant1",
    subGroup: "all",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "coapp1",
    docName: "coapp1_aadhar_card",
    showDoc: false
  },
  {
    name: "coapp1PanCard",
    value: "Co-Applicant's PAN card",
    type: "mandatory",
    group: "coapplicant1",
    subGroup: "all",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "coapp1",
    docName: "coapp1_pan_card",
    showDoc: false
  },
  {
    name: "coapp1Photograph",
    value: "Latest 3 months payslips",
    type: "mandatory",
    group: "coapplicant1",
    subGroup: "all",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "coapp1",
    docName: "coapp1_photograph",
    showDoc: false
  },
  {
    name: "coapp1HouseBill",
    value: "Latest Electricity bill/Property tax receipt/Gas bill of current address",
    type: "mandatory",
    group: "coapplicant1",
    subGroup: "all",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "coapp1",
    docName: "coapp1_residence_bill",
    showDoc: false
  },
  {
    name: "coapp1SalariedPayslip",
    value: "Latest 3 months payslips",
    type : "mandatory",
    group: "coapplicant1",
    subGroup: "Employed",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "coapp1",
    docName: "coapp1_salaried_payslips",
    showDoc: false
  },
  {
    name: "coapp1SalariedForm16",
    value: "Latest 2 years Form 16 (if available)",
    type : "optional",
    group: "coapplicant1",
    subGroup: "Employed",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "coapp1",
    docName: "coapp1_salaried_form16",
    showDoc: false
  },
  {
    name: "coapp1SalariedBankStatement",
    value: "Latest 8 months salary account bank statement",
    type : "mandatory",
    group: "coapplicant1",
    subGroup: "Employed",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "coapp1",
    docName: "coapp1_salaryaccount_bankstatement",
    showDoc: false
  },
  {
    name: "coapp1SEBusinessRegAgriIncCert",
    value: "Business Registration/Agriculture income certificate",
    type : "mandatory",
    group: "coapplicant1",
    subGroup: "Self Employed",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "coapp1",
    docName: "coapp1_business_reg_agri_inc_cert",
    showDoc: false
  },
  {
    name: "coapp1SEITR",
    value: "Latest 2 years ITRs (if available)",
    type : "optional",
    group: "coapplicant1",
    subGroup: "Self Employed",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "coapp1",
    docName: "coapp1_selfemployed_itr",
    showDoc: false
  },
  {
    name: "coapp1SEAccBankStatement",
    value: "Latest 8 months current/savings account bank statement",
    type : "mandatory",
    group: "coapplicant1",
    subGroup: "Self Employed",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "coapp1",
    docName: "coapp1_selfemployed_acc_bank_statement",
    showDoc: false
  },
  {
    name: "coapp1pensionPayslip",
    value: "Latest 3 months pension payslips",
    type : "mandatory",
    group: "coapplicant1",
    subGroup: "Pensioner",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "coapp1",
    docName: "coapp1_pension_payslips",
    showDoc: false
  },
  {
    name: "coapp1PensionCert",
    value: "Pension Certificate",
    type : "mandatory",
    group: "coapplicant1",
    subGroup: "Pensioner",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "coapp1",
    docName: "coapp1_pension_cert",
    showDoc: false
  },
  {
    name: "coapp1PensionSavBankStatement",
    value: "Latest 8 months Savings account bank statement",
    type : "mandatory",
    group: "coapplicant1",
    subGroup: "Pensioner",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "coapp1",
    docName: "coapp1_pension_savacc_bank_statement",
    showDoc: false
  },
  {
    name: "coapp2Aadhar",
    value: "Co-Applicant's Aadhar card",
    type: "mandatory",
    group: "coapplicant2",
    subGroup: "all",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "coapp2",
    docName: "coapp2_aadhar_card",
    showDoc: false
  },
  {
    name: "coapp2PanCard",
    value: "Co-Applicant's PAN card",
    type: "mandatory",
    group: "coapplicant2",
    subGroup: "all",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "coapp2",
    docName: "coapp2_pan_card",
    showDoc: false
  },
  {
    name: "coapp2Photograph",
    value: "Co-Applicant's Photograph",
    type: "mandatory",
    group: "coapplicant2",
    subGroup: "all",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "coapp2",
    docName: "coapp2_photograph",
    showDoc: false
  },
  {
    name: "coapp2HouseBill",
    value: "Latest Electricity bill/Property tax receipt/Gas bill of current address",
    type: "mandatory",
    group: "coapplicant2",
    subGroup: "all",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "coapp2",
    docName: "coapp2_residence_bill",
    showDoc: false
  },
  {
    name: "coapp2SalariedPayslip",
    value: "Latest 3 months payslips",
    type : "mandatory",
    group: "coapplicant2",
    subGroup: "Employed",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "coapp2",
    docName: "coapp2_payslips",
    showDoc: false
  },
  {
    name: "coapp2SalariedForm16",
    value: "Latest 2 years Form 16 (if available)",
    type : "optional",
    group: "coapplicant2",
    subGroup: "Employed",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "coapp2",
    docName: "coapp2_salaried_form16",
    showDoc: false
  },
  {
    name: "coapp2SalariedBankStatement",
    value: "Latest 8 months salary account bank statement",
    type : "mandatory",
    group: "coapplicant2",
    subGroup: "Employed",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "coapp2",
    docName: "coapp2_salaryaccount_bankstatement",
    showDoc: false
  },
  {
    name: "coapp2SEBusinessRegAgriIncCert",
    value: "Business Registration/Agriculture income certificate",
    type : "mandatory",
    group: "coapplicant2",
    subGroup: "Self Employed",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "coapp2",
    docName: "coapp2_business_reg_agri_inc_cert",
    showDoc: false
  },
  {
    name: "coapp2SEITR",
    value: "Latest 2 years ITRs (if available)",
    type : "optional",
    group: "coapplicant2",
    subGroup: "Self Employed",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "coapp2",
    docName: "coapp2_selfemployed_itr",
    showDoc: false
  },
  {
    name: "coapp2SEAccBankStatement",
    value: "Latest 8 months current/savings account bank statement",
    type : "mandatory",
    group: "coapplicant2",
    subGroup: "Self Employed",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "coapp2",
    docName: "coapp2_selfemployed_bank_statement",
    showDoc: false
  },
  {
    name: "coapp2pensionPayslip",
    value: "Latest 3 months pension payslips",
    type : "mandatory",
    group: "coapplicant2",
    subGroup: "Pensioner",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "coapp2",
    docName: "coapp2_pension_payslips",
    showDoc: false
  },
  {
    name: "coapp2PensionCert",
    value: "Pension Certificate",
    type : "mandatory",
    group: "coapplicant2",
    subGroup: "Pensioner",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "coapp2",
    docName: "coapp2_pension_cert",
    showDoc: false
  },
  {
    name: "coapp2PensionSavBankStatement",
    value: "Latest 8 months Savings account bank statement",
    type : "mandatory",
    group: "coapplicant2",
    subGroup: "Pensioner",
    status: "pending",
    uploadedDate: "",
    url: "",
    role: "coapp2",
    docName: "coapp2_pension_savacc_bank_statement",
    showDoc: false
  }
]
export default DocMetaData