import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';

function RadioButtonsGroup(props) {
  const { data, label, name, register, errors, watch} = props;
  return (
    <FormControl error={ errors?.[name]?.message }>
      <FormLabel id="demo-radio-buttons-group-label">{label}</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue={watch(name)}
        name="radio-buttons-group"
      >
        {
          data.map((element, index) => {
            return (
              <FormControlLabel
                {...register(name)} value={element.value} control={<Radio />} label={element.label} />
            );
          })
        }
      </RadioGroup>
      { errors?.[name]?.message  &&
        <FormHelperText id="component-helper-text">
          { errors?.[name]?.message  }
        </FormHelperText>
      }
    </FormControl>
  );
}

export default RadioButtonsGroup