import React, { useState, useEffect, useContext } from "react";
import { border, Container } from "@mui/system";
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Button,
  Paper,
  Alert
} from "@mui/material";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { UserAuth } from "../../../../context/AuthContext";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from "../../../atom/InputController";
import CountrySelectController from "../../../atom/CountryController";
import DatePickerController from "../../../atom/DatePickerController";
import AutocompleteSelectController from "../../../atom/AutocompleteController";
import AutocompleteMultipleSelectController from "../../../atom/AutocompleteMultipleController";
//options values
import { addStatusData, programLevel, programCategory, relations, employmentstatus, yesno, higherDegree, cource, backlog, tests, steps } from "./optionsdata";
import { db, collection, addDoc, getDocs, getDoc, doc, setDoc, updateDoc, serverTimestamp } from "../../../../firebase";
import { SpinnerContext } from "../../../../context/SpinnerContext";
import { FormSkeleton } from "../../../atom/Skeleton";

const dateRegex = /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/;

const schema = yup.object({
  higherDegree: yup.string().required('Highest Degree is required'),
  cource: yup.string().required('Cource is required'),
  universityCollegeName: yup.string().required('University/College Name is required'),
  collegeLocation: yup.string().required('College Location is required'),
  courceCompletedStatus: yup.string().required('Course Completed Status is required'),
  expectedTimeofCompeletion: yup.string().matches(dateRegex, 'Invalid date format').required('Expected Time of Compeletion is required.'),
  /*expectedTimeofCompeletion: yup.string().test('conditionalRequired', null, function(value) {
    if (this.parent.courceCompletedStatus === 'No' && !value) {
      return this.createError({
        message: 'Expected time of completion is required',
      });
    }
    return true;
  }).matches(dateRegex, 'Invalid date format').min(new Date(), 'Date must be in the future'),*/
  cgpa: yup.string().required('CGPA is required.'),  
  backlogs: yup.string().required('Backlogs is required'),
  testAndScore: yup.array().of(
    yup.object().shape({
      testTaken: yup.string().required('Test taken is required'),
      scorebasedonEachTest: yup.string().required('Test score is required.').matches(/^\d+$/, "Only numeric value allowed."),
    })
  ),
}).required();

function withFormHook(Component) {
  return function FormComponent(props) {
    const { user } = UserAuth();
    const navigate = useNavigate();
    const [defaultValues, setDefaultValues] = useState({});
    const [backstepdata, setBackstepdata] = useState();
    const [coappdata, setCoappdata] = useState();
    const { setShowSpinner } = useContext(SpinnerContext)
    const { register, handleSubmit, setValue, control, watch, formState: { errors }, reset } = useForm({
      mode: 'onBlur',
      resolver: yupResolver(schema),
      defaultValues: defaultValues
      // defaultValues: location.state ? location.state.backstepdata : []            
    });

    const { fields, append, remove } = useFieldArray({
      name: "testAndScore",
      control,
      rules: {
        required: "Please append at least 1 test"
      }
    });
    console.log("fields:", fields);

    const fetchAcademicFields = async () => {
      if (user?.currentOpenApplicationId) {
        try {
          setShowSpinner(true)
          const academicRef = doc(db, "applications", user?.currentOpenApplicationId, "academic", `${user.currentOpenApplicationId}_4`);
          // Retrieve admission data to set in default values if exist
          const currentData = (await getDoc(academicRef)).data();
          reset(currentData); // set form default values
          setDefaultValues(currentData)
          setShowSpinner(false)
          console.log("Academic fields retrieved from step 1 mount", currentData);
        } catch (error) {
          setShowSpinner(false)
          console.log("Unable to retrieve current academic fields from firebase", error)
        }
      }
      if (props.step ==="step4") {
        try {
          setShowSpinner(true)
          const academicRef = doc(db, "applications", props.applicationId, "academic", `${props.applicationId}_4`);
          // Retrieve admission data to set in default values if exist
          const currentData = (await getDoc(academicRef)).data();
          reset(currentData); // set form default values
          setDefaultValues(currentData)
          setShowSpinner(false)
          console.log("Academic fields retrieved from step 1 mount", currentData);
        } catch (error) {
          setShowSpinner(false)
          console.log("Unable to retrieve current academic fields from firebase", error)
        }
      }
    }

    useEffect(() => {
      fetchAcademicFields();
      fetchCoapplicantData();
    }, []);

    const fetchCoapplicantData = async () => {
      if (user?.currentOpenApplicationId) {
        try {
          const querySnapshot = await getDocs(collection(db, 'applications', user?.currentOpenApplicationId, 'coapplicant'));
          const docs = [];
          querySnapshot.forEach((doc) => {
            docs.push({ id: doc.id, ...doc.data() });
          });
          setCoappdata(docs[0]);
        }
        catch (error) {
          //setShowSpinner(false)
          console.log("Unable to retrieve current academic fields from firebase", error)
        }
      }
      if (props.step ==="step4") {
        try {
          const querySnapshot = await getDocs(collection(db, 'applications', props.applicationId, 'coapplicant'));
          const docs = [];
          querySnapshot.forEach((doc) => {
            docs.push({ id: doc.id, ...doc.data() });
          });
          setCoappdata(docs[0]);
        }
        catch (error) {
          //setShowSpinner(false)
          console.log("Unable to retrieve current academic fields from firebase", error)
        }
      }
    }




    const onSubmit = async (data) => {
      console.log("in academic submit", data);
      let payloadObj = data;
      payloadObj.uid = user.uid;
      console.log(payloadObj);
      try {
        setShowSpinner(true);
        let applicationid = props.step ==="step4" ? props.applicationId : user?.currentOpenApplicationId;
        const applicationRef = doc(db, "applications", applicationid);
        const academicRef = doc(db, "applications", applicationid, "academic", `${applicationid}_4`);
        // Check if the document exists
        const academicDoc = await getDoc(academicRef);
        if (academicDoc.exists()) {
          // Document exists, update its data
          await updateDoc(academicRef, payloadObj);
        } else {
          // Document doesn't exist, add it with a randomly generated ID
          await setDoc(academicRef, payloadObj);
        }
       
        // Get the current data
        const currentData = (await getDoc(applicationRef)).data();
        // Add a new field to the data
        const newData = { ...currentData, completedSteps: 4, status: 'SUBMITTED', submittedOn : serverTimestamp() };
        // Update the document with the new data
        await updateDoc(applicationRef, newData);
        setShowSpinner(false)
        navigate("/");
        //need to validate this
        /*
        localStorage.removeItem('applicationsId');
        localStorage.removeItem('currentempstatus');
        localStorage.removeItem('coapplicantNum');
        */
      } catch (error) {
        setShowSpinner(false)
        console.error('Error adding data to Firestore:', error);
      }
    }
    const onBackClick = () => {
      navigate("/coreform/step3", { state: { backstepdata } });
    }

    return (
      <Component
        {...props}
        register={register}
        setValue={setValue}
        handleSubmit={handleSubmit(onSubmit)}
        errors={errors}
        onBackClick={onBackClick}
        control={control}
        watch={watch}
        append={append}
        remove={remove}
        fields={fields}
      />
    );
  };
}


function StepFiveEducationDetailscomponent(props) {
  const { register, handleSubmit, setValue, errors, fields, append, remove, onBackClick, formData, control, watch, step } = props;
  const { user } = UserAuth();
  const navigate = useNavigate();
  const [inputValue, setInputValue] = React.useState("");
  const { showSpinner } = useContext(SpinnerContext);

  useEffect(() => {
    if (fields.length === 0) {
      append({
        testTaken: "",
        scorebasedonEachTest: ""
      });
    }
  })


  return (
    <form onSubmit={handleSubmit}>
      <Container sx={{ padding: "36px" }}>
        {showSpinner && <FormSkeleton />}
        {!showSpinner &&
          <Grid container spacing={2}>
            <Grid xs={12} md={12} sx={{ maxHeight: "600px" }}>
              <Paper
                elevation={3}
                sx={{
                  padding: "24px"
                }}
              >
                <Typography gutterBottom>
                    {
                        step ===undefined ? <div className="wizard_title">Education Details</div> : ""
                    } 
                    {
                        step ===undefined ? <Box sx={{ width: '100%', marginTop: "30px" }}>
                        <Stepper activeStep={3} alternativeLabel>
                          {steps.map((label) => (
                            <Step key={label}>
                              <StepLabel>{label}</StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </Box> : ""
                    }                  
                  
                  <Grid container spacing={2} sx={{ margin: "12px 0 12px 0" }}>
                    <Grid item xs={12} sm={4}>
                      <AutocompleteSelectController
                        name="higherDegree"
                        title="Highest Degree"
                        register={register}
                        optionsdata={higherDegree}
                        errors={errors}
                        watch={watch}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <AutocompleteSelectController
                        name="cource"
                        title="Course"
                        register={register}
                        optionsdata={cource}
                        errors={errors}
                        watch={watch}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        name="universityCollegeName"
                        title="University/College Name"
                        register={register}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        name="collegeLocation"
                        title="College Location"
                        register={register}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <AutocompleteSelectController
                        name="courceCompletedStatus"
                        title="Course Completed Status"
                        register={register}
                        optionsdata={yesno}
                        errors={errors}
                        watch={watch}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        name="cgpa"
                        title="CGPA"
                        register={register}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <DatePickerController
                        name="expectedTimeofCompeletion"
                        title="Expected time of completion"
                        register={register}
                        errors={errors}
                        watch={watch}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <AutocompleteSelectController
                        name="backlogs"
                        title="Backlogs"
                        register={register}
                        optionsdata={backlog}
                        errors={errors}
                        watch={watch}
                      />
                    </Grid>

                  </Grid>
                  {/*--Test---*/}
                  {fields.map((field, index) => {
                    return (
                      <Grid container spacing={2} sx={{ margin: "0px 0 0px 0" }} key={field.id}>
                        <Grid item xs={12} sm={4}>
                          <AutocompleteSelectController
                            name={`testAndScore[${index}].testTaken`}
                            title="Test Taken"
                            register={register}
                            optionsdata={tests}
                            //errors={errors}
                            errors={errors?.testAndScore?.[index]}
                            watch={watch}
                            //onChange={onChangetest}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            name={`testAndScore[${index}].scorebasedonEachTest`}
                            title="Score based on each test"
                            register={register}
                            //errors={errors}
                            errors={errors?.testAndScore?.[index]}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Button
                            variant="contained"
                            type="button"
                            color="error"
                            disabled={fields.length === 1}
                            onClick={() => {
                              remove(index);

                            }}
                          >
                            Delete
                          </Button>

                        </Grid>
                      </Grid>
                    )
                  })
                  }
                  <Button
                    variant="contained"
                    type="button"
                    color="success"
                    disabled={fields.length === tests.length}
                    onClick={() => {
                      if (fields.length < tests.length) {
                        append({
                          testTaken: "",
                          scorebasedonEachTest: ""
                        });
                      }

                    }}
                  >
                    Add Test
                  </Button>
                  <Divider />
                  {
                      step ===undefined ? <><Button
                      variant="contained"
                      type="button"
                      color="primary"
                      onClick={onBackClick}
                      sx={{ margin: "12px 0 12px 0" }}
                    >
                      Back
                    </Button>
                    {" "}
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{ margin: "12px 0 12px 0" }}
                    >
                      Next
                    </Button></> : <Button
                      variant="contained"
                      type="submit"                      
                      sx={{ margin: "12px 0 12px 0" }}
                    >
                      Save
                    </Button>
                  }
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        }
      </Container>
    </form>
  )
}




const StepFiveEducationDetails = withFormHook(StepFiveEducationDetailscomponent);

export default StepFiveEducationDetails;