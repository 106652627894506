import React from 'react';
import { Navigate } from 'react-router-dom';
import { UserAuth } from '../../context/AuthContext';


const Protected = (props) => {
  const { user } = UserAuth();

  console.log("props in protected", props, user)
  if (!user) {
    return <Navigate to='/login' />;
  }

  if (props.role && user.role !== props.role) {
    return <Navigate to="/unauthorised" />;
  }

  return props.children;
};

export default Protected;