import React from "react";
import { border, Container } from "@mui/system";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
    Button,
    Paper,
    Alert
} from "@mui/material";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { UserAuth } from "../../../../context/AuthContext";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from "../../../atom/InputController";
import CountrySelectController from "../../../atom/CountryController";
import DatePickerController from "../../../atom/DatePickerController";
import AutocompleteSelectController from "../../../atom/AutocompleteController";
import PhotoUpload from "../../../atom/PhotoDropZone";
//options values
import { addStatusData, programLevel, programCategory, relations, employmentstatus, yesno, higherDegree, cource, backlog, tests, steps } from "./optionsdata";

const schema = yup.object({
    firstName: yup.string().required('First name is required'),
    surName: yup.string().required('Surname is required'),
    gender: yup.string().required('Gender is required'),
    contactNumber: yup.string().required('Phone number is required'),
    email: yup.string().email('Invalid email format').required('Email is required'),
    residingState: yup.string().required('Current state of residence is required'),
    residingCity: yup.string().required('Current city of residence is required'),
    residingCountry: yup.string().required('Current country of residence is required'),
}).required();

function withFormHook(Component) {
    return function FormComponent(props) {
        const { register, handleSubmit, setValue, formState: { errors } } = useForm({
            resolver: yupResolver(schema)
        });

        const onSubmit = (data) => {
            debugger;
            console.log(data);
        }

        return (
            <Component
                {...props}
                register={register}
                setValue={setValue}
                handleSubmit={handleSubmit(onSubmit)}
                errors={errors}
            />
        );
    };
}


function StepElevenCoApplicantHomemakerDetailscomponent(props) {
    const { register, handleSubmit, setValue, errors } = props;
    const { user } = UserAuth();
    return (
        <>
            <div className="wizard_title">Co-Applicant (Homemaker)(All the docs in PDF only)</div>
            <Box sx={{ width: '100%', marginTop:"30px" }}>
                <Stepper activeStep={11} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
            <Grid container spacing={2} sx={{ margin: "12px 0 12px 0" }}>
                <Grid item xs={12} sm={4}>
                    <PhotoUpload
                        name="photo"
                        label="Photo"                        
                        register={register}
                        setValue={setValue}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <PhotoUpload
                        name="pancard"
                        label="PAN CARD"                        
                        register={register}
                        setValue={setValue}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <PhotoUpload
                        name="aadhar"
                        label="AADHAAR"                        
                        register={register}
                        setValue={setValue}
                    />
                </Grid>                
                <Grid item xs={12} sm={4}>
                    <PhotoUpload
                        name="ownhouseelectricbill"
                        label="Own House Electricity Bill (For Field Investigation purpose only)"                        
                        register={register}
                        setValue={setValue}
                    />
                </Grid>                
                <Grid item xs={12} sm={4}>
                    <PhotoUpload
                        name="propertytaxreceipt"
                        label="Property Tax Receipt (For Field Investigation purpose only)"                        
                        register={register}
                        setValue={setValue}
                    />
                </Grid>
                

            </Grid>
            <Divider />
            <Button
                variant="contained"
                type="submit"
                sx={{ margin: "12px 0 12px 0" }}
            >
                Next
            </Button>
        </>
    )
}



const StepElevenCoApplicantHomemakerDetails = withFormHook(StepElevenCoApplicantHomemakerDetailscomponent);

export default StepElevenCoApplicantHomemakerDetails;