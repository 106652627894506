import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate, useLocation } from "react-router-dom";
import { Backdrop } from '@mui/material';
import StepOneAdmissionDetail from "../coreform-wizard/formstep1AdmissionDetail";
import StepTwoDebtRequirements from "../coreform-wizard/formstep2DebtRequirment";
import StepThreeParentDetails from "../coreform-wizard/formstep3ParentDetails";
import StepFiveEducationDetails from "../coreform-wizard/formstep5AducationDetails";

export default function ApplicationEditDialog(props) {

    const [open, setOpen] = useState();
    const [step, setStep] = useState();
    const [stepData, setStepData] = useState();
    const [applicationId, setApplicationId] = useState();
    const [uid, setUid] = useState();

    useEffect(() => {       
        setOpen(props.open);
        setStep(props.step);
        setStepData(props.stepData);
        setApplicationId(sessionStorage.getItem("appid"));    
        setUid(props.uid);            
    }, [props])
    

    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="lg"
            fullWidth
            sx={{ p: 2 }}
        >
            <DialogTitle id="alert-dialog-title">
                {step ==="step1" ? "Update Admission Details" : ""}
                {step ==="step2" ? "Update Debt Details" : ""}
                {step ==="step3" ? "Update Co-Applicant Details" : ""}
                {step ==="step4" ? "Update Education Details" : ""}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">   
                {step ==="step1" ?                 
                    <StepOneAdmissionDetail
                        step={step}
                        stepData={stepData}
                        applicationId={applicationId}
                        handleClose={props.handleClose}
                    /> : ""}
                {step ==="step2" ?
                    <StepTwoDebtRequirements
                        step={step}
                        stepData={stepData}
                        applicationId={applicationId}
                        handleClose={props.handleClose}
                    /> : ""}
                {step ==="step3" ?
                    // <>Hello</>
                    <StepThreeParentDetails
                        step={step}
                        stepData={stepData}
                        uid={uid}
                        applicationId={applicationId}
                        handleClose={props.handleClose}
                    /> 
                    : ""}
                {step ==="step4" ?
                    <StepFiveEducationDetails
                        step={step}
                        stepData={stepData}
                        applicationId={applicationId}
                        handleClose={props.handleClose}
                    /> : ""}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose} variant="outlined">Close</Button>

            </DialogActions>
            <br />
            <Backdrop open={open} sx={{ zIndex: -10, color: '#fff', opacity: 0.8 }} />
        </Dialog>
    );
}