import { createContext, useState } from 'react';
import LoadingPulse from '../components/atom/LoaderBackdrop';

export const SpinnerContext = createContext();

export const SpinnerProvider = ({ children }) => {
  const [showSpinner, setShowSpinner] = useState(false);
  console.log("showSpinner", showSpinner)
  return (
    <SpinnerContext.Provider value={{ showSpinner, setShowSpinner }}>
      {children}
      <LoadingPulse showSpinner={showSpinner} />
    </SpinnerContext.Provider>
  );
};
