import { Box, Typography } from '@mui/material';

export const PrivacyPolicy = () => {
  return (
    <Box maxWidth={800} mx="auto" px={3} py={5}>
      <Typography variant="h4" mb={3}>
        LVEE FINTECH PVT LTD - PRIVACY POLICY
      </Typography>
      <Box>
        <Typography variant="h5" mb={3}>
          1. DEFINITIONS
        </Typography>
        <Typography variant="body1" mb={3}>
          For the purpose of this Privacy Policy (hereinafter referred to as the “Policy”), wherever the context so requires,
        </Typography>
        <Typography variant="body1" mb={3}>
          a) The term ‘Company’ shall mean ‘LVEE Fintech Private Limited’ a Private Limited Company limited
          by shares and registered under the Companies Act, 2013 and having its registered office Flat No 2103
          Level 10 9th Floor T2 Block2, Prestige Westwood Apartments Gopalapura, Bangalore, Karnataka, India,
          560023.
        </Typography>
        <Typography variant="body1" mb={3}>
          b) The term ‘website’ shall mean one or all of the websites <a href="https://www.loanvidya.co.in" target="_blank" rel="noopener noreferrer">www.loanvidya.co.in</a>, <a href="https://www.loanvidya.com" target="_blank" rel="noopener noreferrer">www.loanvidya.com</a>,
          and <a href="https://www.lveefintech.com" target="_blank" rel="noopener noreferrer">www.lveefintech.com</a>.
        </Typography>
        <Typography variant="body1" mb={3}>
          c) The term ‘You’ & ‘User’ shall mean any legal person or entity accessing or using the services provided
          on this Website, who is competent to enter into binding contracts, as per the provisions of the Indian
          Contract Act, 1872;
        </Typography>
        <Typography variant="body1" mb={3}>
          d) The terms ‘We’, ‘Us’ & ‘Our’ shall mean the Website and/or the Company, as the context so
          requires.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5" mb={3}>
          2. GENERAL
        </Typography>
        <Typography variant="body1" mb={3}>
          a) We are committed to safeguarding your privacy and ensuring that you continue to trust us with
          your personal data. When you interact with us you may share personal information with us which
          allows identification of you as an individual. This is known as personal data.
        </Typography>
        <Typography variant="body1" mb={3}>
          b) This document is an electronic record in terms of Information Technology Act, 2000 and rules there
          under as applicable and the amended provisions pertaining to electronic records in various statutes
          as amended by the Information Technology Act, 2000. This electronic record is generated by a
          computer system and does not require any physical or digital signatures.
        </Typography>
        <Typography variant="body1" mb={3}>
          c) This document is published in accordance with the provisions of Rule 3 (1) of the Information
          Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations,
          privacy policy and Terms of Use for access or usage of loanvidya.com
        </Typography>
        <Typography variant="body1" mb={3}>
          d)This website is owned and operated by the Company.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5" mb={3}>
          3. SCOPE AND ACCEPTANCE OF THIS PRIVACY POLICY
        </Typography>
        <Typography variant="body1" mb={3}>
          a) This Policy applies to the personal data that we collect about you for the purposes of providing you
          with our services.
        </Typography>
        <Typography variant="body1" mb={3}>
          b) By using this website or by giving us your personal data, you accept the practices described in this
          Policy. If you do not agree to this Privacy Policy, please do not use this website or give us any personal
          data.
        </Typography>
        <Typography variant="body1" mb={3}>
          c) We reserve the right to change this Policy without prior notice. We encourage you to regularly
          review this policy to ensure that you are aware of any changes and how your personal data may be
          used.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5" mb={3}>
          4. DATA COLLECTED BY US
        </Typography>
        <Typography variant="body1" mb={3}>
          We may collect data about you from a variety of sources, including through:
        </Typography>
        <Typography variant="body1" mb={3}>
          a) Online and electronic interactions with us, including via the website, mobile applications, text
          messaging programs or through our pages on third party social networks.
        </Typography>
        <Typography variant="body1" mb={3}>
          b) Your interaction with online targeted content (such as advertisements) that we or service providers
          on our behalf provide to you via third party websites and/or applications.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5" mb={3}>
          4.1 DATA THAT YOU PROVIDE US DIRECTLY
        </Typography>
        <Typography variant="body1" mb={3}>
          This is data that you provide us with your consent for a specified purpose including:
        </Typography>
        <Typography variant="body1" mb={3}>
          a) Personal contact information, including any information allowing us to contact you in person (e.g.,
          name, home or email address and phone number);
        </Typography>
        <Typography variant="body1" mb={3}>
          b) Demographic information, including date of birth, age, gender, location.
        </Typography>
        <Typography variant="body1" mb={3}>
          c) Education Loan Account login information including any information that is required for you to
          establish a user account with us. (e.g., login ID/email, user name, password, and security
          question/answer);
        </Typography>
        <Typography variant="body1" mb={3}>
          d) Consumer feedback, including information that you share with us about your experience in using
          our services (e.g., your comments and suggestions, testimonials, and other feedback)
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5" mb={3}>
          4.2 DATA WE COLLECT WHEN YOU VISIT OUR WEBSITE
        </Typography>
        <Typography variant="body1" mb={3}>
          We use certain kinds of information when you interact with the website. We use cookies that store
          your preferences and give us data about your browsing behaviour, such as the pages you visited on
          the website, the amount of time you spend on the website etc. This information helps us understand
          the interests of our customers and helps us improve our website.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5" mb={3}>
          5. DIVULGING/SHARING OF PERSONAL INFORMATION
        </Typography>
        <Typography variant="body1" mb={3}>
          a) We may share your personal information with other corporate entities and affiliates to help detect
          and prevent identity theft, fraud, and other potentially illegal acts; correlate related or multiple
          accounts to prevent abuse of our services; and to facilitate relevant services, which may be joint, cobranded, or offered independently by other entities.
        </Typography>
        <Typography variant="body1" mb={3}>
          b) We may disclose personal information if required to do so by law or if we in good faith believe that
          such disclosure is reasonably necessary to respond to subpoenas, court-orders, or other legal
          processes. We may also disclose your personal information to law enforcement offices, third party
          rights owners, or other third parties if we believe that such disclosure is reasonably necessary to
          enforce the ‘Terms of Use’ or Policy; respond to claims that an advertisement, posting, or other
          content violates the rights of a third party; or protect the rights, property or personal safety of its
          users, or the general public.
        </Typography>
        <Typography variant="body1" mb={3}>
          c) We and our affiliates may share / sell some or all of your personal information with other business
          entities in the event that we merge or are acquired by such business entity, or in the event of reorganization, amalgamation, or restructuring of our business. Such business entity or new entity will
          continue to be bound be the Terms and Policy, as may be amended from time to time.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5" mb={3}>
          6. USE OF PERSONAL INFORMATION
        </Typography>
        <Typography variant="body1" mb={3}>
          We and our affiliated partners may use the personal information submitted by you to contact you in
          relation to the services offered. This shall override any calling preferences, which you may have
          registered in the NDNC.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5" mb={3}>
          7. SECURITY
        </Typography>
        <Typography variant="body1" mb={3}>
          Transactions on the Website are secure and protected. Any information entered by the User when
          transacting on the Website is encrypted to protect the User against unintentional disclosure to third
          parties. The User’s credit and debit card information is not received, stored by or retained by the
          Company / Website in any manner. This information is supplied by the User directly to the relevant
          payment gateway which is authorized to handle the information provided, and is compliant with the
          regulations and requirements of various banks and institutions and payment franchisees that it is
          associated with.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5" mb={3}>
          8. THIRD PARTY ADVERTISEMENTS / PROMOTIONS
        </Typography>
        <Typography variant="body1" mb={3}>
          We use third-party advertising companies to serve ads to the users of the Website. These companies
          may use information relating to the User’s visits to the Website and other websites to provide
          customised advertisements to the User. Furthermore, the Website may contain links to other websites
          that may collect personally identifiable information about the User. The Company/Website is not
          responsible for the privacy practices or the content of any of the linked websites, and the User
          expressly acknowledges the same and agrees that any and all risks associated will be borne entirely
          by the User.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5" mb={3}>
          9. GRIEVANCE OFFICER
        </Typography>
        <Typography variant="body1" mb={3}>
          In accordance with Information Technology Act 2000 and rules made there under, the name and
          contact details of the Grievance Officer are provided below:
        </Typography>
        <Typography variant="body1" mb={3}>
          LVEE FinTech Support
        </Typography>
        <Typography variant="body1" mb={3}>
          Email: support@loanvidya.co.in
        </Typography>
        <Typography variant="body1" mb={3}>
          Mobile: +91 79759 30543
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5" mb={3}>
          10. DATA SECURITY & RETENTION
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5" mb={3}>
          10.1 DATA SECURITY
        </Typography>
        <Typography variant="body1" mb={3}>
          In order to keep your personal data secure, we have implemented a number of security measures
          including:
        </Typography>
        <Typography variant="body1" mb={3}>
          a) We store your data in secure operating environments accessible only to our employees, agents and
          affiliates on a need-to-know basis.
        </Typography>
        <Typography variant="body1" mb={3}>
          b) We use 256-bit encryption to provide protection for sensitive financial information, such as credit
          card information sent over the Internet.
        </Typography>
        <Typography variant="body1" mb={3}>
          c) Prior authentication for account access - We require our registered users to verify their identity (e.g.
          login ID and password) before they can access or make changes to their account. This is aimed to
          prevent unauthorized accesses.
        </Typography>
        <Typography variant="body1" mb={3}>
          d) Please note that these protections do not apply to personal data you choose to share in public areas
          such as on community websites.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5" mb={3}>
          10.2 RETENTION
        </Typography>
        <Typography variant="body1" mb={3}>
          a) We will only retain your personal data for as long as it is necessary for the stated purpose, taking
          into account also our need to answer queries or resolve problems, provide improved and new services,
          and comply with legal requirements under applicable laws. This means that we may retain your
          personal data for a reasonable period after your last interaction with us. When the personal data that
          we collect is no longer required in this way, we destroy or delete it in a secure manner.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5" mb={3}>
          11. APPLICABLE LAWS & DISPUTE RESOLUTION
        </Typography>
        <Typography variant="body1" mb={3}>
          Any controversy or claim arising out of or relating to this policy shall be decided by Arbitration in
          accordance with laws of India. The Arbitral Tribunal shall consist of one arbitrator who will be
          appointed by the Company. Any such controversy or claim shall be arbitrated on an individual basis,
          and shall not be consolidated in any arbitration with any claim or controversy of any other party. Any
          other dispute or disagreement of a legal nature will also be decided in accordance with the laws of
          India, and the Courts at Karnataka shall have exclusive jurisdiction in all such cases.
        </Typography>
      </Box>
    </Box>
  );
};

export const TermsAndConditions = () => {
  return (
    <Box maxWidth={800} mx="auto" px={3} py={5}>
      <Typography variant="body1" mb={3}>
        Welcome to  <a href="https://www.loanvidya.co.in" target="_blank" rel="noopener noreferrer">www.loanvidya.co.in</a>, <a href="https://www.loanvidya.com" target="_blank" rel="noopener noreferrer">www.loanvidya.com</a>,
        and <a href="https://www.lveefintech.com" target="_blank" rel="noopener noreferrer">www.lveefintech.com</a> (hereinafter ‘the website.’). ‘LVEE Fintech Private Limited’, having its registered office at Flat No 2103 Level 10 9th Floor T2 Block2, Prestige Westwood Apartments Gopalapura, Bangalore, Karnataka, India, 560023 (hereinafter referred to as ‘the Company’, ‘we’ and ‘our’) provides access to the website to you, subject to the terms contained herein (hereinafter ‘the/this TOU’). Please read this TOU carefully before using the website. By using the Website, you signify your agreement to be bound by this TOU. This TOU constitutes an electronic record within the meaning of the applicable law. This electronic record is generated by a computer system and does not require any physical or digital signatures.
      </Typography>
      <Typography variant="h4" mb={3}>
        LOANVIDYA - TERMS OF USE
      </Typography>
      <Box>
        <Typography variant="h5" mb={1}>
          1. DEFINITIONS
        </Typography>
        <Typography variant="body1" mb={1}>
          Words used in this TOU shall have the following meanings assigned to them:
        </Typography>
        <Typography variant="body1" mb={1}>
          A) ‘Computer’
        </Typography>
        <Typography variant="body1" mb={1}>
          Any device used to access the website.
        </Typography>
        <Typography variant="body1" mb={1}>
          B) ‘Intending Borrower”
        </Typography>
        <Typography variant="body1" mb={1}>
          An individual who needs a loan and/or a scholarship grant/aid to fund his/her education/training programs/co-curricular activities and has registered on the website as a ‘Borrower’.
        </Typography>
        <Typography variant="body1" mb={1}>
          C) ‘Intending Lender”
        </Typography>
        <Typography variant="body1" mb={1}>
          An individual and/or an institution interested in exploring opportunities to communicate with ‘intending borrowers’ and has registered on the website as a ‘Lender’.
        </Typography>
        <Typography variant="body1" mb={1}>
          D) ‘User’
        </Typography>
        <Typography variant="body1" mb={1}>
          Every person visiting/accessing this Website.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5" mb={1}>
          2. WHAT DO WE DO? WE FACILITATE COMMUNICATION BETWEEN INTENDING BORROWERS AND INTENDING LENDERS.
        </Typography>
        <Typography variant="body1" mb={1}>
          The Website is a venue for users to exchange information with the eventual goal of entering into a Lender-Borrower relationship. There is no guarantee that that an Intending Borrower will successfully secure credit or receive any expression of interest from any Intending Lender. Similarly, there is no guarantee that an Intending Lender will successfully find an Intending Borrower who is credit worthy in the opinion of the Intending lender.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5" mb={1}>
          2A. WHAT DO WE CHARGE?
        </Typography>
        <Typography variant="body1" mb={1}>
          We charge a usage fee, which is charged only if/when two users enter into an agreement to give/receive funds. If the Lender is a financial institution, like a bank, we do not charge any fees from the Borrower. Our corporate level agreements with the Lender in such cases ensure that the Lender pays us a commission based on every loan that gets sanctioned. Currently, we charge 1% of the total money being given by the lender to the borrower. The borrower is not charged any fee by Loan Vidya for the entirety of the transaction. This fee is subject to change upon prior notice.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5" mb={1}>
          3. SAMPLE AGREEMENTS PROVIDED ON THE WEBSITE ARE NOT MANDATORY.
        </Typography>
        <Typography variant="body1" mb={1}>
          The Website may, from time to time provide sample borrower-lender agreements/formats which can be used by users at their discretion. However, using the aforementioned agreements is not mandatory. Every user is free to execute contracts which are in variance from the sample agreements available on the Website.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5" mb={1}>
          4. PLEASE CONSULT A LAWYER.
        </Typography>
        <Typography variant="body1" mb={1}>
          It is strongly advised that a user consult a lawyer/legal consultant of their own choice before exchanging any information; entering into any agreement and/or entering into any relationship with any user.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5" mb={1}>
          5. WE DO NOT VOUCH FOR THE INTEGRITY AND/OR CREDIT WORTHINESS OF ANY USER OF THIS WEBSITE.
        </Typography>
        <Typography variant="body1" mb={1}>
          Except to the extent specifically mentioned in these Terms of Use, we do not involve ourselves in the agreements/communication/dealing between users/intending borrowers/intending lenders. We do not ensure the integrity and/or credit-worthiness of any user. The user is solely responsible for assessing the integrity, credentials and credit-worthiness of all persons with whom any user communicates on the Website.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5" mb={1}>
          6. We are not an agent of any user.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5" mb={1}>
          7. No recipients of content from the Website should act, or refrain from acting on
          account/premise of any or all the contents of the Website.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5" mb={1}>
          8. THE WEBSITE/WE ARE NOT:
        </Typography>
        <Typography variant="body1" mb={1}>
          i) a Bank of any nature;
        </Typography>
        <Typography variant="body1" mb={1}>
          ii) an investing agency of any nature;
        </Typography>
        <Typography variant="body1" mb={1}>
          iii) a Lender, a borrower, or a guarantor of any kind;
        </Typography>
        <Typography variant="body1" mb={1}>
          iv) a verification agency of any kind;
        </Typography>
        <Typography variant="body1" mb={1}>
          v) an intermediary of any kind;
        </Typography>
        <Typography variant="body1" mb={1}>
          vi) a Non-Banking Financial Company
        </Typography>
        <Typography variant="body1" mb={1}>
          vii)a Credit information Company
        </Typography>
        <Typography variant="body1" mb={1}>
          viii) a Credit rating company
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5" mb={1}>
          9. LICENSE
        </Typography>
        <Typography variant="body1" mb={1}>
          I.  We grant to every user a limited license to access and make personal use of this Website but not to download (other than page caching) or modify it, or any portion of it. This license does not include any resale or commercial use of the Website or its contents; any downloading or copying of any information or any use of data mining, robots, or similar data gathering and extraction tools. The Website or any portion of it (including but not limited to any copyrighted material, trademarks, or other proprietary information) shall not be reproduced, duplicated, copied, sold, resold, visited, distributed, or otherwise exploited for any commercial purpose without our express written consent.
        </Typography>
        <Typography variant="body1" mb={1}>
          II.  The user is prohibited from framing or using framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of the Website without express written consent. The user is also prohibited from using any Meta tags or any other "hidden text" in the Website. Any unauthorised use terminates the license granted by the Website.
        </Typography>
        <Typography variant="body1" mb={1}>
          III.  The user is granted a limited, revocable, and non-exclusive right to create a hyperlink to the Welcome page of the Website as long as the link does not portray the Website, the company, or their products or services in a false, misleading, derogatory, or otherwise offensive matter. The user is prohibited from using any logo or other proprietary graphic or trademark of the Website and/or the company as part of the link without our express written consent.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5" mb={1}>
          10. RIGHT TO MODIFY TERMS OF USE WITHOUT PRIOR NOTICE
        </Typography>
        <Typography variant="body1" mb={1}>
          We reserve the right to make changes to the Website, policies, this TOU and any fee charged at any time without any prior notice. The user will be subject to the policies and terms in force at the time that he/she uses the Website. If any of these conditions is deemed invalid, void, or for any reason unenforceable, that condition will be deemed severable and will not affect the validity and enforceability of any/all remaining condition(s).
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5" mb={1}>
          11. CONFIDENTIALITY OF ACCOUNT DETAILS IS THE RESPONSIBILITY OF THE USER
        </Typography>
        <Typography variant="body1" mb={1}>
          Users are responsible for maintaining the confidentiality of their account and password, for restricting access to the computer used to access the Website to prevent unauthorised access to their accounts. Users are responsible for all activities that occur under their account and/or password. Users should take all necessary steps to ensure that the password is kept confidential and secure and should inform the Website immediately if they have any reason to believe that their password has become known to anyone else, or if the password is being, or is likely to be, used in an unauthorised manner. Users are responsible for ensuring that the details provided to/on the Website are correct and complete and inform us through the Website immediately of any changes to the information that provided by them. Users can access and update much of the information provided by them with in the ‘profile’ area of the Website. We reserve the right to refuse access to the Website, terminate accounts, remove, or edit content at any time without notice to the user.
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1" mb={1}>
          12. Reasonable efforts will be made to ensure that availability of the Website will be uninterrupted and that transmissions will be error-free. However, due to the nature of the Internet, this cannot be guaranteed. Also, access to the Website may also be occasionally suspended or restricted to allow for repairs, maintenance, or the introduction of new facilities or services at any time without prior notice. We will attempt to limit the frequency and duration of any such suspension or restriction.
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1" mb={1}>
          Users are prohibited from using the Website for any of the following:
        </Typography>
        <Typography variant="body1" mb={1}>
          i) for fraudulent purposes, or in connection with a criminal offense or other unlawful activity;
        </Typography>
        <Typography variant="body1" mb={1}>
          ii) to send, use or reuse any material that does not belong to them; or is illegal, offensive
          (including but not limited to material that is sexually explicit content or which promotes racism, bigotry, hatred, or physical harm), deceptive, misleading, abusive, indecent, harassing,
        </Typography>
        <Typography variant="body1" mb={1}>
          iii) blasphemous, defamatory, libellous, obscene, pornographic, paedophilic, or menacing; ethnically objectionable, disparaging or in breach of copyright, trademark, confidentiality, privacy or any other proprietary information or right; or is otherwise injurious to third parties; or relates to or promotes money laundering or gambling; or is harmful to minors in any way; or impersonates another person; or threatens the unity, integrity, security or sovereignty of India or friendly relations with foreign States; or objectionable or otherwise unlawful in any manner whatsoever; or which consists of or contains software viruses, political campaigning, commercial solicitation, chain letters, mass mailings or any "spam”;
        </Typography>
        <Typography variant="body1" mb={1}>
          iv) to cause annoyance, inconvenience, or needless anxiety.
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1" mb={1}>
          14. Users may post reviews, comments and other content; send communications; and submit suggestions, ideas, comments, questions, or other information, as long as the content is not illegal, obscene, abusive, threatening, defamatory, invasive of privacy, infringing of intellectual property rights, or otherwise injurious to third parties, or objectionable and does not consist of or contain software viruses, political campaigning, commercial solicitation, chain letters, mass mailings, or any form of "spam." In the event a user uses a false e-mail address, impersonates any person or entity, or otherwise misleads as to the origin of any content. We reserve the right (but not the obligation) to remove, refuse, delete or edit any content that in the sole judgement of the Website violates this TOU and, or terminate any user’s permission to access or use the Website.
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1" mb={1}>
          15. If any user posts content or submits material, unless it is indicated otherwise and to the extent permitted by law, the user:
        </Typography>
        <Typography variant="body1" mb={1}>
          (a) grants to the Company and its affiliates and licensees a non-exclusive, royalty-free, irrevocable, perpetual and fully sub-licensable rights to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content throughout the world in any media; and
        </Typography>
        <Typography variant="body1" mb={1}>
          (b) grants to the Company and its affiliates and sub licensees the right to use the name that the user submits in connection with such content, if they choose.
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1" mb={1}>
          16. The rights granted by the user in Clause 15 of these terms are irrevocable during the entire period of protection of a user’s intellectual property rights associated with such content and material. The user agrees to waive his/her right to be identified as the author of such content and the right to object to derogatory treatment of such content. The user agrees to perform all further acts necessary to perfect any of the above rights granted by the user to the Company, including the execution of deeds and documents, at its request.
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1" mb={1}>
          17. The user represents and warrants that he/she owns or otherwise controls all the rights to the content that posted by him/her or otherwise provided on or through the Website; that, as at the date that the content or material is submitted to the Website:
        </Typography>
        <Typography variant="body1" mb={1}>
          i) the content and material are accurate;
        </Typography>
        <Typography variant="body1" mb={1}>
          ii) use of the content and material user supplies does not breach any applicable policies or guidelines of the Website and will not cause injury to any person or entity (including that the content or material is not defamatory);
        </Typography>
        <Typography variant="body1" mb={1}>
          iii) the content is lawful.
        </Typography>
        <Typography variant="body1" mb={1}>
          iv) The user agrees to indemnify the Company and its affiliates for all claims brought by a
          third party against it or its affiliates arising out of or in connection with a breach of any of these warranties.
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1" mb={1}>
          18. If any user believes that any content on the Website is illegal, offensive (including but not limited to material that is sexually explicit content or which promotes racism, bigotry, hatred or physical harm), deceptive, misleading, abusive, indecent, harassing, blasphemous, defamatory, libellous, obscene, pornographic, paedophilic or menacing; ethnically objectionable, disparaging; or is otherwise injurious to third parties; or relates to or promotes money laundering or gambling; or is harmful to minors in any way; or impersonates another person; or threatens the unity, integrity, security or sovereignty of India or friendly relations with foreign States; or objectionable or otherwise unlawful in any manner whatsoever; or which consists of or contains software viruses (hereinafter ‘objectionable content’), it is requested that the user immediately notifies the Website by following the procedure at . Once this procedure has been followed and if in the discretion of the Website/the Company the material/contents are found to be objectionable, the Website will make reasonable efforts to remove such objectionable content within a reasonable time.
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1" mb={1}>
          19. All content included on the Website, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of the Company, its affiliates or its content suppliers and is protected by law. The compilation of all content on this Website is the exclusive property of the Company and its affiliates and is protected by law. All software used on this Website is the property of the Company, its affiliates or its software suppliers and is protected by law. Users are prohibited from systematically extracting and/or re-utilising parts of the contents of the Website.
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1" mb={1}>
          20. The Company and its affiliates respect the intellectual property of others. If you believe that your intellectual property rights have been used in a way that gives rise to concerns of infringement, please follow the procedure at https://loanvidya.com/privacypolicy
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5" mb={1}>
          21. LIMITED LIABILITY & WARRANTY
        </Typography>
        <Typography variant="body1" mb={1}>
          Use of the Website is exclusively at the risk of the users.
        </Typography>
        <Typography variant="body1" mb={1}>
          i) Users acknowledge and undertake that they are accessing the Website and transacting at their own risk and are using their best and prudent judgment before entering into any transactions with any other user. We shall neither be liable nor responsible for any actions or inactions of any user. We hereby expressly disclaim and any all responsibility and liability in this regard.
        </Typography>
        <Typography variant="body1" mb={1}>
          ii) We will not be responsible for any business loss (including loss of profits, revenue, contracts, anticipated savings, data, goodwill or wasted expenditure) or any other indirect or consequential loss that is suffered by the user.
        </Typography>
        <Typography variant="body1" mb={1}>
          iii) We will not be responsible for any loss of data resulting from accidental or deliberate deletion, network or system outages, backup failure, file corruption, or any other reasons.
        </Typography>
        <Typography variant="body1" mb={1}>
          iv) Third party content may appear on the Website or may be accessible via links from the Website. We are not responsible for and assume no liability for any mistakes, misstatements of law, defamation, slander, libel, omissions falsehood, obscenity or profanity in the statements, opinions, representations, or any other form of information contained in any third- party content appearing on the Website. The user understands that the information and opinions in the third-party content is neither endorsed by nor does it reflect the belief of the Company.
        </Typography>
        <Typography variant="body1" mb={1}>
          v) We shall in no event be responsible for any damage that occurs to the computer used by the user to access the Website.
        </Typography>
        <Typography variant="body1" mb={1}>
          vi) The Website is provided on an "as is" basis. THE COMPANY, IT’S DIRECTORS, ITS EMPLOYEES AND ITS AFFILIATES, TO THE FULLEST EXTENT PERMITTED BY LAW, DISCLAIM ALL WARRANTIES, EITHER EXPRESS OR IMPLIED, STATUTOR OR OTHERWISE, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, NON-INFRINGMENT OF THIRD PARTY'S RIGHT, AND FITNESS FOR PARTICULAR PURPOSE.
        </Typography>
        <Typography variant="body1" mb={1}>
          vii) Without limiting the foregoing, The Company, its Directors and Affiliates make no representations or warranties about the accuracy, reliability, completeness, correctness, or timeliness of any content, software, text, graphics, links, or communications provided on or through the use of the Website. In no event shall the Company/the Website, its licensors, its suppliers, or any third parties mentioned on the Website be liable for any damages (including, without limitation, incidental and consequential damages, personal injury/wrongful death, lost profits, or damages resulting from lost data or business interruption) resulting from the use of or inability to use the Website whether based on warranty, contract, tort, or any other legal theory, and whether or not the Website/the Company is advised of the possibility of such damages.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5" mb={1}>
          22. ANY TRANSACTION/AGREEMENT BETWEEN USERS WILL BE BIPARTITE & AT THE EXCLUSIVE RISK OF SUCH USERS.
        </Typography>
        <Typography variant="body1" mb={1}>
          It is however reiterated and made abundantly clear that such aforementioned relationship/ agreement shall be bipartite between the borrower and the lender. The Website/the Company will not be privy to any such contract. In the event any user fails to honour its obligations/promises towards another user, the aggrieved user shall be exclusively responsible and liable to insofar as the pursuit of remedies against such defaulting user is concerned. We do not vouch for the credibility integrity and credit worthiness of any users; all users are advised to conduct a thorough due diligence before entering into agreement/transaction with any other user.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5" mb={1}>
          23. COMMUNICATION BETWEEN THE WEBSITE AND USERS.
        </Typography>
        <Typography variant="body1" mb={1}>
          Submission of any contact details by the user on the Website shall be treated as consent of the User to be contacted by us via the mode of contact whose details have been shared by the user.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5" mb={1}>
          24. SEVERABILITY
        </Typography>
        <Typography variant="body1" mb={1}>
          The invalidity or unenforceability of any provision of this TOU shall not affect the validity or enforceability of any other provision of this TOU, which shall remain in full force and effect.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5" mb={1}>
          25. INDEMNITY
        </Typography>
        <Typography variant="body1" mb={1}>
          The user agrees that we are not responsible for any harm that his/her use of this Website may cause. The user agrees to indemnify, defend, and hold us harmless from and against any and all liability and costs incurred in connection with any loss, liability, claim, demand, damage, and expenses arising from or in connection with the contents or use of the service. The user agrees that this defence and indemnity shall also apply to any breach by the user of this TOU or the foregoing representations, warranties, and covenants. The user further agrees that this defence and indemnity shall include without limitation lawyer fees and costs. The user also agrees that this defence and indemnity shall apply to the Website, the Company and its founders, officers and employees. The Company reserves the right, at its own expense, to assume the exclusive defence and control of any matter otherwise subject to indemnification by the user and the user shall not in any event settle any matter without the written consent of the Company.
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1" mb={1}>
          26. Use of the Website is available only to persons who can form a legally binding contract under the Indian Contract Act, 1872.
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1" mb={1}>
          27. We will not be responsible for any delay or failure to comply with our obligations under these conditions if the delay or failure arises from any cause which is beyond our reasonable control.
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1" mb={1}>
          28. In the event of any user acting/omitting to act in breach of this TOU, omission on part of the Company to pursue their remedies against such breach shall not constitute a waiver of the rights of the Company to pursue such remedies.
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1" mb={1}>
          29. Certain sections of the Website may require users to register. If registration is requested, the user agrees to provide the Website with accurate and complete registration information. It is the user’s responsibility to inform the Website of any changes to that information. Each registration is for a single person only, unless specifically designated otherwise on the registration page. The Website/the Company does not permit
        </Typography>
        <Typography variant="body1" mb={1}>
          a) any other person using the registered sections under your name; or
        </Typography>
        <Typography variant="body1" mb={1}>
          b) access through a single name being made available to multiple users on a network.
          The user is responsible for preventing such unauthorized use. If the user believes there has been unauthorized use, he/she shall notify the Website immediately. If it is found that unauthorized use is being made of the Website, the right of any or many users may be terminated.
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1" mb={1}>
          30. Credit Card/Bank Information- Your online transaction on LoanVidya is secure with the highest levels of transaction security currently available on the Internet. LoanVidya uses 256- bit encryption technology to protect your card information while securely transmitting it to the respective banks for payment processing. All credit card and debit card payments on Loanvidya
          are processed through secure and trusted payment gateways managed by leading banks. Banks now use the 3D Secure password service for online transactions, providing an additional layer of security through identity verification.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5" mb={1}>
          31. PRIVACY POLICY
        </Typography>
        <Typography variant="body1" mb={1}>
          The Privacy Policy contained at <a href="https://www.loanvidya.com/privacypolicy" target="_blank" rel="noopener noreferrer">www.loanvidya.co.in</a>, forms a part of this TOU. The personal information /data provided to us by any user during usage of the Website will be treated in accordance with the Privacy Policy and applicable laws and regulations. If you object to your information being transferred or used, please do not use the Website.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5" mb={1}>
          32. ENTIRE AGREEMENT
        </Typography>
        <Typography variant="body1" mb={1}>
          This TOU together with the documents [referred to in this Agreement] constitute(s) the entire agreement between the parties with respect to its subject matter and constitutes and supersedes all prior agreements, representations, and understandings of the parties, written or oral.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5" mb={1}>
          33. APPLICABLE LAWS & DISPUTE RESOLUTION
        </Typography>
        <Typography variant="body1" mb={1}>
          Any controversy or claim arising out of or relating to this TOU and/or the Website and/or the Company shall be decided by Arbitration in accordance with laws of India. The Arbitral Tribunal shall consist of one arbitrator who will be appointed by the Company. Any such controversy or claim shall be arbitrated on an individual basis, and shall not be consolidated in any arbitration with any claim or controversy of any other party. Any other dispute or disagreement of a legal nature will also be decided in accordance with the laws of India, and the Courts at Karnataka shall have exclusive jurisdiction in all such cases.
        </Typography>
      </Box>
    </Box>
  );
}