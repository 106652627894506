
import { createTheme } from '@mui/material/styles';
import lvwoffFont from '../Assets/font/lvfont.woff2';

const Theme = createTheme({
  background: "#fdfdfd",
  palette: {
    primary: {
      main: '#082e72',
      hover: '#6CACE4'
    },
    secondary: {
      main: '#FFFFFF',
      hover: '#6CACE4'
    },
    mode: 'light'
  },
  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#082e72',
            color: '#ffffff',
            '.MuiListItemIcon-root' : {
              color: '#ffffff',
            },
            '&:hover': {
              backgroundColor: '#6CACE4',
              color: '#ffffff',
            }
          },
          ':hover': {
            backgroundColor: '#6CACE4',
            color: '#ffffff',
          },
          '&.MuiStepIcon': {
            '&:active': {
              color: '#f64f64',
            }
          }
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff', // change this to your desired primary color
          color: '#000000'
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'LVWoffFont';
          src: url(${lvwoffFont}) format('woff');
          font-weight: normal;
          font-style: normal;
          font-display: swap;
        }
      `,
    },
  },
  typography: {
    fontFamily: 'LVWoffFont',
  },
})

export default Theme;