import React, {useState} from "react";
import OutlinedInput from '@mui/material/OutlinedInput'
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import { useForm, Controller } from "react-hook-form";

const TextField = (props) => {
  const { helperText, title, errors, register, name, type, onChange, disabled, value} = props;
  const key = name?.lastIndexOf('.') > 0 ? name.substring(name.lastIndexOf('.') + 1) : name;
  
  return (
    
     <FormControl fullWidth error={ errors?.[key]?.message } >
     <InputLabel htmlFor="outlined-adornment-amount">{title}</InputLabel>
     <OutlinedInput
       id="outlined-adornment-amount"
       label={title}
       type={type}
       {...register(name)}
       onChange={onChange}       
     />
     { errors?.[key]?.message  &&
        <FormHelperText id="component-helper-text">
          { errors?.[key]?.message  }
        </FormHelperText>
      }
   </FormControl>
  );
}
export default TextField;