import React, { useState, useEffect, useContext } from "react";
import { border, Container } from "@mui/system";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Button,
  Paper,
  Alert
} from "@mui/material";
import { UserAuth } from "../../../../context/AuthContext";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Avatar from "../../../organisms/avatar";
import TextField from "../../../atom/InputController";
import RadioButtonsGroup from "../../../atom/RadioController";
import CountrySelectController from "../../../atom/CountryController";
import CustomCountrySelectController from "../../../atom/CustomCountryController";
import AutocompleteSelectControllerWithOnchange from "../../../atom/AutucompleteControllerWithOnChange";
import DatePickerController from "../../../atom/DatePickerController";
import PhotoUpload from "../../../atom/PhotoDropZone";
import { db, collection, addDoc, storage, getDocs, ref, uploadBytes, getDownloadURL } from "../../../../firebase";
import { useNavigate } from "react-router-dom";
import Dropzone from "../../../atom/dropzone";
import { SpinnerContext } from "../../../../context/SpinnerContext";
import { addStatusData, programLevel, programCategory, relations, steps, selectedCountry } from "../coreform-wizard/optionsdata";
import { countries } from "../coreform-wizard/countrylist";
import { consultants } from "./consultants.js";

const dateRegex = /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/;

const schema = yup.object({
  firstName: yup.string().required('First name is required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  surName: yup.string().required('Surname is required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  gender: yup.string().required('Gender is required'),
  profilePicture: yup.array()
    .min(1, 'At least one file is required')
    .test('fileType', 'Only JPG or PNG files are allowed', (files) => {
      if (!files) return true; // no files selected
      return files.every(file => {
        const fileType = file.type;
        return fileType === 'image/jpeg' || fileType === 'image/png';
      });
    })
    .test('fileSize', 'File size is too large', (files) => {
      if (!files) return true; // no files selected
      return files.every(file => file.size <= 1024 * 1024 * 10); // 10MB limit
    }),
  contactNumber: yup.string().required('Phone number is required').matches(/^\d+$/, "Only numeric value allowed."),
  email: yup.string().email('Invalid email format').required('Email is required'),
  residingState: yup.string().required('Current state of residence is required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  residingCity: yup.string().required('Current city of residence is required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  residingCountry: yup.string().required('Current country of residence is required'),
  dob: yup.string().matches(dateRegex, 'Invalid date format').required('Date of birth is required'),
  choiceOfCountry: yup.string().required('Choice of planned country is required'),
  workExp: yup.number().typeError("Work experience must be a number").required('Work experience is required').min(0, 'Work experience must be greater than or equal to 0'),
  pan: yup.string().required('PAN number is required'),
  aadhar: yup.string().required('Aadhar number is required').matches(/^\d+$/, "Only numeric value allowed."),
  passport: yup.string().required('Passport number is required'),
  existingLoan: yup.string().required('Existing Loan details is required'),
  referrer: yup.string().required('Referrer details is required'),
  referrerOther: yup.string().test('conditionalRequired', null, function (value) {
    if (this.parent.referrer === 'Others' && !value) {
      return this.createError({
        message: 'Referrer Other details is required',
      });
    }
    return true;
  }),
  monthlyIncome: yup.number().typeError("Monthly income must be a number").required("Monthly income is required").min(0, 'Monthly income must be greater than or equal to 0'),
}).required();

function withFormHook(Component) {


  return function FormComponent(props) {

    const { register, handleSubmit, setValue, control, watch, formState: { errors } } = useForm({
      mode: 'onBlur',
      resolver: yupResolver(schema)
    });


    const uploadFileAndData = async (file, data, user, updateUser) => {
      //Get storage reference before uploading document
      const storageRef = ref(storage, `${user.uid}/` + file.name);
      //upload the document
      await uploadBytes(storageRef, file);
      //Obtain the downloadable URL of uploaded document
      const downloadURL = await getDownloadURL(storageRef);
      //Add form data into firebase
      data = { ...data, profilePicture: downloadURL }
      console.log("Data after upload", data)
      let basicInfoRef;
      try {
        basicInfoRef = collection(db, "profiles", user.uid, "basicInfo");
        await addDoc(basicInfoRef, data);
        await updateUser({ lvProfile: data })
        console.log("Basic info saved successfully!");
      } catch (error) {
        console.error("Error saving basic info: ", error);
      }
      return basicInfoRef;
    };

    const onSubmit = async (data, metaData) => {      
      let dataObj = {...data, referrer:data.referrer.toUpperCase()}
      console.log(dataObj);      
      const { user, updateUser, setShowSpinner, navigate } = metaData;
      console.log("in submit----->", dataObj, metaData);
      try {
        setShowSpinner(true)
        const docRef = await uploadFileAndData(dataObj.profilePicture[0], dataObj, user, updateUser);
        setShowSpinner(false)
        navigate('/')
        console.log('Data added to Firestore with ID:', docRef.id);
      } catch (error) {
        console.error('Error adding data to Firestore:', error);
        setShowSpinner(false)
      }
    }

    return (
      <Component
        {...props}
        register={register}
        setValue={setValue}
        handleSubmit={handleSubmit(onSubmit)}
        errors={errors}
        control={control}
        watch={watch}
      />
    );
  };
}


const ProfileSetup = (props) => {

  const { register, handleSubmit, errors, control, watch } = props;
  const navigate = useNavigate();
  const { user, updateUser } = UserAuth();
  const { setShowSpinner } = useContext(SpinnerContext);
  const [data, setData] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [referrerOther, setReferrerOther] = useState(false);

  useEffect(() => {
    filteredCountry();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, 'applications'));
      const docs = [];
      querySnapshot.forEach((doc) => {
        docs.push({ id: doc.id, ...doc.data() });
      });
      setData(docs);
    };
    fetchData();
  }, []);

  const filteredCountry = () => {
    let selectedcountry = selectedCountry;
    let allcountry = countries;
    let filtCountry = [];
    for (let i = 0; i < allcountry.length; i++) {
      for (let j = 0; j < selectedcountry.length; j++) {
        if (selectedcountry[j].toLowerCase() === allcountry[i].label.toLowerCase()) {
          filtCountry.push(allcountry[i]);
        }
      }
    }
    const others = {
      "code": "Others",
      "label": "Others",
      "phone": "0",
      "suggested": true
    };
    filtCountry.push(others);
    setCountriesList(filtCountry);
  }

  const fieldValue = watch('referrer');

  const onChangeCunsultant = (e) => {
    console.log("*******", fieldValue);
    console.log(watch('referrer'));
    if (e.target.innerHTML === "Others") {
      setReferrerOther(true);
    } else {
      setReferrerOther(false);
    }
  }

  
  console.log("Register----->", register);

  return (
    <React.Fragment>
      <Container sx={{ padding: "36px" }}>
        <form onSubmit={(e) => {
          e.preventDefault()
          handleSubmit({
            user: user,
            updateUser: updateUser,
            setShowSpinner: setShowSpinner,
            navigate: navigate
          })
        }}>
          <Grid container spacing={2}>
            <Grid xs={12} md={12}>
              <Paper
                elevation={3}
                sx={{
                  padding: "24px"
                }}
              >

                <Typography variant="h4" gutterBottom>
                  Get started with a complete profile set up
                </Typography>
                <Divider />
                <Grid container spacing={2} sx={{ margin: "12px 0 12px 0" }}>
                  <Grid item xs={12}>
                    <Controller
                      name="profilePicture"
                      control={control}
                      defaultValue={[]}
                      render={({ field: { onChange, value } }) => (
                        <Dropzone onChange={onChange}
                          value={value}
                          label="Profile Picture"
                          errors={errors}
                          name="profilePicture"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="firstName"
                      title="First name as in passport"
                      register={register}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="surName"
                      title="Surname as in passport"
                      register={register}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <RadioButtonsGroup
                      name="gender"
                      label="Gender"
                      data={[{ label: "Male", value: "Male" }, { label: "Female", value: "Female" }]}
                      register={register}
                      errors={errors}
                      watch={watch}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="contactNumber"
                      title="Phone Number"
                      register={register}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="email"
                      title="E-Mail"
                      register={register}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePickerController
                      name="dob"
                      title="Date of birth"
                      register={register}
                      errors={errors}
                      watch={watch}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="residingCity"
                      title="Current city of residence"
                      register={register}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="residingState"
                      title="Current state of residence"
                      register={register}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CountrySelectController
                      name="residingCountry"
                      title="Current country of residence"
                      register={register}
                      errors={errors}
                      watch={watch}
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={6}>
                    <CountrySelectController
                      name="choiceOfCountry"
                      title="Choice of country"
                      register={register}
                      errors={errors}
                      watch={watch}
                    />
                  </Grid> */}
                  <Grid item xs={12} sm={6}>
                    <CustomCountrySelectController
                      name="choiceOfCountry"
                      title="Choice of country"
                      register={register}
                      optionsdata={countriesList}
                      errors={errors}
                      watch={watch}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="workExp"
                      title="Work experience in Years"
                      register={register}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="monthlyIncome"
                      title="Total monthly income (if any)"
                      register={register}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="pan"
                      title="Pan Card Number"
                      register={register}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="aadhar"
                      title="Aadhar Number"
                      register={register}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="passport"
                      title="Passport Number"
                      register={register}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <RadioButtonsGroup
                      name="existingLoan"
                      label="Do you have any existing student Loan"
                      data={[{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }]}
                      register={register}
                      errors={errors}
                      watch={watch}
                    />
                  </Grid>
                  {/**New Text field as per feedback user will enter data ALL Caps */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="referrer"
                      title="You were referred by which consultancy"
                      register={register}
                      errors={errors}                      
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={6}>
                    <AutocompleteSelectControllerWithOnchange
                       name="referrer"
                       title="You were referred by which consultancy"
                       register={register}
                       optionsdata={consultants}
                       errors={errors}
                       watch={watch} 
                       onChange={(e) => onChangeCunsultant (e)}
                    />  
                    <div style={{marginTop:"20px"}}>
                    {
                      referrerOther ===true ? <TextField
                      name="referrerOther"
                      title="Enter other consultancy name"
                      register={register}
                      errors={errors}
                    /> : ""
                    }
                    </div>                   */}

                  {/* <RadioButtonsGroup
                      name="referrer"
                      label="You were referred by which consultancy"
                      data={[{ label: "Santamonica (Kochi)", value: "SM" }, { label: "Others", value: "OT" }]}
                      register={register}
                      errors={errors}
                      watch={watch}
                    /> */}

                </Grid>
                <Divider />
                <Button
                  variant="contained"
                  type="submit"
                  sx={{ margin: "12px 0 12px 0" }}
                >
                  Complete your profile
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Container>
    </React.Fragment>
  );
}

const ProfileSetupWrapper = withFormHook(ProfileSetup);

export default ProfileSetupWrapper