import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@mui/styles';
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { UserAuth } from "../../context/AuthContext";
import { db, collection, addDoc, getDocs, doc } from "../../firebase";
import PDFPreview from '../../Assets/images/pdf-thumbnail.png';

const useStyles = makeStyles((theme) => ({
  dropzone: {
    border: `2px dashed #082e72`,
    borderRadius: '5px',
    cursor: 'pointer',
    height: '100%',
    padding: '10px',
    textAlign: 'center',
  },
  preview: {
    height: 200,
    width: 200,
  },
}));

const Dropzone = ({ onChange, label, name, errors, coapplicantdata }) => {
  const { user } = UserAuth();

  //console.log("error in dropzone", errors['profilePicture'] )
  const classes = useStyles();
  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone(
    {
      onDrop: (acceptedFiles) => {        
        //"Student_<Name>_<Document Type>"        
        const modifiedFiles = acceptedFiles.map((file) => {
          const fileExt = file.name.split('.').pop();
          const newdisplayname = user.displayName.replace(/\s+/g, '');
          //File name for Student related Documents
          if (name === "photo" || name === "pancard" || name === "aadhar" || name === "passport" || name === "tenth" || name === "twelveth" || name === "semestermarksheet" || name === "provisionalcertificate" || name === "consolidatedmarksheet" || name === "degreecertificate" || name === "appointmentletter" || name === "eightmonthbankstatement" || name === "lastthreemonthpayslip" || name === "lasttwoyearform16" || name === "resume" || name === "experienceletter") {
            const newFileName = "Student_" +newdisplayname+ "_" + name + "." + fileExt; // Modify the file name here
            return new File([file], newFileName, { type: file.type });
          }
          //File name for Co applicant document           
          else if (
            name === "coapplicantPhotoSalaried" || name === "coapplicantPancardSalaried" || name === "coapplicantaadharSalaried" ||
            name === "coapplicant3monthpayslipSalaried" || name === "coapplicantlast8monthbankstatementSalaried" ||
            name === "coapplicantlast2yearform16Salaried" || name === "coapplicantownhouseelectricbillSalaried" ||
            name === "coapplicantpropertytaxreceiptSalaried" || name === "coapplicantPhotoSelf" ||
            name === "coapplicantPancardSelf" || name === "coapplicantaadharSelf" || name === "coapplicantlast3yearitrSelf" ||
            name === "coapplicantincomestatementSelf" || name === "coapplicantlast12monthbankstatementSelf" ||
            name === "coapplicantbusinessregistrationcopySelf" || name === "coapplicantownhouseelectricbillSelf" ||
            name === "coapplicantpropertytaxreceiptSelf" || name === "coapplicantphotoPensioner" ||
            name === "coapplicantpancardPensioner" || name === "coapplicantaadharPensioner" ||
            name === "coapplicantlast8monthbankstatementPensioner" || name === "coapplicantpensioncertificatePensioner" ||
            name === "coapplicantownhouseelectricbillPensioner" || name === "coapplicantpropertytaxreceiptPensioner" ||
            name === "coapplicantphotoHomemaker" || name === "coapplicantpancardHomemaker" || name === "coapplicantaadharHomemaker" ||
            name === "coapplicantownhouseelectricbillHomemaker" || name === "coapplicantpropertytaxreceiptHomemaker") {
            //"<Relationship with student>_<Name of the Co-Applicant>_<Document Type>"
            const fileExt = file.name.split('.').pop();
            const relation = coapplicantdata.coapplicant[0].relationshipToMainApplicant;
            const coappname = coapplicantdata.coapplicant[0].coApplicantName;
            const newFileName = relation + "_" + coappname + "_" + name + "." + fileExt; // Modify the file name here
            return new File([file], newFileName, { type: file.type });
          }
          else if (name === "fatherPhoto" || name === "motherPhoto") {
            const fileExt = file.name.split('.').pop();            
            const newFileName = "Father" + "_" + "photo" + "." + fileExt; // Modify the file name here
            return new File([file], newFileName, { type: file.type });
          }
          else {
            const newFileName = name + file.name; // Modify the file name here
            return new File([file], newFileName, { type: file.type });
          }

        });
        onChange(modifiedFiles);

      },
      accept: 'image/*'
    }
  );
  return (
    <FormControl fullWidth error={errors?.[name]?.message}>
      <FormLabel id="drop-zone-label">{label}</FormLabel>
      <Grid container direction="column" spacing={2} sx={{ margin: "8px 0" }}>
        <Grid item {...getRootProps()} className={classes.dropzone}>
          <input {...getInputProps()} />
          {acceptedFiles.length > 0 ? (
            <img src={acceptedFiles[0]?.type === "application/pdf" ? PDFPreview : URL.createObjectURL(acceptedFiles[0])} className={classes.preview} alt="Preview" />
          ) : (
            <Typography variant="body1">Drag and drop an image here or click to select a file</Typography>
          )}
        </Grid>
      </Grid>
      {errors?.[name]?.message &&
        <FormHelperText id="component-helper-text">
          {errors?.[name]?.message}
        </FormHelperText>
      }
    </FormControl>

  );
};
export default Dropzone;
