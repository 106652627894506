import Container from '@mui/material/Container'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"
import Login from "../src/components/pages/login"
import Header from "../src/components/organisms/header"
import Error from "./components/pages/page-not-found"
import StudentHome from "./components/pages/home"
import ProfileSetup from './components/pages/student/profile'
import Profile from './components/pages/student/profile/profileReadOnly'
import Protected from './components/organisms/protected'
import Dashboard from './components/pages/student/dashboard'
import UnderConstruction from './components/pages/under-construction'
import ReferAFriend from './components/pages/student/refer-a-friend'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import Formwizard from './components/pages/student/coreform-wizard/formwizard';
import StepOneAdmissionDetail from './components/pages/student/coreform-wizard/formstep1AdmissionDetail';
import StepTwoDebtRequirements from './components/pages/student/coreform-wizard/formstep2DebtRequirment';
import StepThreeParentDetails from './components/pages/student/coreform-wizard/formstep3ParentDetails';
import StepFiveEducationDetails from "./components/pages/student/coreform-wizard/formstep5AducationDetails";
import StepSixMandatoryApplicantDocumentDetails from './components/pages/student/coreform-wizard/formstep6MandatoryApplicantDocument';
import { TermsAndConditions, PrivacyPolicy } from './components/pages/login/Legal'

import AdminDashboardWrapper from "./components/pages/admin/admin-dashboard/adminDashboard";
import ApplicationSummary from "./components/pages/student/coreform-summary/applicationSummary";
import DocumentUpload from "./components/pages/student/document";
import DocGridList from "./components/pages/student/document/docGridList";



const router = createBrowserRouter([
  {
    path: "/login",
    element:  <StudentHome><Login /></StudentHome>,
    errorElement: <StudentHome><Error /></StudentHome>,
  },
  {
    path: "/termsandcondition",
    element:  <StudentHome><TermsAndConditions /></StudentHome>,
    errorElement: <StudentHome><Error /></StudentHome>,
  },
  {
    path: "/privacypolicy",
    element:  <StudentHome><PrivacyPolicy /></StudentHome>,
    errorElement: <StudentHome><Error /></StudentHome>,
  },
  {
    path: "/",
    element: <><StudentHome><Protected role="ROLE_STUDENT"><Dashboard /></Protected></StudentHome></>,
    errorElement: <StudentHome><Error /></StudentHome>,
  },
  {
    path: "/applications",
    element: <><StudentHome><Protected role="ROLE_STUDENT"><UnderConstruction /></Protected></StudentHome></>,
    errorElement: <StudentHome><Error /></StudentHome>,
  },
  {
    path: "/track",
    element: <><StudentHome><Protected role="ROLE_STUDENT"><UnderConstruction /></Protected></StudentHome></>,
    errorElement: <StudentHome><Error /></StudentHome>,
  },
  {
    path: "/products",
    element: <><StudentHome><Protected role="ROLE_STUDENT"><UnderConstruction /></Protected></StudentHome></>,
    errorElement: <StudentHome><Error /></StudentHome>,
  },
  {
    path: "/documents",
    element: <><StudentHome><Protected role="ROLE_STUDENT"><DocumentUpload /></Protected></StudentHome></>,
    errorElement: <StudentHome><Error /></StudentHome>,
  },
  {
    path: "/documents/list",
    element: <><StudentHome><Protected role="ROLE_STUDENT"><DocGridList /></Protected></StudentHome></>,
    errorElement: <StudentHome><Error /></StudentHome>,
  },
  {
    path: "/actions",
    element: <><StudentHome><Protected role="ROLE_STUDENT"><UnderConstruction /></Protected></StudentHome></>,
    errorElement: <StudentHome><Error /></StudentHome>,
  },
  {
    path: "/support",
    element: <><StudentHome><Protected role="ROLE_STUDENT"><UnderConstruction /></Protected></StudentHome></>,
    errorElement: <StudentHome><Error /></StudentHome>,
  },
  {
    path: "/profilesetup",
    element: <><StudentHome><Protected role="ROLE_STUDENT"><ProfileSetup /></Protected></StudentHome></>,
    errorElement: <StudentHome><Error /></StudentHome>,
  },
  {
    path: "/profile",
    element: <><StudentHome><Protected role="ROLE_STUDENT"><Profile /></Protected></StudentHome></>,
    errorElement: <StudentHome><Error /></StudentHome>,
  },
  {
    path: "/coreform",
    element: <><StudentHome><Protected role="ROLE_STUDENT"><StepOneAdmissionDetail /></Protected></StudentHome></>,
    errorElement: <StudentHome><Error /></StudentHome>,  
  },  
  {
    path: "/coreform/step2",
    element: <><StudentHome><Protected role="ROLE_STUDENT"><StepTwoDebtRequirements /></Protected></StudentHome></>,
    errorElement: <StudentHome><Error /></StudentHome>,
  },
  {
    path: "/coreform/step3",
    element: <><StudentHome><Protected role="ROLE_STUDENT"><StepThreeParentDetails /></Protected></StudentHome></>,
    errorElement: <StudentHome><Error /></StudentHome>,
  },
  {
    path: "/coreform/step4",
    element: <><StudentHome><Protected role="ROLE_STUDENT"><StepFiveEducationDetails /></Protected></StudentHome></>,
    errorElement: <StudentHome><Error /></StudentHome>,
  },
  {
    path: "/coreform/step5",
    element: <><StudentHome><Protected role="ROLE_STUDENT"><StepSixMandatoryApplicantDocumentDetails /></Protected></StudentHome></>,
    errorElement: <StudentHome><Error /></StudentHome>,
  },
  {
    path: "/admin",
    element: <><StudentHome><Protected role="ROLE_ROOT_ADMIN"><AdminDashboardWrapper /></Protected></StudentHome></>,
    errorElement: <StudentHome><Error /></StudentHome>,
  },
  {
    path: "/admin/summary",
    element: <><StudentHome><Protected role="ROLE_ROOT_ADMIN"><ApplicationSummary /></Protected></StudentHome></>,
    errorElement: <StudentHome><Error /></StudentHome>,
  },
  {
    path: "/unauthorised",
    element: <><Protected><Error /></Protected></>
  },
  {
    path: "/referafriend",
    element: <><StudentHome><Protected role="ROLE_STUDENT"><ReferAFriend /></Protected></StudentHome></>,
    errorElement: <StudentHome><Error /></StudentHome>
  }
]);

const App = () => {
  return (
      <Container maxWidth='xl' sx={{background: "#fdfdfd"}}>
          <RouterProvider router={router} />
      </Container>
  );
}

export default App;
