import React, { useState, useEffect, useContext } from "react";
import { border, Container } from "@mui/system";
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
    Button,
    Paper,
    Alert
} from "@mui/material";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { UserAuth } from "../../../../context/AuthContext";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from "../../../atom/InputController";
import CountrySelectController from "../../../atom/CountryController";
import DatePickerController from "../../../atom/DatePickerController";
import AutocompleteSelectController from "../../../atom/AutocompleteController";
import PhotoUpload from "../../../atom/PhotoDropZone";
//options values
import { addStatusData, programLevel, programCategory, relations, employmentstatus, yesno, higherDegree, cource, backlog, tests, steps } from "./optionsdata";
import { db, collection, addDoc, getDocs, getDoc, doc, storage, ref, uploadBytes, getDownloadURL, updateDoc, setDoc } from "../../../../firebase";
import Dropzone from "../../../atom/dropzone";
import { SpinnerContext } from "../../../../context/SpinnerContext";

const currentempStatusGlobal = localStorage.getItem('currentempstatus');

const fileValidation = yup.array()
    //.min(1, 'At least one file is required')
    .test('fileType', 'Only PDF, JPG, or PNG files are allowed', (files) => {
        if (!files) return true; // no files selected
        return files.every(file => {
            const fileType = file.type;
            return fileType === 'application/pdf' || fileType === 'image/jpeg' || fileType === 'image/png';
        });
    })
    .test('fileSize', 'File size is too large', (files) => {
        if (!files) return true; // no files selected
        return files.every(file => file.size <= 1024 * 1024 * 10); // 10MB limit
    })

const fileValidationnonmandatory = yup.array()
    .test('fileType', 'Only PDF, JPG, or PNG files are allowed', (files) => {
        if (!files) return true; // no files selected
        return files.every(file => {
            const fileType = file.type;
            return fileType === 'application/pdf' || fileType === 'image/jpeg' || fileType === 'image/png';
        });
    })
    .test('fileSize', 'File size is too large', (files) => {
        if (!files) return true; // no files selected
        return files.every(file => file.size <= 1024 * 1024 * 10); // 10MB limit
    })

const schema = yup.object({
    photo: fileValidation,
    pancard: fileValidation,
    aadhar: fileValidation,
    passport: fileValidation,
    tenth: fileValidation,
    twelveth: fileValidation,
    semestermarksheet: fileValidation,
    provisionalcertificate: fileValidation,
    consolidatedmarksheet: fileValidation,
    degreecertificate: fileValidation,
    admitLetter: fileValidationnonmandatory,
    i20: fileValidationnonmandatory,


    appointmentletter: fileValidation,
    eightmonthbankstatement: fileValidation,
    lastthreemonthpayslip: fileValidation,
    lastthreemonthpayslip: fileValidation,
    resume: fileValidation,
    experienceletter: fileValidation,
    lasttwoyearform16: fileValidation,
    currentemployeename: yup.string().required('Current employeename is required'),
    currentofficeaddress: yup.string().required('Current office address is required'),


    coapplicantPhotoSalaried: fileValidation,
    coapplicantPancardSalaried: fileValidation,
    coapplicantaadharSalaried: fileValidation,
    coapplicant3monthpayslipSalaried: fileValidation,
    coapplicantlast8monthbankstatementSalaried: fileValidation,
    coapplicantlast2yearform16Salaried: fileValidation,
    coapplicantownhouseelectricbillSalaried: fileValidation,
    coapplicantpropertytaxreceiptSalaried: fileValidation,
    currentemployeename: yup.string().required('Field is required'),
    currentofficeaddress: yup.string().required('Field is required'),

    coapplicantPhotoSelf: fileValidation,
    coapplicantPancardSelf: fileValidation,
    coapplicantaadharSelf: fileValidation,
    coapplicantlast3yearitrSelf: fileValidation,
    coapplicantincomestatementSelf: fileValidation,
    coapplicantlast12monthbankstatementSelf: fileValidation,
    coapplicantbusinessregistrationcopySelf: fileValidation,
    coapplicantownhouseelectricbillSelf: fileValidation,
    coapplicantpropertytaxreceiptSelf: fileValidation,
    currentbusinessname: currentempStatusGlobal === "Self Employed" ? yup.string().required('Field is required') : "",
    currentbusinessaddress: currentempStatusGlobal === "Self Employed" ? yup.string().required('Field is required') : "",

    coapplicantphotoPensioner: fileValidation,
    coapplicantpancardPensioner: fileValidation,
    coapplicantaadharPensioner: fileValidation,
    coapplicantlast8monthbankstatementPensioner: fileValidation,
    coapplicantpensioncertificatePensioner: fileValidation,
    coapplicantownhouseelectricbillPensioner: fileValidation,
    coapplicantpropertytaxreceiptPensioner: fileValidation,

    coapplicantphotoRetired: fileValidation,
    coapplicantpancardRetired: fileValidation,
    coapplicantaadharRetired: fileValidation,
    coapplicantlast8monthbankstatementRetired: fileValidation,
    coapplicantpensioncertificateRetired: fileValidation,
    coapplicantownhouseelectricbillRetired: fileValidation,
    coapplicantpropertytaxreceiptRetired: fileValidation,

    coapplicantphotoHomemaker: fileValidation,
    coapplicantpancardHomemaker: fileValidation,
    coapplicantaadharHomemaker: fileValidation,
    coapplicantownhouseelectricbillHomemaker: fileValidation,
    coapplicantpropertytaxreceiptHomemaker: fileValidation,

    IELTS: fileValidation,
    PTE: fileValidation,
    TOEFL: fileValidation,
    GRE: fileValidation,
    GMAT: fileValidation,

    fatherPhoto: fileValidationnonmandatory,
    fatherPanCard: fileValidationnonmandatory,
    fatherAadhar:fileValidationnonmandatory,

    motherPhoto: fileValidationnonmandatory,
    motherPanCard : fileValidationnonmandatory,
    motherAadhar : fileValidationnonmandatory

}).required();

function withFormHook(Component) {
    return function FormComponent(props) {
        const { user } = UserAuth();
        const navigate = useNavigate();
        const location = useLocation();
        const [backstepdata, setBackstepdata] = useState();
        const [coapplicantdata, setCoapplicantdata] = useState();
        const [acadamicdata, setAcadamicdata] = useState();
        const [coappData, setCoappData] = useState(location.state ? location.state.coappdata : []);
        const { showSpinner, setShowSpinner } = useContext(SpinnerContext);

        const { register, handleSubmit, setValue, control, reset, formState: { errors } } = useForm({
            resolver: yupResolver(schema)
        });
        let payloadObj;
        const uploadFileAndData = async (label, file, dataObj, i, length) => {
            payloadObj = dataObj;
            payloadObj.uid = user.uid;
            console.log(payloadObj);

            let newName = file[0].name;
            //Get storage reference before uploading document/ We are uploading doc to folder name UUID:            
            const storageRef = ref(storage, user.uid + '/' + file[0].name);

            //upload the document
            await uploadBytes(storageRef, file[0]);
            //Obtain the downloadable URL of uploaded document
            const downloadURL = await getDownloadURL(storageRef);
            //Add form data into firebase
            payloadObj[label] = downloadURL;
            console.log("Data after upload", payloadObj);

            if (i = length - 1) {
                const docRef = doc(db, "applications", user?.currentOpenApplicationId, "documents", `${user.currentOpenApplicationId}_5`);
                // Check if the document exists
                const documentDoc = await getDoc(docRef);
                if (documentDoc.exists()) {
                    // Document exists, update its data
                    await updateDoc(docRef, payloadObj);
                } else {
                    // Document doesn't exist, add it with a randomly generated ID
                    await setDoc(docRef, payloadObj);
                }
                //navigate to next step once data is saved
                return docRef;
            }

        };
        //Get prev Step data:
        const fetchData = async () => {
            //setShowSpinner(true)
            const querySnapshot = await getDocs(collection(db, 'applications', user?.currentOpenApplicationId, 'academic'));
            const docs = [];
            querySnapshot.forEach((doc) => {
                docs.push({ id: doc.id, ...doc.data() });
            });
            setBackstepdata(docs[0]);
        };
        const fetchCoapplicantFields = async () => {
            if (user?.currentOpenApplicationId) {
                try {
                    setShowSpinner(true)
                    const coapplicantRef = doc(db, "applications", user?.currentOpenApplicationId, "coapplicant", `${user.currentOpenApplicationId}_3`);
                    // Retrieve admission data to set in default values if exist
                    const currentData = (await getDoc(coapplicantRef)).data();
                    reset(currentData); // set form default values
                    setCoapplicantdata(currentData);
                    setShowSpinner(false);
                    console.log("coapplicant fields retrieved from step 3 mount", currentData);
                } catch (error) {
                    setShowSpinner(false);
                    console.log("Unable to retrieve current coapplicant fields from firebase", error)
                }
            }
        };
        const fetchAcademicFields = async () => {
            if (user?.currentOpenApplicationId) {
                try {
                    setShowSpinner(true)
                    const academicRef = doc(db, "applications", user?.currentOpenApplicationId, "academic", `${user.currentOpenApplicationId}_4`);
                    // Retrieve admission data to set in default values if exist
                    const currentData = (await getDoc(academicRef)).data();
                    reset(currentData); // set form default values
                    setAcadamicdata(currentData);
                    setShowSpinner(false)
                } catch (error) {
                    setShowSpinner(false)
                    console.log("Unable to retrieve current academic fields", error)
                }
            }
        }
        useEffect(() => {
            fetchAcademicFields();
            fetchData();
            fetchCoapplicantFields();

        }, []);

        const onSubmit = async (data) => {
            
            let dataArr = Object.entries(data);
            console.log("*************");
            console.log(Object.entries(data));
            const newDataObj = new Object();
            for (let i = 0; i < dataArr.length; i++) {
                newDataObj[dataArr[i][0]] = dataArr[i][1];
            }
            setShowSpinner(true)
            for (let i = 0; i < dataArr.length; i++) {
                
                if (dataArr[i][0] !== "currentemployeename" && dataArr[i][0] !=="currentCollectiveMonthlyIncome" && dataArr[i][0] !=="currentHomeAddress" && dataArr[i][0] !=="fatherContactNumber" && dataArr[i][0] !=="fatherEmailAddress" && dataArr[i][0] !=="fathersName" && dataArr[i][0] !=="motherContactNumber" && dataArr[i][0] !=="motherEmailAddress" && dataArr[i][0] !=="mothersName" && dataArr[i][0] !== "currentofficeaddress" && dataArr[i][0] !== "currentbusinessname" && dataArr[i][0] !== "currentbusinessaddress" && dataArr[i][0] !== "coapplicant" && dataArr[i][1].length > 0) {
                    try {
                        
                        const docRef = await uploadFileAndData(dataArr[i][0], dataArr[i][1], newDataObj, i, dataArr.length);
                        //Comment-------------------------------------(label, file, dataObj, i, dataArr.length) 
                        localStorage.removeItem('applicationsId');
                        localStorage.removeItem('currentempstatus');
                        localStorage.removeItem('coapplicantNum');
                        navigate("/");
                    } catch (error) {
                        console.error('Error adding data to Firestore:', error);
                    }
                }
            }

            //Update the status of application as completed and navigate back to dashboard
            const applicationRef = doc(db, "applications", user?.currentOpenApplicationId);
            // Update document with steps completed
            // Get the current data
            const currentData = (await getDoc(applicationRef)).data();
            // Add a new field to the data
            const newData = { ...currentData, completedSteps: 5, status: 'SUBMITTED' };
            // Update the document with the new data
            await updateDoc(applicationRef, newData);
            setShowSpinner(false)
            navigate("/");
        }

        const onBackClick = () => {
            navigate("/coreform/step4", { state: { backstepdata } });
        }

        return (
            <>
                {!showSpinner &&
                    <Component
                        {...props}
                        register={register}
                        setValue={setValue}
                        handleSubmit={handleSubmit(onSubmit)}
                        errors={errors}
                        onBackClick={onBackClick}
                        control={control}
                        coapplicantdata={coapplicantdata}
                        coappData={coappData}
                        acadamicdata={acadamicdata}
                    />
                }
            </>
        );
    };
}


function StepSixMandatoryApplicantDocumentDetailscomponent(props) {
    const { register, handleSubmit, setValue, errors, control, onBackClick, coapplicantdata, coappData, acadamicdata } = props;
    const { user } = UserAuth();
    const navigate = useNavigate();
    const [currentempStatus, setCurrentempStatus] = useState("");
    const [currentempStatusOne, setCurrentempStatusOne] = useState("");
    const [currentempStatusTwo, setCurrentempStatusTwo] = useState("");
    const [acadamicdataTemp, setAcadamicdataTemp] = useState("");

    //console.log( user?.lvProfile?.workExp);

    useEffect(() => {
        //setCurrentempStatus(localStorage.getItem('currentempstatus'));
        if (coapplicantdata !== undefined) {
            if (coapplicantdata?.coapplicant?.length > 1) {
                setCurrentempStatusOne(coapplicantdata?.coapplicant[0]?.currentEmploymentStatus);
                setCurrentempStatusTwo(coapplicantdata?.coapplicant[1]?.currentEmploymentStatus);
            } else {
                setCurrentempStatusOne(coapplicantdata?.coapplicant[0]?.currentEmploymentStatus);
            }
        }
        if (acadamicdata !== undefined) {
            
            setAcadamicdataTemp(acadamicdata);
        }
    }, [coapplicantdata, acadamicdata]);


    return (
        <form onSubmit={handleSubmit}>
            <Container sx={{ padding: "36px" }}>
                <Grid container spacing={2}>
                    <Grid xs={12} md={12} sx={{ maxHeight: "600px" }}>
                        <Paper
                            elevation={3}
                            sx={{
                                padding: "24px"
                            }}
                        >
                            <Typography gutterBottom>
                                <div className="wizard_title">Applicant Mandatory Document Details (All the docs in PDF only)</div>
                                <Box sx={{ width: '100%', marginTop: "30px" }}>
                                    <Stepper activeStep={4} alternativeLabel>
                                        {steps.map((label) => (
                                            <Step key={label}>
                                                <StepLabel>{label}</StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </Box>
                                <Grid container spacing={2} sx={{ margin: "12px 0 12px 0" }}>
                                    <Grid item xs={12} sm={4}>
                                        <Controller
                                            name="photo"
                                            control={control}
                                            defaultValue={[]}
                                            render={({ field: { onChange, value } }) => (
                                                <Dropzone onChange={onChange}
                                                    value={value}
                                                    label="Photo"
                                                    errors={errors}
                                                    name="photo"
                                                    coapplicantdata={coapplicantdata}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Controller
                                            name="pancard"
                                            control={control}
                                            defaultValue={[]}
                                            render={({ field: { onChange, value } }) => (
                                                <Dropzone onChange={onChange}
                                                    value={value}
                                                    label="Pan Card"
                                                    errors={errors}
                                                    name="pancard"
                                                    coapplicantdata={coapplicantdata}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Controller
                                            name="aadhar"
                                            control={control}
                                            defaultValue={[]}
                                            render={({ field: { onChange, value } }) => (
                                                <Dropzone onChange={onChange}
                                                    value={value}
                                                    label="AADHAAR"
                                                    errors={errors}
                                                    name="aadhar"
                                                    coapplicantdata={coapplicantdata}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Controller
                                            name="passport"
                                            control={control}
                                            defaultValue={[]}
                                            render={({ field: { onChange, value } }) => (
                                                <Dropzone onChange={onChange}
                                                    value={value}
                                                    label="Passport"
                                                    errors={errors}
                                                    name="passport"
                                                    coapplicantdata={coapplicantdata}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Controller
                                            name="tenth"
                                            control={control}
                                            defaultValue={[]}
                                            render={({ field: { onChange, value } }) => (
                                                <Dropzone onChange={onChange}
                                                    value={value}
                                                    label="10TH"
                                                    errors={errors}
                                                    name="tenth"
                                                    coapplicantdata={coapplicantdata}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Controller
                                            name="twelveth"
                                            control={control}
                                            defaultValue={[]}
                                            render={({ field: { onChange, value } }) => (
                                                <Dropzone onChange={onChange}
                                                    value={value}
                                                    label="12TH"
                                                    errors={errors}
                                                    name="twelveth"
                                                    coapplicantdata={coapplicantdata}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Controller
                                            name="semestermarksheet"
                                            control={control}
                                            defaultValue={[]}
                                            render={({ field: { onChange, value } }) => (
                                                <Dropzone onChange={onChange}
                                                    value={value}
                                                    label="Semester Marksheets"
                                                    errors={errors}
                                                    name="semestermarksheet"
                                                    coapplicantdata={coapplicantdata}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Controller
                                            name="provisionalcertificate"
                                            control={control}
                                            defaultValue={[]}
                                            render={({ field: { onChange, value } }) => (
                                                <Dropzone onChange={onChange}
                                                    value={value}
                                                    label="Provisional Certificate"
                                                    errors={errors}
                                                    name="provisionalcertificate"
                                                    coapplicantdata={coapplicantdata}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Controller
                                            name="consolidatedmarksheet"
                                            control={control}
                                            defaultValue={[]}
                                            render={({ field: { onChange, value } }) => (
                                                <Dropzone onChange={onChange}
                                                    value={value}
                                                    label="Consolidated marksheet"
                                                    errors={errors}
                                                    name="consolidatedmarksheet"
                                                    coapplicantdata={coapplicantdata}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Controller
                                            name="degreecertificate"
                                            control={control}
                                            defaultValue={[]}
                                            render={({ field: { onChange, value } }) => (
                                                <Dropzone onChange={onChange}
                                                    value={value}
                                                    label="Degree Certificate"
                                                    errors={errors}
                                                    name="degreecertificate"
                                                    coapplicantdata={coapplicantdata}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Controller
                                            name="admitLetter"
                                            control={control}
                                            defaultValue={[]}
                                            render={({ field: { onChange, value } }) => (
                                                <Dropzone onChange={onChange}
                                                    value={value}
                                                    label="Admit Letter"
                                                    errors={errors}
                                                    name="admitLetter"
                                                    coapplicantdata={coapplicantdata}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Controller
                                            name="i20"
                                            control={control}
                                            defaultValue={[]}
                                            render={({ field: { onChange, value } }) => (
                                                <Dropzone onChange={onChange}
                                                    value={value}
                                                    label="I20"
                                                    errors={errors}
                                                    name="i20"
                                                    coapplicantdata={coapplicantdata}
                                                />
                                            )}
                                        />
                                    </Grid>

                                </Grid>
                                {user?.lvProfile?.workExp != "0" ?
                                    <>
                                        <div className="wizard_title">Work Details (If Student is Working)</div>
                                        <Grid container spacing={2} sx={{ margin: "12px 0 12px 0" }}>
                                            <Grid item xs={12} sm={4}>
                                                <Controller
                                                    name="appointmentletter"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="Appointment Letter"
                                                            errors={errors}
                                                            name="appointmentletter"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4} className="isCustomField">
                                                <Controller
                                                    name="eightmonthbankstatement"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="8 Month Bank Statement of Salary Account"
                                                            errors={errors}
                                                            name="eightmonthbankstatement"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                                <span style={{ fontSize: "10px" }}>(with clear <b>MICR</b> and <b>IFSC</b> code)</span>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Controller
                                                    name="lastthreemonthpayslip"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="Last 3 months payslips"
                                                            errors={errors}
                                                            name="lastthreemonthpayslip"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Controller
                                                    name="lasttwoyearform16"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="Last 2 years Form 16"
                                                            errors={errors}
                                                            name="lasttwoyearform16"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Controller
                                                    name="resume"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="Current CV/Resume"
                                                            errors={errors}
                                                            name="resume"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Controller
                                                    name="experienceletter"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="Experience Letter / Service Letter"
                                                            errors={errors}
                                                            name="experienceletter"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <label>&nbsp;</label>
                                                <TextField
                                                    name="currentemployeename"
                                                    title="Current Employer Name"
                                                    register={register}
                                                    errors={errors}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <label>&nbsp;</label>
                                                <TextField
                                                    name="currentofficeaddress"
                                                    title="Current Office Address"
                                                    register={register}
                                                    errors={errors}
                                                />
                                            </Grid>

                                        </Grid>
                                    </> : ""
                                }
                                {currentempStatusOne === "Employed" || currentempStatusTwo === "Employed" ?
                                    <><div className="wizard_title">Co-Applicant (Salaried)(All the docs in PDF only)</div>
                                        <Grid container spacing={2} sx={{ margin: "12px 0 12px 0" }}>
                                            <Grid item xs={12} sm={4}>
                                                <Controller
                                                    name="coapplicantPhotoSalaried"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="Photo"
                                                            errors={errors}
                                                            name="coapplicantPhotoSalaried"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Controller
                                                    name="coapplicantPancardSalaried"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="Pan Card"
                                                            errors={errors}
                                                            name="coapplicantPancardSalaried"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Controller
                                                    name="coapplicantaadharSalaried"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="AADHAAR"
                                                            errors={errors}
                                                            name="coapplicantaadharSalaried"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Controller
                                                    name="coapplicant3monthpayslipSalaried"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="Last 3 months payslips"
                                                            errors={errors}
                                                            name="coapplicant3monthpayslipSalaried"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4} className="isCustomField">
                                                <Controller
                                                    name="coapplicantlast8monthbankstatementSalaried"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="8 Month Bank Statement of Salary Account"
                                                            errors={errors}
                                                            name="coapplicantlast8monthbankstatementSalaried"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                                <span style={{ fontSize: "10px" }}>(with clear MICR and IFSC code)</span>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Controller
                                                    name="coapplicantlast2yearform16Salaried"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="Last 2 years Form 16"
                                                            errors={errors}
                                                            name="coapplicantlast2yearform16Salaried"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4} className="isCustomField">
                                                <Controller
                                                    name="coapplicantownhouseelectricbillSalaried"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="Own House Electricity Bill"
                                                            errors={errors}
                                                            name="coapplicantownhouseelectricbillSalaried"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                                <span style={{ fontSize: "10px" }}>(For Field Investigation purpose only)</span>
                                            </Grid>
                                            <Grid item xs={12} sm={4} className="isCustomField">
                                                <Controller
                                                    name="coapplicantpropertytaxreceiptSalaried"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="Property Tax Receipt"
                                                            errors={errors}
                                                            name="coapplicantpropertytaxreceiptSalaried"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                                <span style={{ fontSize: "10px" }}>(For Field Investigation purpose only)</span>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                &nbsp;
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <label>&nbsp;</label>
                                                <TextField
                                                    name="currentemployeename"
                                                    title="Current Employer Name"
                                                    register={register}
                                                    errors={errors}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <label>&nbsp;</label>
                                                <TextField
                                                    name="currentofficeaddress"
                                                    title="Current Office Address"
                                                    register={register}
                                                    errors={errors}
                                                />
                                            </Grid>
                                        </Grid></> : ""
                                }
                                {currentempStatusOne === "Self Employed" || currentempStatusTwo === "Self Employed" ?
                                    <><div className="wizard_title">Co-Applicant (Self Employed)(All the docs in PDF only)</div>
                                        <Grid container spacing={2} sx={{ margin: "12px 0 12px 0" }}>
                                            <Grid item xs={12} sm={4}>
                                                <Controller
                                                    name="coapplicantPhotoSelf"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="Photo"
                                                            errors={errors}
                                                            name="coapplicantPhotoSelf"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Controller
                                                    name="coapplicantPancardSelf"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="PAN CARD"
                                                            errors={errors}
                                                            name="coapplicantPancardSelf"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Controller
                                                    name="coapplicantaadharSelf"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="AADHAAR"
                                                            errors={errors}
                                                            name="coapplicantaadharSelf"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4} className="irt">
                                                <Controller
                                                    name="coapplicantlast3yearitrSelf"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label={"Last 3 years complete set of ITR"}
                                                            errors={errors}
                                                            name="coapplicantlast3yearitrSelf"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Controller
                                                    name="coapplicantincomestatementSelf"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="Statement of Income, P&L, Balance Sheet along with all the annexures duly attested by CA"
                                                            errors={errors}
                                                            name="coapplicantincomestatementSelf"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Controller
                                                    name="coapplicantlast12monthbankstatementSelf"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="Last 12 Month Bank Statement of Current and Savings Account with clear MICR and IFSC code"
                                                            errors={errors}
                                                            name="coapplicantlast12monthbankstatementSelf"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Controller
                                                    name="coapplicantbusinessregistrationcopySelf"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="Business Registration Copy"
                                                            errors={errors}
                                                            name="coapplicantbusinessregistrationcopySelf"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4} className="isCustomField">
                                                <Controller
                                                    name="coapplicantownhouseelectricbillSelf"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="Own House Electricity Bill"
                                                            errors={errors}
                                                            name="coapplicantownhouseelectricbillSelf"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                                <span style={{ fontSize: "10px" }}>(For Field Investigation purpose only)</span>
                                            </Grid>
                                            <Grid item xs={12} sm={4} className="isCustomField">
                                                <Controller
                                                    name="coapplicantpropertytaxreceiptSelf"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="Property Tax Receipt"
                                                            errors={errors}
                                                            name="coapplicantpropertytaxreceiptSelf"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                                <span style={{ fontSize: "10px" }}>(For Field Investigation purpose only)</span>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <label>&nbsp;</label>
                                                <TextField
                                                    name="currentbusinessname"
                                                    title="Current Business Name"
                                                    register={register}
                                                    errors={errors}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <label>&nbsp;</label>
                                                <TextField
                                                    name="currentbusinessaddress"
                                                    title="Current Business  Address"
                                                    register={register}
                                                    errors={errors}
                                                />
                                            </Grid>


                                        </Grid></> : ""
                                }
                                {currentempStatusOne === "Pensioner" || currentempStatusTwo === "Pensioner" ?
                                    <>
                                        <div className="wizard_title">Co-Applicant (Pensioner)(All the docs in PDF only)</div>
                                        <Grid container spacing={2} sx={{ margin: "12px 0 12px 0" }}>
                                            <Grid item xs={12} sm={4}>
                                                <Controller
                                                    name="coapplicantphotoPensioner"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="Photo"
                                                            errors={errors}
                                                            name="coapplicantphotoPensioner"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Controller
                                                    name="coapplicantpancardPensioner"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="Pan Card"
                                                            errors={errors}
                                                            name="coapplicantpancardPensioner"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Controller
                                                    name="coapplicantaadharPensioner"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="AADHAR"
                                                            errors={errors}
                                                            name="coapplicantaadharPensioner"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4} className="isCustomField">
                                                <Controller
                                                    name="coapplicantlast8monthbankstatementPensioner"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="8 Month Bank Statement of Salary Account"
                                                            errors={errors}
                                                            name="coapplicantlast8monthbankstatementPensioner"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                                <span style={{ fontSize: "10px" }}>(with clear MICR and IFSC code)</span>

                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Controller
                                                    name="coapplicantpensioncertificatePensioner"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="Pension Certificate"
                                                            errors={errors}
                                                            name="coapplicantpensioncertificatePensioner"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4} className="isCustomField">
                                                <Controller
                                                    name="coapplicantownhouseelectricbillPensioner"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="Own House Electricity Bill"
                                                            errors={errors}
                                                            name="coapplicantownhouseelectricbillPensioner"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                                <span style={{ fontSize: "10px" }}>(For Field Investigation purpose only)</span>

                                            </Grid>
                                            <Grid item xs={12} sm={4} className="isCustomField">
                                                <Controller
                                                    name="coapplicantpropertytaxreceiptPensioner"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="Property Tax Receipt"
                                                            errors={errors}
                                                            name="coapplicantpropertytaxreceiptPensioner"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                                <span style={{ fontSize: "10px" }}>(For Field Investigation purpose only)</span>
                                            </Grid>

                                        </Grid>
                                    </> : ""
                                }
                                {currentempStatusOne === "Retired" || currentempStatusTwo === "Retired" ?
                                    <>
                                        <div className="wizard_title">Co-Applicant (Retired)(All the docs in PDF only)</div>
                                        <Grid container spacing={2} sx={{ margin: "12px 0 12px 0" }}>
                                            <Grid item xs={12} sm={4}>
                                                <Controller
                                                    name="coapplicantphotoRetired"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="Photo"
                                                            errors={errors}
                                                            name="coapplicantphotoRetired"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Controller
                                                    name="coapplicantpancardRetired"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="Pan Card"
                                                            errors={errors}
                                                            name="coapplicantpancardRetired"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Controller
                                                    name="coapplicantaadharRetired"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="AADHAR"
                                                            errors={errors}
                                                            name="coapplicantaadharRetired"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4} className="isCustomField">
                                                <Controller
                                                    name="coapplicantlast8monthbankstatementRetired"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="8 Month Bank Statement of Salary Account"
                                                            errors={errors}
                                                            name="coapplicantlast8monthbankstatementRetired"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                                <span style={{ fontSize: "10px" }}>(with clear MICR and IFSC code)</span>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Controller
                                                    name="coapplicantpensioncertificateRetired"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="Pension Certificate"
                                                            errors={errors}
                                                            name="coapplicantpensioncertificateRetired"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4} className="isCustomField">
                                                <Controller
                                                    name="coapplicantownhouseelectricbillRetired"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="Own House Electricity Bill"
                                                            errors={errors}
                                                            name="coapplicantownhouseelectricbillRetired"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                                <span style={{ fontSize: "10px" }}>(For Field Investigation purpose only)</span>
                                            </Grid>
                                            <Grid item xs={12} sm={4} className="isCustomField">
                                                <Controller
                                                    name="coapplicantpropertytaxreceiptRetired"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="Property Tax Receipt"
                                                            errors={errors}
                                                            name="coapplicantpropertytaxreceiptRetired"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                                <span style={{ fontSize: "10px" }}>(For Field Investigation purpose only)</span>
                                            </Grid>

                                        </Grid>
                                    </> : ""
                                }
                                {currentempStatusOne === "Homemaker" || currentempStatusTwo === "Homemaker" ?
                                    <>
                                        <div className="wizard_title">Co-Applicant (Homemaker)(All the docs in PDF only)</div>
                                        <Grid container spacing={2} sx={{ margin: "12px 0 12px 0" }}>
                                            <Grid item xs={12} sm={4}>
                                                <Controller
                                                    name="coapplicantphotoHomemaker"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="Photo"
                                                            errors={errors}
                                                            name="coapplicantphotoHomemaker"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Controller
                                                    name="coapplicantpancardHomemaker"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="PAN CARD"
                                                            errors={errors}
                                                            name="coapplicantpancardHomemaker"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Controller
                                                    name="coapplicantaadharHomemaker"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="AADHAAR"
                                                            errors={errors}
                                                            name="coapplicantaadharHomemaker"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={4} className="isCustomField">
                                                <Controller
                                                    name="coapplicantownhouseelectricbillHomemaker"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="Own House Electricity Bill"
                                                            errors={errors}
                                                            name="coapplicantownhouseelectricbillHomemaker"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                                <span style={{ fontSize: "10px" }}>(For Field Investigation purpose only)</span>
                                            </Grid>
                                            <Grid item xs={12} sm={4} className="isCustomField">
                                                <Controller
                                                    name="coapplicantpropertytaxreceiptHomemaker"
                                                    control={control}
                                                    defaultValue={[]}
                                                    render={({ field: { onChange, value } }) => (
                                                        <Dropzone onChange={onChange}
                                                            value={value}
                                                            label="Property Tax Receipt"
                                                            errors={errors}
                                                            name="coapplicantpropertytaxreceiptHomemaker"
                                                            coapplicantdata={coapplicantdata}
                                                        />
                                                    )}
                                                />
                                                <span style={{ fontSize: "10px" }}>(For Field Investigation purpose only)</span>
                                            </Grid>


                                        </Grid>
                                    </> : ""
                                }
                                {
                                    acadamicdata !== undefined ?
                                        <div className="wizard_title">Upload Test certificates</div> : ""
                                }
                                <Grid container spacing={2} sx={{ margin: "12px 0 12px 0" }}>
                                    {
                                        acadamicdata !== undefined ?
                                            acadamicdata.testAndScore.map((test, index) => {
                                                return (
                                                    <Grid item xs={12} sm={4} key={index}>
                                                        <Controller
                                                            name={test.testTaken}
                                                            control={control}
                                                            defaultValue={[]}
                                                            render={({ field: { onChange, value } }) => (
                                                                <Dropzone onChange={onChange}
                                                                    value={value}
                                                                    label={test.testTaken}
                                                                    errors={errors}
                                                                    name={test.testTaken}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                )
                                            })
                                            : ""
                                    }
                                </Grid>


                                <div className="wizard_title">Parent documents</div>

                                <Grid container spacing={2} sx={{ margin: "12px 0 12px 0" }}>
                                    <Grid item xs={12} sm={4}>
                                        <Controller
                                            name="fatherPhoto"
                                            control={control}
                                            defaultValue={[]}
                                            render={({ field: { onChange, value } }) => (
                                                <Dropzone onChange={onChange}
                                                    value={value}
                                                    label="Father Photo"
                                                    errors={errors}
                                                    name="fatherPhoto"
                                                    coapplicantdata={coapplicantdata}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Controller
                                            name="fatherPanCard"
                                            control={control}
                                            defaultValue={[]}
                                            render={({ field: { onChange, value } }) => (
                                                <Dropzone onChange={onChange}
                                                    value={value}
                                                    label="Father PAN CARD"
                                                    errors={errors}
                                                    name="fatherPanCard"
                                                    coapplicantdata={coapplicantdata}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Controller
                                            name="fatherAadhar"
                                            control={control}
                                            defaultValue={[]}
                                            render={({ field: { onChange, value } }) => (
                                                <Dropzone onChange={onChange}
                                                    value={value}
                                                    label="Father AADHAAR"
                                                    errors={errors}
                                                    name="fatherAadhar"
                                                    coapplicantdata={coapplicantdata}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Controller
                                            name="motherPhoto"
                                            control={control}
                                            defaultValue={[]}
                                            render={({ field: { onChange, value } }) => (
                                                <Dropzone onChange={onChange}
                                                    value={value}
                                                    label="Mother Photo"
                                                    errors={errors}
                                                    name="motherPhoto"
                                                    coapplicantdata={coapplicantdata}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Controller
                                            name="motherPanCard"
                                            control={control}
                                            defaultValue={[]}
                                            render={({ field: { onChange, value } }) => (
                                                <Dropzone onChange={onChange}
                                                    value={value}
                                                    label="Mother PAN CARD"
                                                    errors={errors}
                                                    name="motherPanCard"
                                                    coapplicantdata={coapplicantdata}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Controller
                                            name="motherAadhar"
                                            control={control}
                                            defaultValue={[]}
                                            render={({ field: { onChange, value } }) => (
                                                <Dropzone onChange={onChange}
                                                    value={value}
                                                    label="Mother AADHAAR"
                                                    errors={errors}
                                                    name="motherAadhar"
                                                    coapplicantdata={coapplicantdata}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>





                                <Divider />
                                <Button
                                    variant="contained"
                                    type="button"
                                    color="primary"
                                    onClick={onBackClick}
                                    sx={{ margin: "12px 0 12px 0" }}
                                >
                                    Back
                                </Button>
                                {" "}
                                <Button
                                    variant="contained"
                                    type="submit"
                                    sx={{ margin: "12px 0 12px 0" }}
                                >
                                    Next
                                </Button>
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>

        </form>
    )
}



const StepSixMandatoryApplicantDocumentDetails = withFormHook(StepSixMandatoryApplicantDocumentDetailscomponent);

export default StepSixMandatoryApplicantDocumentDetails;