import { styled } from '@mui/material/styles';
import { Stepper, Step, StepLabel, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const StyledStepper = styled(Stepper)(({ theme }) => ({
  backgroundColor: 'transparent',
  padding: theme.spacing(2, 0),
}));

const useStyles = makeStyles((theme) => ({
  stepLabel: {
    '& .MuiStepLabel-label': {
      color: '#FFFFFF', // or any other color value
      fontWeight: "bold"
    },
    '& .MuiStepLabel-label.Mui-active': {
      color: '#FFFFFF', // or any other color value
      fontWeight: "bold"
    },
    '& .MuiStepLabel-label.Mui-completed': {
      color: '#FFFFFF', // or any other color value
      fontWeight: "bold"
    },
    '& .root-MuiStepIcon-root.Mui-active': {
      color: '#f64f64'
    }
  },
}));

const StyledStepLabel = styled(StepLabel)(({ theme, completed }) => ({
  color: completed ? theme.palette.primary.main : theme.palette.text.secondary,
}));

function ApplicationProgress({ steps, activeStep }) {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h6" align="center" sx={{color: "#FFFFFF", fontWeight: "bold"}}>
        Your current application status
      </Typography>
      <br></br>
      <StyledStepper activeStep={activeStep} className={classes.stepLabel} alternativeLabel>
        {steps.map((step) => (
          <Step key={step.value}>
            <StyledStepLabel completed={step.value < activeStep}>{step.value}</StyledStepLabel>
          </Step>
        ))}
      </StyledStepper>
    </>
  );
}

export default ApplicationProgress;