import { createContext, useContext, useState } from 'react';
import { Snackbar, Alert } from '@mui/material';

export const SnackbarContext = createContext();

export const useSnackbar = () => {
  return useContext(SnackbarContext)
}

export const SnackbarProvider = ({ children }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarPosition, setSnackbarPosition ] = useState({ vertical: 'bottom', horizontal: 'left' });
  const [snackbarType, setSnackbarType] = useState('info')

  const showSnackbar = (message, type, position) => {
    setSnackbarOpen(true)
    setSnackbarMessage(message)
    setSnackbarPosition(position)
    setSnackbarType(type)
  }

  const hideSnackBar = () => {
    setSnackbarOpen(false)
  }

  return (
    <SnackbarContext.Provider value={{ showSnackbar, hideSnackBar }}>
      {children}
      <Snackbar
        open={snackbarOpen}
        anchorOrigin={snackbarPosition}
        onClose={hideSnackBar}
        autoHideDuration={3000}>
      <Alert elevation={6} variant="filled" severity={snackbarType}>{snackbarMessage}</Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
export default SnackbarProvider
