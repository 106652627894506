import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Container, Grid, Chip, Typography, Button, Paper, Alert, AlertTitle } from '@mui/material';
import { ChevronLeft, CloudUpload } from '@mui/icons-material';
import { SpinnerContext } from '../../../../context/SpinnerContext'
import DocUploadDialog from "./docUploadDialog"
import { db, getDocs, collection } from "../../../../firebase";
import { UserAuth } from '../../../../context/AuthContext'

const DocGridList = (props) => {
  const mandatoryBackground =  "rgba(255, 86, 48, 0.16)"
  const mandatoryColour = "rgb(183, 29, 24)"
  const optionalBackground =  "rgba(255, 171, 0, 0.16)"
  const optionalColour = "rgb(183, 110, 0)"
  const successBackground = "rgba(34, 197, 94, 0.16)"
  const successColour = "rgb(17, 141, 87)"
  const location = useLocation();
  const { user } = UserAuth();
  const [open, setOpen] = useState(false);
  const [uploadFor, setUploadFor] = useState()
  const [allDocs, setAllDocs] = useState([])
  const { setShowSpinner } = useContext(SpinnerContext);
  const navigate = useNavigate()
  const handleOpen = (item) => {
    setOpen(true)
    setUploadFor(item)
  }

  const handleClose = () => {
    setOpen(false)      
  };

  const disableDoc = {
    opacity: 0.5,
    pointerEvents: 'none'
  };

  const showDoc = {
    opacity: 1,
    pointerEvents: 'auto'
  }

  const fetchData = async () => {
    const currentOpenApplicationId = user?.currentOpenApplicationId;
    const collectionPath = `applications/${currentOpenApplicationId}/lvDocuments`;
    try {
      setShowSpinner(true)
      const collectionRef = collection(db, collectionPath);
      const querySnapshot = await getDocs(collectionRef);
      const dataArray = querySnapshot.docs.map((docSnapshot) => docSnapshot.data());
      const docInScope = dataArray?.filter(metaData => metaData.group === location.state.path)
      setAllDocs(docInScope)
    } catch (error) {
      console.error('Error fetching documents:', error);
    } finally {
      setShowSpinner(false)
    }
  };


  useEffect(() => {
    fetchData() // run whenever allDocs (fb's documents) changes
  }, [])

  return (
    <React.Fragment>
      <DocUploadDialog open={open} close={handleClose} uploadFor={uploadFor} fetchDocData={fetchData} />
      <Button variant="plain" startIcon={<ChevronLeft />} onClick={ () => navigate('/documents') }>
        Back
      </Button>
      <Container sx={{ padding: "25px 0" }}>
        <Typography variant="h5">
          Documentation list for action
        </Typography>
        { allDocs?.map((item, index) => (
          <React.Fragment>
            <Paper key={index} elevation={3} sx={{ width: '100%', maxWidth: '100%' }} style={ item.showDoc ? showDoc : disableDoc }>
              <Alert
                severity={item.status === "success" ? "success" : item.type === "mandatory" ? "warning" : item.type === "optional" ? "info" : "info"}
                action={
                  <Button color="inherit" endIcon={item.showDoc ? <CloudUpload /> : '' } onClick={() => handleOpen(item)}>
                    { item.showDoc ? 'Upload' : 'Not applicable' }
                  </Button>
                }
              >
                <AlertTitle><b>{`${item.value}`}</b></AlertTitle>
                {
                  item.status === "success" ? "Document successfully uploaded. Thank you!" :
                  item.type === "mandatory" ? "Please upload the pending document to complete the application" : 
                  item.type === "optional"  ? "Upload optional documents to enhance your application or profile." :
                  ""
                }
            </Alert>
            <Grid container spacing={2} sx={{ margin: "0 0 25px 0", padding: "16px"}}>
              <Grid item xs={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Chip 
                      label={item.type} 
                      sx={{
                            backgroundColor: item.type === 'mandatory' ? mandatoryBackground : optionalBackground, 
                            color: item.type === 'mandatory' ? mandatoryColour : optionalColour
                          }} 
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Chip 
                      label={item.status === "success" ? "Upload successful" : "Pending Upload" } 
                      sx={{
                        backgroundColor: item.status === 'success' ? successBackground : optionalBackground, 
                        color: item.status === 'success' ? successColour : optionalColour
                      }}  
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    { typeof item.uploadedDate === "object" ? 'Uploaded on: ' + item?.uploadedDate?.toDate().toLocaleDateString() : '' }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
           
          </React.Fragment>
        ))}
      </Container>
    </React.Fragment>
  );

}
export default DocGridList;