
import { useContext, createContext, useEffect, useState } from 'react';
import {
  signInWithPopup,
  signInWithRedirect,
  signOut,
  onAuthStateChanged,
  getRedirectResult
} from 'firebase/auth';
import { auth, provider } from '../firebase';
import { useDispatch } from 'react-redux'
import { addUser } from "../rootSlice";
import Loader from '../components/atom/Loader'
import { db, collection, query, getDocs, doc , setDoc, getDoc} from "../firebase"

const AuthContext = createContext();

const ADMIN_DOMAIN_REGX = /loanvidya\.co\.in/;
//const ADMIN_DOMAIN_REGX = /gmail\.com/;

export const AuthProvider = ({ children }) => {
  console.log("In auth provider");
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  // redux dispatch of saving the user details
  // const dispatch = useDispatch();

  const googleSignIn = async () => {
    try {
      await signInWithPopup(auth, provider)
      // await signInWithRedirect(auth, provider)
    } catch (error) {
      console.error(error);
    }
  };

  const updateUser = (fieldsToAdd) => {
    console.log("fields to add", fieldsToAdd)
    setUser({...user, ...fieldsToAdd})
    console.log("new user", user)
  }

  const logOut = () => {
      signOut(auth)
  }

  const getData = async (user) => {
    let data;
    if(user != null && !user?.email.match(ADMIN_DOMAIN_REGX)) {
      const userRef = doc(db, "profiles", user.uid);
      const q = query(collection(db, "profiles", user.uid, "basicInfo"));
      const querySnapshot = await getDocs(q);
      console.log("querySnapshot", querySnapshot)
      const lvProfile = querySnapshot.docs.map((doc) => doc.data());
      // Get the current data
      const currentData = (await getDoc(userRef)).data();
      data = {lvProfile : lvProfile?.[0], userData : currentData}
    }
    return data;
  };

  const addUidToProfile = async (user) => {
    if(user != null) {
      console.log("Setting up user profile with UID")
      try {
        const userRef = doc(db, "profiles", user.uid);
        await setDoc(userRef, {userId : user?.uid});
      }catch(error) {
        console.error('Error adding data to Firestore:', error);
      }
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      console.log("user after authentication", user)
      setLoading(true);
      getData(user).then(async data => {
        console.log("auth set up get data", data)
        if(data) {
          if(data.lvProfile != null) {
            user = {...user, lvProfile : data.lvProfile, ...data.userData}
          } else {
            await addUidToProfile(user);
          }
        }
        if(user) {
          user = {...user, role : user?.email.match(ADMIN_DOMAIN_REGX) ? "ROLE_ROOT_ADMIN" : "ROLE_STUDENT" } 
        }
        setUser(user);
      }
      ).finally(() => {
        setLoading(false);
      }
      )
      // dispatch(addUser(currentUser));
    });
    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ googleSignIn, logOut, user, updateUser}}>
      {loading && <Loader />}
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(AuthContext);
};