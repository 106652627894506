import React, { useState, useEffect, useContext } from "react";
import { border, Container } from "@mui/system";
import { useForm, useWatch } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Button,
  Paper,
  Alert
} from "@mui/material";
import { UserAuth } from "../../../../context/AuthContext";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from "../../../atom/InputController";
import CountrySelectController from "../../../atom/CountryController";
import DatePickerController from "../../../atom/DatePickerController";
import AutocompleteSelectController from "../../../atom/AutocompleteController";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
//options values
import { addStatusData, programLevel, programCategory, steps } from "./optionsdata";
import { db, collection, addDoc, getDocs, doc, setDoc, updateDoc, getDoc } from "../../../../firebase";
import { SpinnerContext } from "../../../../context/SpinnerContext";
import { FormSkeleton } from "../../../atom/Skeleton";

const schema = yup.object({
  fullCourceFeeINR: yup.string().required('Full Course Fee in INR is required'),
  currentLocPermanentAddress: yup.string().required('Current location is required'),
}).required();

function withFormHook(Component) {
  return function FormComponent(props) {
    const { user } = UserAuth();
    const navigate = useNavigate();
    const [defaultValues, setDefaultValues] = useState({});
    const [backstepdata, setBackstepdata] = useState();
    const { showSpinner, setShowSpinner } = useContext(SpinnerContext);
    const [nextStep, setNextStep] = useState([]);
    const { register, handleSubmit, setValue, control, watch, formState: { errors }, reset } = useForm({
      mode: 'onBlur',
      resolver: yupResolver(schema),
      defaultValues: defaultValues
    });

    // Define a function that returns the latest watch data
    // eslint-disable-next-line
    const getWatchData = () => useWatch({ control });

    const fetchDebtFields = async () => {
      if (user?.currentOpenApplicationId) {
        try {
          setShowSpinner(true)
          const debtRef = doc(db, "applications", user?.currentOpenApplicationId, "debt", `${user.currentOpenApplicationId}_2`);
          // Retrieve admission data to set in default values if exist
          const currentData = (await getDoc(debtRef)).data();
          reset(currentData); // set form default values
          await setDefaultValues(currentData)
          setShowSpinner(false)
          console.log("Debt fields retrieved from step 2 mount", currentData);
        } catch (error) {
          setShowSpinner(false)
          console.log("Unable to retrieve current debt fields from firebase", error)
        }
      }
      if(props.step ==="step2") {
        try {
          setShowSpinner(true)
          const debtRef = doc(db, "applications", props.applicationId, "debt", `${props.applicationId}_2`);
          // Retrieve admission data to set in default values if exist
          const currentData = (await getDoc(debtRef)).data();
          reset(currentData); // set form default values
          await setDefaultValues(currentData)
          setShowSpinner(false)
          console.log("Debt fields retrieved from step 2 mount", currentData);
        } catch (error) {
          setShowSpinner(false)
          console.log("Unable to retrieve current debt fields from firebase", error)
        }
      }
    }

    useEffect(() => {
      fetchDebtFields();
    }, []);

    const onSubmit = async (data) => {
      let payloadObj = data;
      let totalAmount = parseInt(payloadObj.fullCourceFeeINR) + parseInt(payloadObj.anyLivingCostINR);
      payloadObj.uid = user.uid;
      payloadObj.totalLoanRequiredINR = totalAmount;
      console.log(payloadObj);
      try {
        setShowSpinner(true);
        let applicationid = props.step ==="step2" ? props.applicationId : user?.currentOpenApplicationId;
        const applicationRef = doc(db, "applications", applicationid);
        const debtRef = doc(db, "applications", applicationid, "debt", `${applicationid}_2`);
        // Check if the document exists
        const debtDoc = await getDoc(debtRef);
        if (debtDoc.exists()) {
          // Document exists, update its data
          await updateDoc(debtRef, payloadObj);
        } else {
          // Document doesn't exist, add it with a randomly generated ID
          await setDoc(debtRef, payloadObj);

          // Update document with steps completed
          // Get the current data
          const currentData = (await getDoc(applicationRef)).data();
          // Add a new field to the data
          const newData = { ...currentData, completedSteps: 2 };
          // Update the document with the new data
          await updateDoc(applicationRef, newData);
        }
        setShowSpinner(false)
        //navigate to next step once data is saved
        if(props.step ===undefined){
          navigate("/coreform/step3", { state: { nextStep } });
        }else{
          props.handleClose();
        } 
        
      } catch (error) {
        console.error('Error adding data to Firestore:', error);
      }
      localStorage.setItem('coapplicantNum', 1);
    }
    const onBackClick = () => {
      navigate("/coreform", { state: { backstepdata } });
    }

    return (
      <>
        {!showSpinner &&
          <Component
            {...props}
            register={register}
            setValue={setValue}
            handleSubmit={handleSubmit(onSubmit)}
            errors={errors}
            onBackClick={onBackClick}
            control={control}
            watch={watch}
            defaultValues={defaultValues}
            getWatchData={getWatchData}
          />
        }
      </>
    );
  };
}


function StepTwoDebtRequirementscomponent(props) {
  const { register, handleSubmit, errors, onBackClick, watch, defaultValues, getWatchData, step } = props;
  const { showSpinner } = useContext(SpinnerContext);
   // Call the function to get the latest watch data - this is not working at moment, check why it is not
  const watchData = getWatchData();

  const [fullCourceFeeINR, setfullCourceFeeINR] = useState(parseInt(defaultValues?.fullCourceFeeINR));
  const [anyLivingCostINR, setAnyLivingCostINR] = useState(parseInt(defaultValues?.anyLivingCostINR));
  const [totalLoanRequiredINR, setTotalLoanRequiredINR] = useState(defaultValues?.totalLoanRequiredINR);
  

  const onChange = (event) => {
    if (event.target.name === "fullCourceFeeINR") {
      setfullCourceFeeINR(event.target.value);
      setTotalLoanRequiredINR(parseInt(event.target.value) + parseInt(anyLivingCostINR));
    }
    if (event.target.name === "anyLivingCostINR") {
      setAnyLivingCostINR(parseInt(event.target.value));
      setTotalLoanRequiredINR(parseInt(fullCourceFeeINR) + parseInt(event.target.value));
    }
  }

  
  return (
    <form onSubmit={handleSubmit}>
      <Container sx={{ padding: "36px" }}>
        {showSpinner && <FormSkeleton />}
        {!showSpinner &&
          <Grid container spacing={2}>
            <Grid xs={12} md={12} sx={{ maxHeight: "600px" }}>
              <Paper
                elevation={3}
                sx={{
                  padding: "24px"
                }}
              >
                <Typography gutterBottom>
                {
                        step ===undefined ? <div className="wizard_title">Debt Requirements</div> : ""
                    }
                    {
                        step ===undefined ? <Box sx={{ width: '100%', marginTop: "30px" }}>
                        <Stepper activeStep={1} alternativeLabel>
                          {steps.map((label) => (
                            <Step key={label}>
                              <StepLabel>{label}</StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </Box> : ""
                    }                 
                  
                  <Grid container spacing={2} sx={{ margin: "12px 0 12px 0" }}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        name="fullCourceFeeINR"
                        title="Full Course Fee in INR"
                        register={register}
                        errors={errors}
                        type="number"
                        onChange={onChange}

                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        name="anyLivingCostINR"
                        title="Any living cost required in INR"
                        register={register}
                        errors={errors}
                        type="number"
                        onChange={onChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <div className="custom_input">
                        <label>Total Loan Required in INR</label>
                        {/* {parseInt(totalLoanRequiredINR)}
                        - {totalLoanRequiredINR ? totalLoanRequiredINR : parseInt(defaultValues?.totalLoanRequiredINR)}*/}
                        {/*{parseInt(defaultValues?.totalLoanRequiredINR)} --@

                        {totalLoanRequiredINR}
                      ---@*/}
                        {isNaN(totalLoanRequiredINR) ? '' : totalLoanRequiredINR}
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField
                        name="currentLocPermanentAddress"
                        title="Current Location of Permanent Residence"
                        register={register}
                        errors={errors}
                      />
                    </Grid>
                  </Grid>
                  <Divider />
                  {
                      step ===undefined ? <><Button
                      variant="contained"
                      type="button"
                      color="primary"
                      onClick={onBackClick}
                      sx={{ margin: "12px 0 12px 0" }}
                    >
                      Back
                    </Button>
                    {" "}
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{ margin: "12px 0 12px 0" }}
                    >
                      Next
                    </Button></> : <Button
                      variant="contained"
                      type="submit"                      
                      sx={{ margin: "12px 0 12px 0" }}
                    >
                      Save
                    </Button>
                  }
                  
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        }
      </Container>
    </form>
  )
}



const StepTwoDebtRequirements = withFormHook(StepTwoDebtRequirementscomponent);

export default StepTwoDebtRequirements