import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  lvapp : {
    user: {
      name: 'Venkat Thiagarajan',
      age : '33'
    }
  }
}

export const rootSlice = createSlice({
  name: 'lvReducer',
  initialState,
  reducers: {
    addUser: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.lvapp.user = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { addUser } = rootSlice.actions

export default rootSlice.reducer