import React, { useContext } from "react";
import { TextField, Container } from '@mui/material';
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Box from '@mui/system/Box'
import Paper from "@mui/material/Paper";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
import ReferralIllustration from '../../../../Assets/illustrations/referral.svg'
import { UserAuth } from "../../../../context/AuthContext";
import { SpinnerContext } from "../../../../context/SpinnerContext";
import { useSnackbar } from "../../../../context/SnackbarContext";

import { db, collection, addDoc } from "../../../../firebase";


const schema = yup.object({
  refereefirstname: yup.string().required('Referee first name is required'),
  refereelastname: yup.string().required('Referee last name is required'),
  refereeemail: yup.string().email('Please enter a valid email').required('Referee Email is required'),
  refereecontactnumber: yup.string().matches(/^[0-9]+$/, 'Please enter a valid number').required('Referee contact is required').length(10, 'please enter valid phone number')
}).required();

function withFormHook(Component) {
  return function FormComponent(props) {
    const { handleSubmit, control, formState: { errors }, reset } = useForm({
      mode: 'onBlur',
      resolver: yupResolver(schema)
    });


    const addInvitesDoc = async (data, user) => {
      //Add form data into firebase
      console.log("Data", data, user)
      let invitesRef;
      const updData = { ...data, selfEmail: user.email, selfId: user.uid, selfName: user.displayName }
      try {
        invitesRef = collection(db, "invites");
        await addDoc(invitesRef, updData);
        console.log("Basic info saved successfully!");
      } catch (error) {
        console.error("Error saving basic info: ", error);
      }
      return invitesRef;
    };

    const onSubmit = async (data, metaData) => {      
      const { user, setShowSpinner, showSnackbar } = metaData;
      console.log("in submit----->", data, metaData, user);
      try {
        setShowSpinner(true)
        const docRef = await addInvitesDoc(data, user);
        setShowSpinner(false)
        showSnackbar('Referral details sent to admin for further action.', 'success')
        reset()
        console.log('Data added to Firestore with ID:', docRef.id);
      } catch (error) {
        console.error('Error adding data to Firestore:', error);
        setShowSpinner(false)
        reset()
        showSnackbar('Referral details not sent. Kindly try again', 'failure')
      }
    }

    return (
      <Component
        {...props}
        handleSubmit={handleSubmit(onSubmit)}
        errors={errors}
        control={control}
      />
    );
  };
}

function ReferAFriendComponent(props) {
  const { control, errors, handleSubmit } = props;
  const navigate = useNavigate();
  const { user } = UserAuth();
  const { setShowSpinner } = useContext(SpinnerContext);
  const { showSnackbar } = useSnackbar()
  return (
    <React.Fragment>
      <Container>
        <Grid container spacing={{ xs:2, md: 4 }}>
          <Grid xs={12} md={6} display="flex" justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
            <Box
              component="img"
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'contain'
              }}
              alt="Refer a friend"
              src={ReferralIllustration}
            />
          </Grid>
          <Grid xs={12} md={6} display="flex" justifyContent="center" alignItems="center">
            <Paper
              elevation={1}
              sx={{
                height: '100%',
                width: '100%',
                padding: "24px",
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Typography variant="h6" sx={{ textAlign:"center", margin: "24px 0 10px 0", display:"flex", justifyContent:"center" }}>
                Refer a friend
              </Typography>
              <Typography variant="body1"  sx={{ margin: "24px 0 24px 0" }}>
                Spread the word and reap the rewards - invite a friend so that both of you can benefit from this platform's valuable services.
              </Typography>
                <form style={{ width: '100%'}} onSubmit={(e) => {
                  e.preventDefault()
                  handleSubmit({
                    user: user,
                    setShowSpinner: setShowSpinner,
                    navigate: navigate,
                    showSnackbar: showSnackbar
                  })
                }}>
                  <Grid container spacing={{ xs: 2 }} direction="column">
                    <Grid item>
                      <Controller
                        name="refereefirstname"
                        control={control}
                        defaultValue=""
                        rules={{ required: 'Referee first name is required' }}
                        render={({ field }) => (
                          <TextField
                            style={{ width: '100%'}}
                            {...field}
                            label="Referee First Name"
                            title="First Name of your Referee"
                            error={!!errors.refereefirstname}
                            helperText={errors.refereefirstname?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="refereelastname"
                        control={control}
                        defaultValue=""
                        rules={{ required: 'Referree last name is required' }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            style={{ width: '100%'}}
                            label="Referee Last Name"
                            title="Last Name of your Referee"
                            error={!!errors.refereelastname}
                            helperText={errors.refereelastname?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="refereeemail"
                        control={control}
                        defaultValue=""
                        rules={{ required: 'Email is required', pattern: { value: /^\S+@\S+$/i, message: 'Invalid email format' } }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            style={{ width: '100%'}}
                            label="Referee Email"
                            title="Email of your Referee"
                            error={!!errors.refereeemail}
                            helperText={errors.refereeemail?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="refereecontactnumber"
                        control={control}
                        defaultValue=""
                        rules={{ required: 'Referee Contact number is required', pattern: { value: /^[0-9]+$/g, message: 'Invalid contact number' } }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            style={{ width: '100%'}}
                            label="Referee Contact number"
                            title="Contact number of your Referee"
                            error={!!errors.refereecontactnumber}
                            helperText={errors.refereecontactnumber?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        type="submit"
                        sx={{ margin: "12px 0 12px 0" }}>
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

const ReferAFriend = withFormHook(ReferAFriendComponent);

export default ReferAFriend;
