import React, { useState, useContext, useEffect } from "react";
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom'
import { AgGridReact } from 'ag-grid-react';
import { jsPDF } from "jspdf";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import autoTable from 'jspdf-autotable';
import {
  Button,
  Paper,
  Alert
} from "@mui/material";
import { UserAuth } from "../../../../context/AuthContext";

import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';
import { db, collection, query, addDoc, getDocs, getDoc, doc, setDoc } from "../../../../firebase";
import { SpinnerContext } from "../../../../context/SpinnerContext";


const BtnCellRenderer = (props) => {
  const { user } = UserAuth();
  const { showSpinner, setShowSpinner } = useContext(SpinnerContext);
  const docs = new jsPDF();
  const zip = new JSZip();

  const btnClickedHandler = () => {
    const rowData = props.data;
    console.log("rowData->>", rowData);
    //generatePDF(rowData);
    documentZip(rowData);
  }

  // const generatePDF = async (formData) => {
  //   setShowSpinner(true);
  //   const { id, status, completedSteps, userId, dob, aadhar, residingState, gender, residingCountry, pan, existingLoan, referrer, contactNumber, surName, firstName, choiceOfCountry, monthlyIncome, residingCity, email, passport, workExp } = formData;
  //   const coapplicantRef1 = doc(db, "applications", formData.id, "coapplicant", `${formData.id}_3`);
  //   let coapplicant1 = (await getDoc(coapplicantRef1)).data();
  //   let application = [];
  //   let applicationObject = [];
  //   //0 Applicant Profile Data
  //   let userProfile = {
  //     "Basic Details": "",
  //     "Application ID": id,
  //     "Application Status": status,
  //     "Applicant Name": firstName + " " + surName,
  //     "Applicant Email": email,
  //     "Applicant Phone": contactNumber,
  //     "Applicant Pan": pan,
  //     "Applicant Aadhar": aadhar,
  //     "Applicant Passport": passport,
  //     "Father Name": coapplicant1.fathersName,
  //     "Father Phone": coapplicant1.fatherContactNumber,
  //     "Father Email": coapplicant1.fatherEmailAddress,
  //     "Mother Name": coapplicant1.mothersName,
  //     "Mother Phone": coapplicant1.motherContactNumber,
  //     "Mother Email": coapplicant1.motherEmailAddress,
  //     //"Completed Steps": completedSteps,
  //     //"Date of Birth": dob,  
  //     "Applicant Residing City": residingCity,
  //     "Applicant Residing State": residingState,
  //     //"Gender": gender,
  //     "Applicant Residing Country": residingCountry,
  //     "Applicant Existing Loan": existingLoan,
  //     //"Referrer": referrer,      
  //     //"Choice Of Country": choiceOfCountry,
  //     "Applicant Monthly Income": monthlyIncome,
  //     "Applicant Work Experience": workExp
  //   };
  //   application.push(userProfile);
  //   //2 debt
  //   const debtRef = doc(db, "applications", formData.id, "debt", `${formData.id}_2`);
  //   const debt = (await getDoc(debtRef)).data();
  //   const { currentLocPermanentAddress, anyLivingCostINR, totalLoanRequiredINR, fullCourceFeeINR } = debt;
  //   let newdebtObj = {
  //     "Debt Requirements": "",
  //     "Total Course Fee": fullCourceFeeINR,
  //     "Total living cost": anyLivingCostINR,
  //     "Total Loan Required": totalLoanRequiredINR,
  //     //"Current Location of Permanent Residence": currentLocPermanentAddress,
  //   };
  //   if (debt !== undefined) { application.push(newdebtObj) };

  //   //3 coapplicant
  //   const coapplicantRef = doc(db, "applications", formData.id, "coapplicant", `${formData.id}_3`);
  //   let coapplicant = (await getDoc(coapplicantRef)).data();
  //   let newCoapplicantObj = {
  //     "Co-Applicant Details": ""      
  //   };
  //   let coappdata = {};
  //   if (coapplicant.coapplicant !== undefined && coapplicant.coapplicant.length > 0) {

  //     coapplicant.coapplicant.map((x, index) => {
  //       let coapplicantObj = {
  //         "Number of Co-Applicants": coapplicant.coapplicant.length,
  //         [`Co Applicant ${index === 0 ? '1' : '2'} Name`]: x.coApplicantName,
  //         [`Co Applicant ${index === 0 ? '1' : '2'} Pan`]: x.pan,
  //         [`Co Applicant ${index === 0 ? '1' : '2'} Relationship to main applicant`]: x.relationshipToMainApplicant,
  //       };
  //       coappdata = { ...coappdata, ...coapplicantObj };
  //     });
  //   }
  //   let finalCoappdata = { ...newCoapplicantObj, ...coappdata };
  //   if (coapplicant !== undefined) { application.push(finalCoappdata) };
  //   //4 academic
  //   const academicRef = doc(db, "applications", formData.id, "academic", `${formData.id}_4`);
  //   const academic = (await getDoc(academicRef)).data();
  //   let newacadmicObj = {
  //     "Higher Degree": academic.higherDegree,
  //     "Backlogs": academic.backlogs,
  //     "Expected Time of Compeletion": academic.expectedTimeofCompeletion,
  //     "College Location": academic.collegeLocation,
  //     "Course Completed Status": academic.courceCompletedStatus,
  //     "University College Name": academic.universityCollegeName,
  //     "Course": academic.cource,
  //     "CGPA": academic.cgpa
  //   };
  //   let testscore = {};
  //   if (academic.testAndScore !== undefined && academic.testAndScore.length > 0) {
  //     academic.testAndScore.map((x, index) => {
  //       let testscoreObj = {
  //         [x.testTaken]: x.scorebasedonEachTest                        
  //       };
  //       testscore = { ...testscore, ...testscoreObj };
  //     })
  //   }
  //   let finalacademic = { ...newacadmicObj, ...testscore };
  //   if (academic !== undefined) { application.push(finalacademic) };
  //   //5 documents      
  //   //combine object
  //   let tempapplicationObject = {};
  //   application.map((appobj, index) => {
  //     tempapplicationObject = { ...tempapplicationObject, ...appobj };
  //   });
  //   applicationObject = [tempapplicationObject];

  //   applicationObject.map((item, index) => {
  //     docs.autoTable({
  //       head: [['Application Details', '']],
  //       body: Object.keys(item).map((key) => [key, item[key]])
  //     });
  //   });
  //   docs.save("application.pdf");
  //   //setShowSpinner(false);
  // };
  const documentZip = async (formData) => { 
    console.log("in document zip")   
    ///////////
    const { id, status, completedSteps, userId, dob, aadhar, residingState, gender, residingCountry, pan, existingLoan, referrer, contactNumber, surName, firstName, choiceOfCountry, monthlyIncome, residingCity, email, passport, workExp } = formData;
    //1 admission    
    const admissionRef = doc(db, "applications", formData.id, "admission", `${formData.id}_1`);
    const admission = (await getDoc(admissionRef)).data();

    const coapplicantRef1 = doc(db, "applications", formData.id, "coapplicant", `${formData.id}_3`);
    let coapplicant1 = (await getDoc(coapplicantRef1)).data();
    let application = [];
    let applicationObject = [];
    //0 Applicant Profile Data
    let userProfile = {
      "Basic Details": "",      
      "Completed Steps": completedSteps,
      "Application ID": id,
      "Application Status": status,
      "Applicant Name": firstName + " " + surName,
      "Applicant Email": email,
      "Applicant Phone": contactNumber,
      "Applicant Pan": pan,
      "Applicant Aadhar": aadhar,
      "Applicant Passport": passport,
      "Father Name": coapplicant1.fathersName,
      "Father Phone": coapplicant1.fatherContactNumber,
      "Father Email": coapplicant1.fatherEmailAddress,
      "Mother Name": coapplicant1.mothersName,
      "Mother Phone": coapplicant1.motherContactNumber,
      "Mother Email": coapplicant1.motherEmailAddress,      
      "Date of Birth": dob,  
      "Applicant Residing City": residingCity,
      "Applicant Residing State": residingState,
      "Gender": gender,
      "Applicant Residing Country": residingCountry,
      "Applicant Existing Loan": existingLoan,
      "Choice Of Country": choiceOfCountry,
      "Applicant Monthly Income": monthlyIncome,
      "Applicant Work Experience": workExp,
      //--------------
      
      "Admission Details": "",
      "Admission Status": admission?.admissionStatus,
      "Country": admission?.countryName,
      "Name of University": admission?.nameCollegeUniversity,
      "Program Level": admission?.programLevel,
      "Program Name": admission?.programName,
      "Program Category": admission?.programCategory,
      "Tentative Program Start Date": admission?.programStartDate
    };
    application.push(userProfile);
    console.log("pushed user profile");
    //2 debt
    const debtRef = doc(db, "applications", formData.id, "debt", `${formData.id}_2`);
    const debt = (await getDoc(debtRef)).data();
    const { currentLocPermanentAddress, anyLivingCostINR, totalLoanRequiredINR, fullCourceFeeINR } = debt;
    let newdebtObj = {
      "Debt Requirements": "",      
      "Total Course Fee": fullCourceFeeINR,
      "Total living cost": anyLivingCostINR,
      "Total Loan Required": totalLoanRequiredINR,
      "Current Location of Permanent Residence": currentLocPermanentAddress,
    };
    if (debt !== undefined) { application.push(newdebtObj) };

    //3 coapplicant
    const coapplicantRef = doc(db, "applications", formData.id, "coapplicant", `${formData.id}_3`);
    let coapplicant = (await getDoc(coapplicantRef)).data();
    //console.log("CO-****", coapplicant);
    let newCoapplicantObj = {
      "Co-Applicant Details": "",  
      "Current Collective Monthly Income":coapplicant.currentCollectiveMonthlyIncome,    
    };
    let coappdata = {};
    if (coapplicant.coapplicant !== undefined && coapplicant.coapplicant.length > 0) {

      coapplicant.coapplicant.map((x, index) => {          
        let coapplicantObj = {
          "Number of Co-Applicants": coapplicant.coapplicant.length,
          [`Co Applicant ${index === 0 ? '1' : '2'} Name`]: x.coApplicantName,
          [`Co Applicant ${index === 0 ? '1' : '2'} Pan`]: x.pan,
          [`Co Applicant ${index === 0 ? '1' : '2'} Relationship to main applicant`]: x.relationshipToMainApplicant,
          [`Co Applicant ${index === 0 ? '1' : '2'} Current Employment Status`]: x.currentEmploymentStatus,
          [`Co Applicant ${index === 0 ? '1' : '2'} Current City and State`]: x.currentCityandState,
          [`Co Applicant ${index === 0 ? '1' : '2'} Monthly Income`]: x.monthlyIncome,
          [`Co Applicant ${index === 0 ? '1' : '2'} Can You Provide Proof of Income`]: x.canYouprovideProofofIncome,
          [`Co Applicant ${index === 0 ? '1' : '2'} Property Address`]: x.propertyAddress,
          [`Co Applicant ${index === 0 ? '1' : '2'} Existing Loan`]: x.existingLoan,
          [`Co Applicant ${index === 0 ? '1' : '2'} Total Amount of Emi`]: x.totalAmountofEmi,
          [`Co Applicant ${index === 0 ? '1' : '2'} Is Own Property Ailable`]: x.isOwnproPertyAilable,
        };
        coappdata = { ...coappdata, ...coapplicantObj };
      });
    }
    let finalCoappdata = { ...newCoapplicantObj, ...coappdata };
    if (coapplicant !== undefined) { application.push(finalCoappdata) };
    //4 academic
    const academicRef = doc(db, "applications", formData.id, "academic", `${formData.id}_4`);
    const academic = (await getDoc(academicRef)).data();        
    let newacadmicObj = {
      "Acadamic Details": "",
      "Higher Degree": academic.higherDegree,
      "Backlogs": academic.backlogs,
      "Expected Time of Compeletion": academic.expectedTimeofCompeletion,
      "College Location": academic.collegeLocation,
      "Course Completed Status": academic.courceCompletedStatus,
      "University College Name": academic.universityCollegeName,
      "Course": academic.cource,
      "CGPA": academic.cgpa
    };
    let testscore = {};
    if (academic.testAndScore !== undefined && academic.testAndScore.length > 0) {
      academic.testAndScore.map((x, index) => {
        let testscoreObj = {
          [x.testTaken]: x.scorebasedonEachTest
        };
        testscore = { ...testscore, ...testscoreObj };
      })
    }
    let finalacademic = { ...newacadmicObj, ...testscore };
    if (academic !== undefined) { application.push(finalacademic) };
    //5 documents      
    //combine object
    let tempapplicationObject = {};
    application.map((appobj, index) => {
      tempapplicationObject = { ...tempapplicationObject, ...appobj };
    });
    applicationObject = [tempapplicationObject];
    console.log("pushed application data");
    const headerStyles = [
      {   
      fillColor:'#1e1ff',   
      textColor: '#0c4cc1',
      fontStyle: 'bold',
      fontSize: 14,
      halign: 'left',
      valign: 'middle',
      minCellHeight:10
    },
    {   
      fillColor:'#1e1ff',   
      textColor: '#0c4cc1',
      fontStyle: 'bold',
      fontSize: 14,
      halign: 'left',
      valign: 'middle',
      minCellHeight:10
    },
    {   
      fillColor:'#1e1ff',   
      textColor: '#0c4cc1',
      fontStyle: 'bold',
      fontSize: 14,
      halign: 'left',
      valign: 'middle',
      minCellHeight:10
    },
    {   
      fillColor:'#1e1ff',   
      textColor: '#0c4cc1',
      fontStyle: 'bold',
      fontSize: 14,
      halign: 'left',
      valign: 'middle',
      minCellHeight:10
    },
    {   
      fillColor:'#1e1ff',   
      textColor: '#0c4cc1',
      fontStyle: 'bold',
      fontSize: 14,
      halign: 'left',
      valign: 'middle',
      minCellHeight:10
    }

  ];

    applicationObject.map((item, index) => {          
      docs.autoTable({        
        head: [['Application Details', '']],
        body: Object.keys(item).map((key) => [key, item[key]]),
        // didParseCell: (item) => {
        //   const row = item.row.raw[0];  
        //   if (row === "Basic Details") {            
        //     item.cell.styles = headerStyles[0];
        //   }
        //   else if (row === "Debt Requirements") {            
        //     item.cell.styles = headerStyles[1];
        //   }
        //   else if (row === "Admission Details") {            
        //     item.cell.styles = headerStyles[2];// Apply headerStyles to the first row of headers
        //   }
        //   else if (row === "Co-Applicant Details") {            
        //     item.cell.styles = headerStyles[3];// Apply headerStyles to the first row of headers
        //   }
        //   else if (row === "Acadamic Details") {            
        //     item.cell.styles = headerStyles[4];// Apply headerStyles to the first row of headers
        //   }          
        // }
      });
    });
    //docs.save("application.pdf");  
    ///////////
    console.log("Before pdf download");
    setShowSpinner(true);
    //const { surName, firstName } = formData;
    let studentName = firstName + "_" + surName;
    let docArray = [];
    //5 documents
   
  
    try {
      // Create a query to get all documents in the collection
      const queryPath = query(collection(db, "applications",formData.id, "lvDocuments"));
      await getDocs(queryPath)
      .then((querySnapshots) => {
        // Iterate through the documents that match the query
        querySnapshots.forEach(async (snapshot) => {
          const documentData = snapshot.data();
          console.log(documentData);
          if(documentData.url) {
            const val = { name: documentData.docName, url: documentData.url };
            docArray.push(val)
          }
        })
      })
      console.log("doc array after push", docArray)
      if (docArray.length > 0) {
         
        //download file method
        const downloadFile = async (url) => {
          const response = await fetch(url);
          const blob = await response.blob();
          return blob;
        };
        console.log("document download - before", docArray)

        //Use Promise.all() to download all files in parallel.
        const blobs = await Promise.all(docArray.map((docitem) => downloadFile(docitem.url)));

        console.log("document download - after", blobs)
        //Use the JSZip library to create a zip file and add the downloaded files to it.    
        blobs.forEach((blob, index) => {
          const name = docArray[index].name;
          zip.file("basic_profile.pdf", docs.output());
          zip.file(name, blob);
        });

        //---
        
        const content = await zip.generateAsync({ type: 'blob' });        

        const url = URL.createObjectURL(content);
        saveAs(content, studentName + ".zip");
      }
      setShowSpinner(false);
    }
    catch (error) {
      console.log(error)
      setShowSpinner(false);
    }
  }
  const DeleteKeys = (myObj, array) => {
    for (let index = 0; index < array.length; index++) {
      delete myObj[array[index]];
    }
    return myObj;
  }


  return (
    <>
      {!showSpinner &&
        <Button
          id="zipdownload"
          disabled={props.data.status === "SUBMITTED" ? false : true}
          variant="contained"
          type="button"
          onClick={btnClickedHandler}
          size="small"
          style={{ fontSize: "10px", height: "auto" }}
        >
          Download Form
        </Button>
      }
    </>
  )
}

const fullNameGetter = (params) => {
  return params.data.firstName + ' ' + params.data.surName;
}

const consultantName = (props) => {  
  if(props.data.referrer ==="Others"){
    return props.data.referrerOther
  }else{
    return props.data.referrer
  }
}

const DateFormatter = ({ value }) => {
  if (value) {
    const date = new Date(value);
    const options = { day: '2-digit', month: 'short', year: '2-digit' };
    return date.toLocaleDateString('en-IN', options);
  } else {
    return '';
  }
};

const columnDefs = [
  { headerName: "Created On", field: "createdDate", cellRendererFramework: DateFormatter, filter: true, sortable: true, width: 130 },
  { headerName: "Submitted On", field: "submittedDate", cellRendererFramework: DateFormatter, sort: 'desc', filter: true, sortable: true, width: 175 },
  { headerName: "Application id", field: "id", filter: true, width: 150 },
  { headerName: "Name", valueGetter: fullNameGetter, field: "firstName", filter: true, sortable: true, width: "auto", resizable: true },
  { headerName: "Application Status", field: "status", filter: true, width: 175, sortable: true },
  { headerName: "Consultant Name", valueGetter: consultantName, field: "referrer", filter: true, width: "auto", sortable: true, resizable: true },
  //{ headerName: "Date of Birth", field: "dob", filter: true, width: "auto", sortable: true, resizable: true },
  { headerName: "Choice of Country", field: "choiceOfCountry", filter: true, width: "auto", sortable: true, resizable: true },
  { headerName: "Email", field: "email", filter: true, width: "auto", sortable: true, resizable: true },
  //{ headerName: "Work Experience", field: "workExp", filter: true, width: "auto", sortable: true, resizable: true },
  { headerName: "Actions", field: "actions", width: "auto", resizable: true, cellRenderer: BtnCellRenderer }
];

function AggridTable(props) {
  const navigate = useNavigate();
  const [data, setData] = useState(props.applications ? props.applications : []);

  useEffect(() => {
    console.log("---data->", data);
  }, [props.applications]);

  const handleCellClick = (event) => { 
    //&& event.data.status ==="SUBMITTED"
    if (event.column.colId !== 'actions') {
      const rowData = event.data;      
      sessionStorage.setItem("appid", rowData.id);
      navigate("./summary", { state: { rowData } });
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >

      <div
        className="ag-theme-alpine"
        style={{
          height: '600px',
          width: '100%'
        }}
      >
        <AgGridReact
          columnDefs={columnDefs}
          rowData={data}
          onCellClicked={handleCellClick}
        >
        </AgGridReact>
      </div>

    </Box>
  );
};
export default AggridTable;