import Grid from '@mui/material/Unstable_Grid2'
import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import Box from '@mui/system/Box'
import Logo from '../../Assets/images/loan-vidya-logo.png'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    border: ''
}));

const Header = () => {
    return (
        <Grid container spacing={2}>
            <Grid xs={4} md={3} lg={2}>
                <Box
                    component="img" sx={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain'
                  }}
                    alt="Your logo."
                    src={Logo}
                />
            </Grid>
            <Grid xs={8} md={9} lg={10}>

            </Grid>
        </Grid>
    )
}

export default Header;
