import React from "react";
import { border, Container } from "@mui/system";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
    Button,
    Paper,
    Alert
} from "@mui/material";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { UserAuth } from "../../../../context/AuthContext";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from "../../../atom/InputController";
import CountrySelectController from "../../../atom/CountryController";
import DatePickerController from "../../../atom/DatePickerController";
import AutocompleteSelectController from "../../../atom/AutocompleteController";
//options values
import { addStatusData, programLevel, programCategory, relations, employmentstatus, yesno, steps } from "./optionsdata";

const schema = yup.object({
    firstName: yup.string().required('First name is required'),
    surName: yup.string().required('Surname is required'),
    gender: yup.string().required('Gender is required'),
    contactNumber: yup.string().required('Phone number is required'),
    email: yup.string().email('Invalid email format').required('Email is required'),
    residingState: yup.string().required('Current state of residence is required'),
    residingCity: yup.string().required('Current city of residence is required'),
    residingCountry: yup.string().required('Current country of residence is required'),
}).required();

function withFormHook(Component) {
    return function FormComponent(props) {
        const { register, handleSubmit, setValue, formState: { errors } } = useForm({
            resolver: yupResolver(schema)
        });

        const onSubmit = (data) => {
            debugger;
            console.log(data);
        }

        return (
            <Component
                {...props}
                register={register}
                setValue={setValue}
                handleSubmit={handleSubmit(onSubmit)}
                errors={errors}
            />
        );
    };
}


function StepFourCoApplicantDetailscomponent(props) {
    const { register, handleSubmit, setValue, errors } = props;
    const { user } = UserAuth();
    return (
        <>
            <div className="wizard_title">Co-Application Details</div>
            <Box sx={{ width: '100%', marginTop:"30px" }}>
                <Stepper activeStep={4} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
            <Grid container spacing={2} sx={{ margin: "12px 0 12px 0" }}>                
                <Grid item xs={12} sm={4}>
                    <TextField
                        name="coapplicantname"
                        title="Co-Applicant Name"
                        register={register}
                        errors={errors}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <AutocompleteSelectController
                        name="relationshiptomainapplicant"
                        title="Relationship to Main applicant"
                        register={register}
                        optionsdata={relations}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <AutocompleteSelectController
                        name="currentemploymentstatus"
                        title="Current employment status"
                        register={register}
                        optionsdata={employmentstatus}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        name="currentcityandstate"
                        title="Current city & state of residence"
                        register={register}
                        errors={errors}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        name="pan"
                        title="PAN"
                        register={register}
                        errors={errors}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        name="monthlyincome"
                        title="Monthly Income"
                        register={register}
                        errors={errors}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <AutocompleteSelectController
                        name="existingloan"
                        title="Existing Loans EMI (if any)"
                        register={register}
                        optionsdata={yesno}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        name="totalamountofemi"
                        title="Total Amount of EMI"
                        register={register}
                        errors={errors}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <AutocompleteSelectController
                        name="canyouprovideproofofincome"
                        title="Can you provide proof of income "
                        register={register}
                        optionsdata={yesno}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <AutocompleteSelectController
                        name="isownpropertyavailable"
                        title="Is own property available (For Field Investigation purpose only)"
                        register={register}
                        optionsdata={yesno}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        name="propertyaddress"
                        title="Property Address"
                        register={register}
                        errors={errors}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        name="currentcollectivemonthlyincome"
                        title="Current Collective Monthly Income (Main Applicant and Co-Applicant combined)"
                        register={register}
                        errors={errors}
                    />
                </Grid>                
                                
            </Grid>
            <Divider />
            <Button
                variant="contained"
                type="submit"
                sx={{ margin: "12px 0 12px 0" }}
            >
                Next
            </Button>
        </>
    )
}



const StepFourCoApplicantDetails = withFormHook(StepFourCoApplicantDetailscomponent);

export default StepFourCoApplicantDetails;