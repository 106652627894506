import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useNavigate, useLocation } from "react-router-dom";
import { UserAuth } from '../../../context/AuthContext';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import { styled } from '@mui/material/styles';
import { Logout } from '@mui/icons-material';
import LogoImg from '../../../Assets/images/loan-vidya-logo.png'
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import CategoryIcon from '@mui/icons-material/Category';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import MessageIcon from '@mui/icons-material/Message';
import ProfileAlertDialog from './profileRedirectDialog';


const Logo = styled('img')({
  height: '50px',
});

const routes = [
  { path: '/', name: 'Home', icon: null },
  { path: '/about', name: 'About', icon: null },
  { path: '/contact', name: 'Contact', icon: null },
];

const sideMenu = [
  {name: 'Dashboard', icon: <DashboardIcon />, route: '/', isEnabled : true },
  // {name: 'Your Applications', icon: <AssignmentIcon />, route: '/applications', isEnabled: false},
  // {name: 'Track Services', icon: <LibraryAddCheckIcon />, route: '/track', isEnabled: false},
  // {name: 'Other Products', icon: <CategoryIcon />, route: '/products', isEnabled: false},
  {name: 'Your Documents', icon: <DocumentScannerIcon />, route: '/documents', isEnabled: true},
  {name: 'Your Actions', icon: <PendingActionsIcon />, route: '/actions', isEnabled: true},
  {name: 'Support', icon: <MessageIcon />, route: '/support', isEnabled: true},
]

const settings = ['Profile', 'Sign Out', 'Contact Us'];

const drawerWidth = 240;

const StudentHome = (props) => {
  const { logOut, user } = UserAuth();
  const [open, setOpen] = useState(false);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    if(user && user.lvProfile && user.role == "ROLE_STUDENT") {
      setAnchorElUser(event.currentTarget);
    } else {
      setAnchorElUser(null);
    }
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (setting) => {
    if(setting === 'Profile') {
      navigate('/profile')
    }
    setAnchorElUser(null);
  };

  const handleOnSignOut = async () => {
    try {
      await logOut();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  console.log("user in home", user)

  return (
    <React.Fragment>
      <ProfileAlertDialog open={user != null && !user.lvProfile && user?.role === "ROLE_STUDENT"} />
      <Box sx={{ display: 'flex'}}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar>
          <Box  sx={{ flexGrow: 0, width : "216px" }}>
            <Logo src={LogoImg} alt="Logo" />
          </Box>
            {user &&
              <><Typography variant="h6" sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
                Welcome {user?.lvProfile ? `${user?.lvProfile?.firstName} ${user?.lvProfile?.surName}` : user?.displayName} 
              </Typography><React.Fragment>
                  <Box sx={{ flexGrow: 0 }}>
                    <Tooltip title="Open settings">
                      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        <Avatar 
                          displayname={user?.lvProfile ? `${user?.lvProfile?.firstName} ${user?.lvProfile?.surName}` : user?.displayName} 
                          src={user?.lvProfile ? user?.lvProfile?.profilePicture : user?.photoURL} 
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Box>
                    <Menu
                      sx={{ mt: '45px' }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      {settings.map((setting) => (
                        <MenuItem key={setting} onClick={() => handleCloseUserMenu(setting)}>
                          <Typography textAlign="center">{setting}</Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>
                  <Box sx={{ display: { xs: 'none', md: 'block' }, margin: "0 5px 0 15px" }}>
                    <Button color="inherit" onClick={() => handleOnSignOut()} startIcon={<Logout />}>
                      Sign Out
                    </Button>
                  </Box>
                </React.Fragment></>
            }
          </Toolbar>
        </AppBar>
        {user && user.role === "ROLE_STUDENT" && user.lvProfile &&
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
                bgcolor: '#fdfdfd', // change this to your desired color
              },

            }}
            variant="permanent"
            anchor="left"
          >
            <Toolbar />
            <Divider />
            <List>
              {sideMenu.map((menu, index) => (
                <ListItem key={menu.name} disablePadding>
                  <ListItemButton disabled={!menu.isEnabled} selected={menu.route === location.pathname} onClick={()=> navigate(menu.route)} >
                    <ListItemIcon>
                     {menu.icon}
                    </ListItemIcon>
                    <ListItemText primary={menu.name} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Drawer>
        }

        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3, height: '100vh' }}
        >
          <Toolbar />
          {props.children}
        </Box>
      </Box>
    </React.Fragment>
  );

}
export default StudentHome;

