import React, { useState, useEffect, useContext } from "react";
import { Box, Container, Grid, Card, Typography, Button, Alert, AlertTitle } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Chart from 'react-apexcharts';
import { useNavigate, useLocation } from "react-router-dom";
import { doc, db, getDocs, collection } from "../../../../firebase";
import { UserAuth } from '../../../../context/AuthContext'
import { SpinnerContext } from '../../../../context/SpinnerContext'

const DocumentUpload = (props) => {

  const navigate = useNavigate();
  const { user } = UserAuth();
  const [documentCount, setDocumentCount] = useState([])
  const { setShowSpinner } = useContext(SpinnerContext);

  const onViewClick =(path) => {
    navigate('/documents/list', { state: { path : path } })
  }

  const fetchData = async () => {
    const currentOpenApplicationId = user?.currentOpenApplicationId;
    const collectionPath = `applications/${currentOpenApplicationId}/lvDocuments`;
    try {
      setShowSpinner(true)
      const collectionRef = collection(db, collectionPath);
      const querySnapshot = await getDocs(collectionRef);
      const dataArray = querySnapshot.docs.map((docSnapshot) => docSnapshot.data());
      frameDocTotal(dataArray) // run whenever groupedData (fb's documents) changes
    } catch (error) {
      console.error('Error fetching documents:', error);
    } finally {
      setShowSpinner(false)
    }
  };

  const frameDocTotal = (groupedData) => {
    const documentCount = {};
    groupedData.forEach(item => {
      const { group, status } = item;
  
      if (!documentCount[group]) {
        documentCount[group] = { total: 0, completed: 0 };
      }
  
      documentCount[group].total++;
  
      if (status === 'success') {
        documentCount[group].completed++;
      }
    });
    setDocumentCount(documentCount)
    return documentCount;
  };

  useEffect(() => {
    fetchData()
  }, [])


  const getChartOptions = (total, completed) => {
    return {
      chart: {
        height: 280,
        type: "radialBar",
      },
      series: [completed / total],
      colors: ["#20E647"],
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "70%",
            background: "#293450"
          },
          track: {
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              blur: 4,
              opacity: 0.15
            }
          },
          dataLabels: {
            name: {
              offsetY: -10,
              color: "#fff",
              fontSize: "13px"
            },
            value: {
              color: "#fff",
              fontSize: "30px",
              show: true,
              formatter: () => {
                return `${completed}/${total}`
              }
            }
          }
        }
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "vertical",
          gradientToColors: ["#87D4F9"],
          stops: [0, 100]
        }
      },
      stroke: {
        lineCap: "round"
      },
      labels: ["Uploaded"]
    }
  };

  return (
    <React.Fragment>
      { Object.keys(documentCount).length > 0 &&
      <Box sx={{ margin: "25px 0" }}>
        <Container>
          <Grid container spacing={2} sx={{ margin: "25px 0" }}>
            <Grid item xs={4}>
              <Card sx={{
                background: 'linear-gradient(100.66deg, rgb(67, 67, 67) 6.56%, rgb(0, 0, 0) 93.57%)',
                borderRadius: "10px",
                padding: "25px",
              }}>
                <Typography variant="h6" sx={{ color: "#FFFFFF", fontWeight: 'bold' }}>
                  Student Identification
                </Typography>
                <Chart
                  options={getChartOptions(documentCount?.identity?.total, documentCount?.identity?.completed)}
                  series={[(documentCount?.identity?.completed / documentCount?.identity?.total) * 100]}
                  type="radialBar"
                  height={175}
                />
                <Grid container sx={{justifyContent: "end"}}>
                  <Button sx={{color: "#FFFFFF"}} endIcon={<ArrowForwardIcon />} onClick={() => onViewClick("identity")}>
                    View all
                  </Button>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card sx={{
                background: 'linear-gradient(-20deg, rgb(43, 88, 118) 0%, rgb(78, 67, 118) 100%)',
                borderRadius: "10px",
                padding: "25px",
              }}>
                <Typography variant="h6" sx={{ color: "#FFFFFF", fontWeight: 'bold' }}>
                  Academic
                </Typography>
                <Chart
                  options={getChartOptions(documentCount?.academic?.total, documentCount?.academic?.completed)}
                  series={[(documentCount?.academic?.completed / documentCount?.academic?.total) * 100]}
                  type="radialBar"
                  height={175}
                />
                <Grid container sx={{justifyContent: "end"}}>
                  <Button sx={{color: "#FFFFFF"}} endIcon={<ArrowForwardIcon />} onClick={() => onViewClick("academic")}>
                    View all
                  </Button>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card sx={{
                background: 'linear-gradient(135deg, rgb(171, 220, 255) 0%, rgb(3, 150, 255) 100%)',
                borderRadius: "10px",
                padding: "25px",
              }}>
                <Typography variant="h6" sx={{ color: "#FFFFFF", fontWeight: 'bold' }}>
                  Work Experience
                </Typography>
                <Chart
                  options={getChartOptions(documentCount?.workexperience?.total, documentCount?.workexperience?.completed)}
                  series={[(documentCount?.workexperience?.completed / documentCount?.workexperience?.total) * 100]}
                  type="radialBar"
                  height={175}
                />
                <Grid container sx={{justifyContent: "end"}}>
                  <Button sx={{color: "#FFFFFF"}} endIcon={<ArrowForwardIcon />} onClick={() => onViewClick("workexperience")}>
                    View all
                  </Button>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card sx={{
                background: 'linear-gradient(135deg, rgb(252, 207, 49) 0%, rgb(245, 85, 85) 100%)',
                borderRadius: "10px",
                padding: "25px",
              }}>
                <Typography variant="h6" sx={{ color: "#FFFFFF", fontWeight: 'bold' }}>
                  Parents
                </Typography>
                <Chart
                  options={getChartOptions(documentCount?.parents?.total, documentCount?.parents?.completed)}
                  series={[(documentCount?.parents?.completed / documentCount?.parents?.total) * 100]}
                  type="radialBar"
                  height={175}
                />
                <Grid container sx={{justifyContent: "end"}}>
                  <Button sx={{color: "#FFFFFF"}} endIcon={<ArrowForwardIcon />} onClick={() => onViewClick("parents")}>
                    View all
                  </Button>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card sx={{
                background: 'linear-gradient(135deg, rgb(67, 203, 255) 0%, rgb(151, 8, 204) 100%)',
                borderRadius: "10px",
                padding: "25px",
              }}>
                <Typography variant="h6" sx={{ color: "#FFFFFF", fontWeight: 'bold' }}>
                  Co-Applicant 1
                </Typography>
                <Chart
                  options={getChartOptions(documentCount?.coapplicant1?.total, documentCount?.coapplicant1?.completed)}
                  series={[(documentCount?.coapplicant1?.completed / documentCount?.coapplicant1?.total) * 100]}
                  type="radialBar"
                  height={175}
                />
                <Grid container sx={{justifyContent: "end"}}>
                  <Button sx={{color: "#FFFFFF"}} endIcon={<ArrowForwardIcon />} onClick={() => onViewClick("coapplicant1")}>
                    View all
                  </Button>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card sx={{
                background: 'linear-gradient(45deg, #02aab0 30%, #00cdac 90%)',
                borderRadius: "10px",
                padding: "25px",
              }}>
                <Typography variant="h6" sx={{ color: "#FFFFFF", fontWeight: 'bold' }}>
                  Co-Applicant 2
                </Typography>
                <Chart
                  options={getChartOptions(documentCount?.coapplicant2?.total, documentCount?.coapplicant2?.completed)}
                  series={[(documentCount?.coapplicant2?.completed / documentCount?.coapplicant2?.total) * 100]}
                  type="radialBar"
                  height={175}
                />
                <Grid container sx={{justifyContent: "end"}}>
                  <Button sx={{color: "#FFFFFF"}} endIcon={<ArrowForwardIcon />} onClick={() => onViewClick("coapplicant2")}>
                    View all
                  </Button>
                </Grid>
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ margin: "25px 0" }}>
            <Grid item xs={12}>
            </Grid>
          </Grid>
        </Container>
      </Box> }
      { !user.currentOpenApplicationId && <Box>
        <Alert
        severity="info">
          <AlertTitle>Please proceed to the dashboard to initiate the loan application process.<br /><br /> After completing the initial application, please return to submit the required documents for further processing.</AlertTitle>
        </Alert>
        <br />
        <br />
        <Button size="small" type="button" variant="contained" onClick={() => navigate("/")}>
            Go to dashboard
          </Button>
      </Box> }
    </React.Fragment>
  );
}

export default DocumentUpload;