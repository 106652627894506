import React, { useEffect } from "react";
import { Container } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import Box from '@mui/system/Box'
import Paper from "@mui/material/Paper";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Divider from "@mui/material/Divider";
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import LoginIllustration from '../../../Assets/illustrations/login-new.svg'
import GoogleIcon from '../../../Assets/images/google.svg'
import MicrosoftIcon from '../../../Assets/images/ms.svg'
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../../context/AuthContext";
import Logo from '../../../Assets/images/loan-vidya-logo.png'
import { db, collection, query, where, getDocs } from "../../../firebase";



const Login = () => {
  const navigate = useNavigate();
  const { googleSignIn , user } = UserAuth();
  
  const onHandleSignIn = async () => {
    try {
      const response = await googleSignIn()
      console.log("in handle sign in", response)
    } catch(errors) {
      console.log("errors")
    }
  }

  const getData = async (user) => {
    const q = query(collection(db, "profiles"), where("email", "==", user?.email));
    const querySnapshot = await getDocs(q);
    const data = querySnapshot.docs.map((doc) => doc.data());
    return data;
  };

  useEffect(() => {
    if (user != null && user?.role == "ROLE_STUDENT") {
      navigate('/');
    } else if (user != null && user?.role == "ROLE_ROOT_ADMIN") {
      navigate('/admin');
    }
  }, [user]);

  return (
    <React.Fragment>
      <Container>
        <Grid container spacing={2}>
          <Grid xs={12} md={7} display="flex" justifyContent="center" alignItems="center">
            <Box
              component="img"
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }}
              alt="Your logo."
              src={LoginIllustration}
            />
          </Grid>
          <Grid xs={12} md={5} display="flex" justifyContent="center" alignItems="center">
            <Paper
              elevation={1}
              sx={{
                width: '100%',
                padding: "24px"
              }}
            >
              <Box display="flex" justifyContent="center" alignItems="center">
                <img src={Logo} alt="Logo" height="70" />
              </Box>
              <Typography variant="body1"  sx={{ margin: "24px 0 24px 0" }}> 
                Streamline your application process with a login - create, track, and stay updated.
              </Typography>
              <Stack direction="column" spacing={4} sx={{ width: "100%", margin: "24px 0 24px 0" }}>
                <Button 
                  variant="outlined" 
                  size='large' 
                  startIcon={
                  <Box
                    component="img"
                    sx={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover'
                    }}
                    alt="Your logo."
                    src={GoogleIcon}
                  />}
                  onClick={() => onHandleSignIn()}
                >
                  Sign in with Google
                </Button>
              </Stack>
              <Alert severity="success" sx={{ margin: "24px 0 24px 0" }}>
                Log in with confidence, your privacy and security is our top priority!
              </Alert>
              <Typography variant="body2"> 
                Before you proceed, please review the  {" "}
                  <Link href="/termsandcondition" underline="always" target="_blank" rel="noopener noreferrer">
                    Terms and Condition
                  </Link>
                  {" "}
                  and
                  {" "}
                   <Link href="/privacypolicy"underline="always" target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                  </Link>
              </Typography>
              <br />
              <Typography variant="body2"> 
                <b>By signing in</b> you agree to our terms and condition. We also take the privacy and security of your information seriously and want to ensure you're comfortable with our policies before using our platform.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default Login;
